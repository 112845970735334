import { Component, ComponentRef, Input, OnChanges, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { Item, ItemMetadata } from "@common/ADAPT.Common.Model/organisation/item";
import { Link } from "@common/ADAPT.Common.Model/organisation/link";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingNote, MeetingNoteType, MeetingNoteTypeMetadata } from "@common/ADAPT.Common.Model/organisation/meeting-note";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { KanbanUiService } from "@org-common/lib/kanban/kanban-ui.service";
import { BehaviorSubject, forkJoin, Observable } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { KanbanService } from "../../kanban/kanban.service";
import { LinkService } from "../../link/link.service";
import { IMeetingAgendaItemComponent, MeetingAgendaItemComponentRegistry } from "../meeting-agenda-component-registry";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

@Component({
    selector: "adapt-meeting-sidebar-agenda-item",
    templateUrl: "./meeting-sidebar-agenda-item.component.html",
    styleUrls: ["./meeting-sidebar-agenda-item.component.scss"],
})
export class MeetingSidebarAgendaItemComponent extends BaseComponent implements OnChanges {
    public readonly MeetingNoteIconClass = MeetingNoteTypeMetadata.IconClass;
    public readonly MeetingNoteType = MeetingNoteType;
    public readonly MeetingItemIconClass = ItemMetadata.IconClass;

    @Input() public agendaItem!: MeetingAgendaItem;

    @ViewChild("container", { read: ViewContainerRef }) public container!: ViewContainerRef;
    public agendaActionLinks: Link[] = [];
    public agendaNotes: MeetingNote[] = [];
    public canEditMeetingItem$: Observable<boolean>;
    public canEditAgendaItem = false;
    public hasEditableBoard = false;

    private triggerUpdate$ = new BehaviorSubject<void>(undefined);
    private currentCustomComponent?: ComponentRef<IMeetingAgendaItemComponent>;

    constructor(
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private dialogService: AdaptCommonDialogService,
        private kanbanService: KanbanService,
        private kanbanUiService: KanbanUiService,
        private linkService: LinkService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.triggerUpdate$.pipe(
            debounceTime(10),
            // agenda is valid with valid meeting nav prop - can possibly be detached/deleted???
            filter(() => !!this.agendaItem?.meeting),
            tap(() => this.checkLoadComponentSelectorComponent()),
            switchMap(() => forkJoin([
                this.meetingsService.getMeetingItemsForAgendaItem(this.agendaItem),
                this.meetingsService.getMeetingNotesForAgendaItem(this.agendaItem),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([agendaActionLinks, agendaNotes]) => {
            this.agendaActionLinks = agendaActionLinks;
            this.agendaNotes = agendaNotes;
            this.canEditAgendaItem = this.meetingsService.canEditMeetingForTeam(this.agendaItem.meeting!.team!);
        });

        this.triggerUpdate$.pipe(
            switchMap(() => this.kanbanService.hasAnyEditableBoard()),
            this.takeUntilDestroyed(),
        ).subscribe((hasEditableBoard) => this.hasEditableBoard = hasEditableBoard);

        this.canEditMeetingItem$ = this.triggerUpdate$.pipe(
            // agenda is valid with valid meeting nav prop - can possibly be detached/deleted???
            filter(() => !!this.agendaItem?.meeting),
            // needs to be able to create meeting item for team - not just editing any public board
            switchMap(() => this.meetingsService.canEditMeetingItemsAsParticipant(this.agendaItem.meeting!)),
            this.takeUntilDestroyed(),
        );

        rxjsBreezeService.entityTypeChanged(Item).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((item) => {
            if (this.agendaActionLinks.find((a) => item.itemId === a.itemId)) {
                this.triggerUpdate$.next();
            }
        });

        rxjsBreezeService.entityTypeChanged(Link).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meetingItem) => {
            if (meetingItem.meetingAgendaItemId === this.agendaItem.meetingAgendaItemId) {
                this.triggerUpdate$.next();
            }
        });

        rxjsBreezeService.entityTypeChanged(MeetingNote).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((note) => {
            if (note.meetingAgendaItemId === this.agendaItem.meetingAgendaItemId) {
                this.triggerUpdate$.next();
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.agendaItem && this.agendaItem) {
            this.triggerUpdate$.next();
        }
    }

    public addDecision() {
        return this.addMeetingNoteOfType(MeetingNoteType.Decision).subscribe();
    }

    public addMinute() {
        return this.addMeetingNoteOfType(MeetingNoteType.Minute).subscribe();
    }

    public addMeetingItem() {
        return this.kanbanService.getBoardsByTeam(this.agendaItem.meeting!.teamId).pipe(
            // not hiding personal board now - since Steve wants to allow meeting actions to be from anywhere. If personal board, only the creator can see it
            switchMap((boards) => this.kanbanUiService.createItemOnFirstEditableBoard(boards, { forceAllBoards: true })),
            // item is already saved in the above create
            switchMap((item: Item) => this.linkService.createLinkForAgendaItem(this.agendaItem, item.itemId)),
            switchMap((meetingItem) => this.meetingsService.saveEntities([meetingItem])),
        ).subscribe();
    }

    @Autobind
    public editAgendaItem() {
        return this.meetingsUiService.editMeetingAgendaItem(this.agendaItem, true);
    }

    private addMeetingNoteOfType(type: MeetingNoteType) {
        return this.meetingsService.createMeetingNoteForAgendaItem(this.agendaItem, type).pipe(
            switchMap((meetingNote) => {
                const dialogData: IEditSimpleValueBreezeEntityDialogData = {
                    title: `Add ${type}`,
                    entities: [{
                        label: type,
                        entity: meetingNote,
                        fieldName: "content",
                        type: SimpleValueType.RichText,
                    }],
                    articleId: MeetingNoteTypeMetadata.ArticleId[type],
                    dialogWidth: 900,
                    saveOnClose: true,
                };
                return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, dialogData);
            }),
            tap(() => this.triggerUpdate$.next()),
        );
    }

    private checkLoadComponentSelectorComponent() {
        if (this.currentCustomComponent) {
            this.currentCustomComponent = undefined;
        }

        if (this.agendaItem?.componentSelector && this.container) {
            const type = MeetingAgendaItemComponentRegistry.get(this.agendaItem.componentSelector);
            if (!type) {
                throw new Error(`component is not registered - ${this.agendaItem.componentSelector} - needs to use the MeetingAgendaComponent class decorator`);
            }

            this.container.clear();
            this.currentCustomComponent = this.container.createComponent<IMeetingAgendaItemComponent>(type);
            const instance = this.currentCustomComponent.instance;
            instance.meetingAgendaItem = this.agendaItem;

            if (FunctionUtilities.isFunction(instance.onDataChanged)) {
                instance.onDataChanged!();
            }
        }
    }
}
