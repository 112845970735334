/* eslint-disable max-classes-per-file */
import { Injectable, Injector, Optional } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BreezeService } from "@common/lib/data/breeze.service";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { SignalRService } from "@common/lib/signalr-provider/signalr.service";
import { SignalRHub } from "@common/lib/signalr-provider/signalr-hub";
import { ISignalRSubscriptionHandler } from "@common/lib/signalr-provider/signalr-subscription-handler.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IEntityAutoUpdaterTemplate } from "@org-common/lib/entity-sync/entity-auto-updater-template.interface";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { Entity } from "breeze-client";
import { forkJoin, lastValueFrom, Subject } from "rxjs";
import { DirectorySharedService } from "../directory-shared/directory-shared.service";
import { ConflictingChangesDialogComponent, IConflictingChangesDialogData } from "./conflicting-changes-dialog/conflicting-changes-dialog.component";
import { AutoUpdaterMergeStrategy } from "./entity-auto-updater/auto-updater-merge-strategy";
import { IEntityAutoUpdater } from "./entity-auto-updater/entity-auto-updater.interface";
import { EntityAutoUpdaterFactory } from "./entity-auto-updater/entity-auto-updater-factory";
import { EntitySignalRRegistrarService } from "./entity-signalr-registrar.service";
import { IEntityUpdateSummary } from "./entity-update-summary.interface";
import { IEntityUpdates } from "./entity-updates.interface";

@Injectable({
    providedIn: "root",
})
export class EntitySignalRHub extends SignalRHub {
    public static readonly Name = "EntityHub";

    private static readonly autoUpdaters: IEntityAutoUpdaterTemplate[] = [];

    private entitySyncSubscriptionHandler: ISignalRSubscriptionHandler<[number]>;
    private _entitiesUpdated$ = new Subject<IEntityUpdateSummary>();
    private entityAutoUpdaterFactory: EntityAutoUpdaterFactory;

    public constructor(
        injector: Injector,
        private featureService: FeaturesService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
        private rxjsBreezeService: RxjsBreezeService,
        private directorySharedService: DirectorySharedService,
        private signalRService: SignalRService,
        entitySignalRRegistrarService: EntitySignalRRegistrarService,
        @Optional() entityAutoUpdaterFactory?: EntityAutoUpdaterFactory,
    ) {
        super(EntitySignalRHub.Name);

        // register the connectionId with breeze so that we don't get updates from the same session
        BreezeService.addSaveTagParameter({
            paramName: "signalRConnectionId",
            invoker: () => this.connectionId ?? "",
            valueIsValid: () => true,
        });

        entitySignalRRegistrarService.registerUpdaterTemplates();
        this.entityAutoUpdaterFactory = entityAutoUpdaterFactory || new EntityAutoUpdaterFactory(injector, EntitySignalRHub.autoUpdaters);
        this.entitySyncSubscriptionHandler = this.createSubscriptionHandler(
            "subscribeToChangesInOrganisation",
            "unsubscribeFromChangesInOrganisation",
        );

        const orgService: OrganisationService = injector.get(OrganisationService);
        orgService.organisationChanging$.subscribe(this.unsubscribeFromOrganisationChanges);
        orgService.organisationChanged$.subscribe(this.subscribeToOrganisationChanges);
    }

    public static addEntityAutoUpdaterTemplate<T extends {}>(autoUpdater: IEntityAutoUpdaterTemplate<T>) {
        EntitySignalRHub.autoUpdaters.push(autoUpdater);
    }

    public get entitiesUpdated$() {
        return this._entitiesUpdated$.asObservable();
    }

    public getHandlingMethods() {
        return {
            onEntityUpdates: this.promiseToHandleEntityUpdates,
        };
    }

    @Autobind
    public async promiseToHandleEntityUpdates(entityUpdates: IEntityUpdates) {
        this.log.info("Entities have been modified on server", entityUpdates);

        const personPromise = this.directorySharedService.promiseToGetPersonById(entityUpdates.ChangedByPersonId);
        const autoUpdaters: IEntityAutoUpdater[] = [];
        for (const entityUpdate of entityUpdates.Updates) {
            autoUpdaters.push(await this.entityAutoUpdaterFactory.fromEntityUpdate(entityUpdate));
        }

        return personPromise.then((person) => this.promiseToProcessEntityUpdates(person!, autoUpdaters));
    }

    /**
     * Process the given changes, prompting the user if there are conflicts and then integrate the updates into the
     * local breeze cache. Public to allow testing.
     * @returns The list of updates which were applied to the cache
     * @param changedByPerson person who caused the changes
     * @param autoUpdaters auto-updaters for the entities
     */
    public async promiseToProcessEntityUpdates(changedByPerson: Person, autoUpdaters: IEntityAutoUpdater[]) {
        const mergeStrategy = await this.promiseToConfirmConflictingChangesOverwrite(changedByPerson, autoUpdaters);
        const appliedAutoUpdaters = await this.promiseToIntegrateChangedEntitiesIntoCache(autoUpdaters, mergeStrategy);
        this.broadcastAppliedChanges(changedByPerson, appliedAutoUpdaters);
        return appliedAutoUpdaters;
    }

    private promiseToConfirmConflictingChangesOverwrite(changedByPerson: Person, autoUpdaters: IEntityAutoUpdater[]) {
        const conflictingEntities: IConflictingChangesDialogData = {
            changedByPerson,
            conflictingUpdates: autoUpdaters.filter((autoUpdater) => autoUpdater.hasConflicts()
                && !autoUpdater.conflictsShouldBeAutomaticallyIntegrated),
        };

        if (conflictingEntities.conflictingUpdates.length > 0) {
            // default to RemoteOverwritesLocal if multiple users change the entity at the same time and dialogService.closeAll() is called, resulting a "no elements in sequence" error.
            return lastValueFrom(this.dialogService.open(ConflictingChangesDialogComponent, conflictingEntities), { defaultValue: AutoUpdaterMergeStrategy.RemoteOverwritesLocal });
        }

        return Promise.resolve(AutoUpdaterMergeStrategy.RemoteOverwritesLocal);
    }

    private async promiseToIntegrateChangedEntitiesIntoCache(autoUpdaters: IEntityAutoUpdater[], mergeStrategy: AutoUpdaterMergeStrategy) {
        const updatesToApply = autoUpdaters.filter((autoUpdater) => autoUpdater.updatesShouldBeIntegrated());
        if (updatesToApply.length > 0) {
            const integrateUpdatesPromises = updatesToApply.map((autoUpdater) => autoUpdater.promiseToIntegrateUpdates(mergeStrategy));
            await Promise.all(integrateUpdatesPromises);

            const modifiedEntities = updatesToApply.map((autoUpdater) => autoUpdater.localEntity as IBreezeEntity)
                .filter((entity) => !!entity);

            // prime after fetching entities -> wait for all prime to finish
            await lastValueFrom(forkJoin(updatesToApply.map((i) => i.primeAfterFetch())));

            // This should act as if it is a local save for cache flushing purposes
            // TODO Should this logic be moving into the breeze service somewhere?
            this.commonDataService.clearCountCacheForEntities(modifiedEntities);

            modifiedEntities.forEach((entity) => this.rxjsBreezeService.emitEntityChangedFromAutoUpdater(entity));
            return updatesToApply;
        }

        return Promise.resolve(updatesToApply);
    }

    private broadcastAppliedChanges(changedByPerson: Person, appliedAutoUpdaters: IEntityAutoUpdater[]) {
        const relatedEntitiesNested = appliedAutoUpdaters.map((autoUpdater) => autoUpdater.relatedEntitiesInCache);
        const updateSummary: IEntityUpdateSummary = {
            changedByPerson,
            changedEntityModels: ArrayUtilities.distinct(appliedAutoUpdaters.map((autoUpdater) => autoUpdater.model)),
            changedEntities: appliedAutoUpdaters.map((autoUpdater) => autoUpdater.localEntity as Entity)
                .filter((e) => !!e),
            entitiesRelatedToChanges: ArrayUtilities.mergeArrays(relatedEntitiesNested),
        };

        this.log.info("Changes integrated locally successfully.", appliedAutoUpdaters);
        this._entitiesUpdated$.next(updateSummary);
    }

    @Autobind
    private unsubscribeFromOrganisationChanges(oldOrg: Organisation) {
        // at this point the org has not changed, so any breeze queries will hit the oldOrg ID.
        // we can unsubscribe regardless of there being a subscription or not, so just do it.
        this.entitySyncSubscriptionHandler.promiseToUnsubscribe(oldOrg.organisationId);
    }

    @Autobind
    private subscribeToOrganisationChanges(newOrg: Organisation) {
        if (newOrg) {
            // only do stuffs if there is an org (to avoid errors when not logging in or user has no access to any org)
            this.signalRService.updateLastEntityUpdateTimestamp();
            this.promiseToExecuteIfEntitySyncEnabled(
                () => this.entitySyncSubscriptionHandler.promiseToSubscribe(newOrg.organisationId),
            );
        }
    }

    private async promiseToExecuteIfEntitySyncEnabled(callback: () => void) {
        const isEnabled = await this.featureService.promiseToCheckIfFeatureActive(FeatureName.PlatformEntitySync);
        if (isEnabled) {
            callback();
        }
    }
}
