<div *ngIf="careerValuation"
     class="d-flex gap-1 align-items-baseline">
    <span adaptTooltip="Career Valuation"
          class="cvt-link-badge"></span>
    <a *ngIf="!linkDisabled else plainTextLink"
       (click)="openCareerValuation($event)"
       class="text-break cvt-link"
       data-test="cvt-link-summary"
       href="#"
       target="_blank">{{careerValuation.extensions.linkText}}
    </a>
    <ng-template #plainTextLink>
        <span data-test="cvt-plain-text-link-summary">{{careerValuation.extensions.linkText}}</span>
    </ng-template>
    <button class="btn btn-link"
            [adaptBlockingClick]="deleteLink"
            adaptTooltip="Delete link"
            data-test="cvt-link-delete"
            *ngIf="canEdit"
            adaptButtonIcon="fal fa-fw fa-trash-alt"></button>
</div>
