import { Injectable, Injector } from "@angular/core";
import { AfterOrganisationInitialisation } from "../organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { CulturalLeadershipQueryUtilities } from "./cultural-leadership-query-utilities";

@Injectable({
    providedIn: "root",
})
export class OrgCommonCulturalLeadershipService extends BaseOrganisationService {
    private clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    @AfterOrganisationInitialisation
    public async promiseToGetCulturalLeadershipConfiguration() { // leave async here even if there is no away as the function becomes a promise from the decorator
        return this.clQueryUtilities.promiseToGetCulturalLeadershipConfiguration();
    }
}
