<adapt-confirmation-dialog (closed)="onDialogClosed($event)"
                           (initialised)="onInitialised($event)">
    <p>Are you sure you wish to delete this action?</p>

    <ng-container *adaptLoading="loadingRelatedInfo">
        <p *ngIf="linkedMeeting">
            It will be removed from the "
            <adapt-link-team-meeting [meeting]="linkedMeeting"></adapt-link-team-meeting>
            " meeting.
        </p>
        <p *ngIf="catchupLink"
           class="d-flex">
            It will be removed from the "
            <adapt-catchup-link [catchupLink]="catchupLink"></adapt-catchup-link>
            "
        </p>

        <p *ngIf="careerValuationLink"
           class="d-flex">
            It will be removed from the "
            <adapt-cvt-link [careerValuationLink]="careerValuationLink"></adapt-cvt-link>
            "
        </p>

        <ng-container *ngIf="itemLinks.length > 0">
            <p>It will be unlinked from the following actions:</p>
            <ul>
                <li *ngFor="let link of itemLinks">
                    <adapt-item-link [item]="link.linkedItem"
                                     [showStatus]="true"
                                     [showSummary]="true"></adapt-item-link>
                </li>
            </ul>
        </ng-container>

        <ng-container *ngIf="objectiveLinks.length > 0">
            <p>It will be removed from the following objectives:</p>
            <ul>
                <li *ngFor="let objectiveLink of objectiveLinks">
                    <adapt-link-objective [objective]="objectiveLink.objective1"
                                          [displayInline]="true"></adapt-link-objective>
                </li>
            </ul>
        </ng-container>
    </ng-container>
</adapt-confirmation-dialog>
