<adapt-filter-content [isDefault]="isDefault"
                      (reset)="filterComponent?.resetOptions()"
                      [urlParamNames]="PcuFilterParamKeys">
    <adapt-catchup-relationships-filter [allowTeamSelection]="allowTeamSelection"
                                        [isTeamView]="!!teamId"
                                        [focusOnInactivePerson]="focusedPerson && !focusedPerson.isActive()"
                                        [showTeamRelationships]="showTeamRelationships"
                                        (optionsChange)="onFilterOptionsChanged($event)"></adapt-catchup-relationships-filter>
</adapt-filter-content>

<div class="speed-catchup-relationship-graph">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-xl-2 card-legend">
                    <div class="scu-toolbar-padding">
                        <div class="btn-group btn-group-sm">
                            <button type="button"
                                    class="btn"
                                    title="Zoom In"
                                    (click)="zoom(0.8)">
                                <!-- TODO: search graph control - should be locally now! -->
                                <span class="fal fa-search-plus"
                                      aria-hidden="true"></span>
                            </button>
                            <button type="button"
                                    class="btn"
                                    title="Zoom Out"
                                    (click)="zoom(1.2)">
                                <span class="fal fa-search-minus"
                                      aria-hidden="true"></span>
                            </button>
                            <button type="button"
                                    class="btn"
                                    title="Fit to View"
                                    (click)="fitToView()">
                                <span class="fal fa-expand"
                                      aria-hidden="true"></span>
                            </button>
                        </div>
                        <div *ngIf="isXlWidth">
                            <h4>Legend</h4>
                            <ul class="legend">
                                <li>
                                    <img src="/content/legend-good.svg"
                                         alt="good"
                                         width="24"
                                         height="24" />
                                    <p class="legend-description">Good</p>
                                </li>
                                <li>
                                    <img src="/content/legend-minor.svg"
                                         alt="minor"
                                         width="24"
                                         height="24" />
                                    <p class="legend-description">Minor issues</p>
                                </li>
                                <li>
                                    <img src="/content/legend-major.svg"
                                         alt="major"
                                         width="24"
                                         height="24" />
                                    <p class="legend-description">Major issues</p>
                                </li>
                                <li *ngIf="showTeamRelationships">
                                    <img src="/content/legend-no-catchup.svg"
                                         alt="no catchup"
                                         width="24"
                                         height="24" />
                                    <p class="legend-description">No catch-up</p>
                                </li>
                            </ul>
                            <ul *ngIf="showCombinedLegend"
                                class="legend">
                                <li>
                                    <img src="/content/legend-engagement.svg"
                                         alt="engagement"
                                         width="24"
                                         height="24" />
                                    <p class="legend-description">Engagement rating</p>
                                </li>
                                <li>
                                    <img src="/content/legend-connection.svg"
                                         alt="connection"
                                         width="24"
                                         height="24" />
                                    <p class="legend-description">Connection rating</p>
                                </li>
                                <li>
                                    <img src="/content/legend-contribution.svg"
                                         alt="contribution"
                                         width="24"
                                         height="24" />
                                    <p class="legend-description">Contribution rating</p>
                                </li>
                            </ul>
                            <p><small><strong>Note:</strong> Faded line signifies an out of date catch-up.</small></p>
                            <p *ngIf="filterOptions.showInactive"><small>Faded person indicates the person has left the
                                    {{teamId ? 'team' : 'organisation'}}.</small></p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-10">
                    <div class="row"
                         *ngIf="selectedPerson && allowSelectPerson">
                        <br />
                        <button adaptButtonIcon="fal fa-angle-double-left"
                                class="btn btn-link"
                                (click)="resetPersonSelection()">Back</button>
                        <span class="scu-toolbar-padding">Connected to {{selectedPerson.fullName}}</span>
                    </div>
                    <!-- needs the following div to inherit the height from parent so that the svg would know the height of the canvas -->
                    <div class="h-auto"
                         *adaptLoading="graphBusy">
                        <adapt-linked-nodes-graph [nodes]="displayNodes"
                                                  [links]="displayLinks"
                                                  [showName]="filterOptions.showName"
                                                  [showArrowHead]="showArrowHead"
                                                  [useCircleArrowHead]="useCircleArrowHead"
                                                  [hideLinks]="hideLinks"
                                                  [allowNodeSelection]="allowSelectPerson"
                                                  [showGreen]="showGreen"
                                                  [showOrange]="showOrange"
                                                  [showRed]="showRed"
                                                  (nodeSelected)="onNodeSelected($event)"
                                                  (linkSelected)="onLinkSelected($event)"></adapt-linked-nodes-graph>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
