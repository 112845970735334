import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLayoutModule } from "@common/ux/layout/layout.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptRerenderModule } from "@common/ux/rerender/rerender.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptTeamsSharedModule } from "@org-common/lib/teams/teams-shared.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { AdaptDiagramModule } from "app/general/diagram/diagram.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxListModule, DxSelectBoxModule, DxTabPanelModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptProcessMapSharedModule } from "../architecture/process-map/process-map-shared.module";
import { AdaptRoleSharedModule } from "../architecture/role/role-shared.module";
import { ConfigureSystemLocationsComponent } from "./configure-system-locations/configure-system-locations.component";
import { CopySystemComponentDialogComponent } from "./copy-system-component-dialog/copy-system-component-dialog.component";
import { DisplaySystemsComponent } from "./display-systems/display-systems.component";
import { EditExternalLinkDialogComponent } from "./edit-external-link-dialog/edit-external-link-dialog.component";
import { EditSystemDiagramDialogComponent } from "./edit-system-diagram-dialog/edit-system-diagram-dialog.component";
import { EditSystemDialogComponent } from "./edit-system-dialog/edit-system-dialog.component";
import { EditSystemDocumentDialogComponent } from "./edit-system-document-dialog/edit-system-document-dialog.component";
import { EditSystemLocationsComponent } from "./edit-system-locations/edit-system-locations.component";
import { EditSystemLocationsDialogComponent } from "./edit-system-locations-dialog/edit-system-locations-dialog.component";
import { EditVideoDocumentDialogComponent } from "./edit-video-document-dialog/edit-video-document-dialog.component";
import { LinkSystemComponent } from "./link-system/link-system.component";
import { LinkSystemGroupComponent } from "./link-system-group/link-system-group.component";
import { ListSystemsComponent } from "./list-systems/list-systems.component";
import { MoveSystemComponentDialogComponent } from "./move-system-component-dialog/move-system-component-dialog.component";
import { SystemComponentComponent } from "./system-component/system-component.component";
import { SystemComponentAddMenuComponent } from "./system-component-add-menu/system-component-add-menu.component";
import { SystemContentComponent } from "./system-content/system-content.component";
import { SystemDiagramComponent } from "./system-diagram.component/system-diagram.component";
import { SystemExternalLinkComponent } from "./system-external-link/system-external-link.component";
import { SystemPageComponent } from "./system-page/system-page.component";
import { systemPageRoute } from "./system-page/system-page.route";
import { AdaptSystemisationSharedModule } from "./systemisation-shared.module";
import { SystemsPageComponent } from "./systems-page/systems-page.component";
import { systemsPageRoute } from "./systems-page/systems-page.route";

@NgModule({
    declarations: [
        SystemsPageComponent,
        ListSystemsComponent,
        SystemContentComponent,
        SystemComponentAddMenuComponent,
        SystemComponentComponent,
        DisplaySystemsComponent,
        EditSystemLocationsComponent,
        EditSystemLocationsDialogComponent,
        SystemPageComponent,
        LinkSystemGroupComponent,
        SystemDiagramComponent,
        EditSystemDiagramDialogComponent,
        EditSystemDialogComponent,
        EditVideoDocumentDialogComponent,
        EditExternalLinkDialogComponent,
        MoveSystemComponentDialogComponent,
        CopySystemComponentDialogComponent,
        ConfigureSystemLocationsComponent,
        SystemExternalLinkComponent,
        EditSystemDocumentDialogComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AdaptSystemisationSharedModule,
        AdaptTwoColumnModule,
        AdaptEmbedShellModule,
        AdaptShellModule,
        AdaptButtonModule,
        AdaptMenuModule,
        AdaptHtmlEditorModule,
        AdaptProcessMapSharedModule,
        AdaptCallToActionModule,
        AdaptCommonDialogModule,
        AdaptEntityValidatorModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        AdaptKeyFunctionsCommonModule,
        AdaptTeamDashboardSharedModule,
        AdaptTeamsSharedModule,
        AdaptRoleSharedModule,
        AdaptDirectorySharedModule,
        AdaptAuthorisationModule,
        AdaptLayoutModule,
        AdaptDocumentSelectorModule,
        DxListModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        DxTabPanelModule,
        AdaptDiagramModule,
        AdaptRerenderModule,
        AdaptLabellingModule,
        AdaptZoneMapModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
    ],
    exports: [
        DisplaySystemsComponent,
        LinkSystemComponent,
        EditSystemLocationsComponent,
    ],
})
export class AdaptSystemisationModule {
    public static readonly Routes = [
        ...systemPageRoute.routes,
        ...systemsPageRoute.routes,

    ];
}
