import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CareerValuation, CareerValuationBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { CareerValuationCategoryValue, CareerValuationCategoryValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Link, LinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/link";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { lastValueFrom } from "rxjs";
import { CulturalLeadershipFrameworkAuthService } from "../cultural-leadership/cultural-leadership-framework-auth.service";
import { CulturalLeadershipQueryUtilities } from "../cultural-leadership/cultural-leadership-query-utilities";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { ViewCareerValuationDialogComponent } from "./view-cvt-dialog/view-cvt-dialog.component";

@Injectable({
    providedIn: "root",
})
export class OrgCommonCareerValuationService extends BaseOrganisationService {
    public static readonly EditCareerValuationForPerson = "editCareerValuationForPerson";

    private culturalLeadershipQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
        private clfAuthService: CulturalLeadershipFrameworkAuthService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
    }

    /**
     * Promise to get the latest career valuation for a specified person
     * @param {int} personId : The ID of the specified person
     * @returns {promise} A promise that resolves with a single career valuation
     */
    public async promiseToGetLatestCareerValuationForPerson(personId: number) {
        const clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

        const key = "latestCareerValuationForPerson" + personId;
        const options = {
            namedParams: {
                latestOnly: true,
            },
            predicate: new MethodologyPredicate<CareerValuation>("personId", "==", personId),
        };


        try {
            const accessLevel = await this.clfAuthService.checkCulturalAccessLevel(FeatureName.CulturalNetworkCareerValuation, personId);
            clQueryUtilities.setCulturalEntityOptions(options, accessLevel);

            const latestCareerValuations = await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationBreezeModel, key, options));
            const careerValuation = ArrayUtilities.getSingleFromArray(latestCareerValuations);

            if (!careerValuation) {
                return undefined;
            }

            const valuesKey = "latestCareerValuationValuesForPerson" + personId;
            const valuesOptions = {
                predicate: new MethodologyPredicate<CareerValuationCategoryValue>("careerValuationId", "==", careerValuation.careerValuationId),
            };

            clQueryUtilities.setCulturalEntityOptions(valuesOptions, accessLevel);
            await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationCategoryValueBreezeModel, valuesKey, valuesOptions));
            return careerValuation;

        } catch (error) {
            this.log.warn(error as string);
            return Promise.reject(error);
        }
    }

    /**
     * Promise to get career valuation
     * @param {int} careerValuationId : The ID of the career valuation
     * @returns {promise} A promise that resolves with a single career valuation
     */
    public async promiseToGetCareerValuation(careerValuationId: number) {
        const clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

        await lastValueFrom(this.commonDataService.getAll(CareerValuationCategoryBreezeModel));

        const key = "careerValuationById" + careerValuationId;
        const options = {
            predicate: new MethodologyPredicate<CareerValuation>("careerValuationId", "==", careerValuationId),
        };

        try {
            const accessLevel = await this.clfAuthService.checkCulturalAccessLevel(FeatureName.CulturalNetworkCareerValuation);
            clQueryUtilities.setCulturalEntityOptions(options, accessLevel);

            const careerValuations = await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationBreezeModel, key, options));
            const careerValuation = ArrayUtilities.getSingleFromArray(careerValuations);

            if (!careerValuation) {
                return undefined;
            }

            const valuesKey = "latestCareerValuationValuesForId" + careerValuation.careerValuationId;
            const valuesOptions = {
                predicate: new MethodologyPredicate<CareerValuationCategoryValue>("careerValuationId", "==", careerValuation.careerValuationId),
            };

            clQueryUtilities.setCulturalEntityOptions(valuesOptions, accessLevel);
            await lastValueFrom(this.commonDataService.getWithOptions(CareerValuationCategoryValueBreezeModel, valuesKey, valuesOptions));
            return careerValuation;

        } catch (error) {
            this.log.warn(error as string);
            return Promise.reject(error);
        }
    }

    public viewCareerValuationDialog(cv: CareerValuation) {
        return this.dialogService.open(ViewCareerValuationDialogComponent, cv);
    }

    /**
     * Promise to get all career valuations for a specified person
     * @param personId : The Id of the specified person
     * @returns A promise that resolves with an array of career valuations
     */
    public async promiseToGetAllCareerValuationsForPerson(personId: number) {
        try {
            const accessLevel = await this.clfAuthService.checkCulturalAccessLevel(FeatureName.CulturalNetworkCareerValuation, personId);

            // Ensure the categories are all primed for sort of values below
            await lastValueFrom(this.commonDataService.getAll(CareerValuationCategoryBreezeModel));

            const careerValuationOptions: IBreezeQueryOptions<CareerValuation> = {
                predicate: new MethodologyPredicate<CareerValuation>("personId", "==", personId),
            };
            this.culturalLeadershipQueryUtilities.setCulturalEntityOptions(careerValuationOptions, accessLevel);
            const careerValuations = await lastValueFrom(this.commonDataService.getWithOptions<CareerValuation>(
                CareerValuationBreezeModel,
                "allCareerValuationsForPerson" + personId,
                careerValuationOptions,
            ));

            const careerValuationValuesOptions: IBreezeQueryOptions<CareerValuationCategoryValue> = {
                predicate: new MethodologyPredicate<CareerValuationCategoryValue>("careerValuation.personId", "==", personId),
            };
            this.culturalLeadershipQueryUtilities.setCulturalEntityOptions(careerValuationValuesOptions, accessLevel);
            await lastValueFrom(this.commonDataService.getWithOptions(
                CareerValuationCategoryValueBreezeModel,
                "allCareerValuationValuesForPerson" + personId,
                careerValuationValuesOptions,
            ));

            const linkOptions: IBreezeQueryOptions<Link> = {
                predicate: new MethodologyPredicate<Link>("careerValuationId", "in", careerValuations.map((cv) => cv.careerValuationId)),
                navProperty: "item",
            };
            this.culturalLeadershipQueryUtilities.setCulturalEntityOptions(linkOptions, accessLevel);
            await lastValueFrom(this.commonDataService.getWithOptions(
                LinkBreezeModel,
                "allCareerValuationLinksForPerson" + personId,
                linkOptions,
            ));

            for (const careerValuation of careerValuations) {
                careerValuation.categoryValues.sort((a, b) => a.category.ordinal - b.category.ordinal);
            }

            return careerValuations;
        } catch (e: any) {
            this.log.warn(e);
            throw e;
        }
    }
}
