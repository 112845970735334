import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { FeaturesService } from "../../features/features.service";
import { DisplayCatchupVisibilityComponent } from "../display-catchup-visibility/display-catchup-visibility.component";

@Component({
    selector: "adapt-select-catchup-team",
    templateUrl: "./select-catchup-team.component.html",
    styleUrls: ["./select-catchup-team.component.scss"],
})
export class SelectCatchupTeamComponent extends BaseComponent implements OnInit {
    @Input() public catchup?: SpeedCatchup;
    @Input() public isEditing = true;
    @Input() public showSelectionLabel = true;

    @Output() public selectionValidityChange = new EventEmitter<boolean>();
    @Output() public selectionChange = new EventEmitter<Team | undefined>();

    @ViewChild(DisplayCatchupVisibilityComponent) private displayVisibilityComponent?: DisplayCatchupVisibilityComponent;

    public validationStates = {
        hasCommonTeams: false,
        pcuEnabledOnTeam: true,
        isCommonTeamValid: false,
    };
    public isValidSelection = false;
    public selectCommonTeamsOptions = {
        allowNullSelection: true,
        nullSelectionLabel: "All teams",
    };
    public invalidTeamMessage = "";

    public constructor(
        private featuresService: FeaturesService,
    ) {
        super();
    }

    public ngOnInit() {
        // force the validation to run - when a team is not selected, the component was not being initialised properly because onTeamSelection() was not run
        this.onTeamSelection(this.catchup?.team);
    }

    public get isSelectTeamVisible() {
        return this.isEditing && this.validationStates.hasCommonTeams;
    }

    public async onTeamSelection(team?: Team) {
        this.catchup!.team = team;

        const pcuEnabledOnTeam = await this.featuresService.promiseToCheckIfFeatureActive(FeatureName.PeoplePeerCatchUp, team);
        this.validationStates.pcuEnabledOnTeam = pcuEnabledOnTeam;
        this.validateSelections();

        if (!this.validationStates.pcuEnabledOnTeam) {
            this.invalidTeamMessage = "Peer Catch-ups are not enabled on this team.";
        } else {
            this.invalidTeamMessage = "";
        }

        this.selectionChange.emit(team);
    }


    public setHasCommonTeams(commonTeams: Team[]) {
        this.validationStates.hasCommonTeams = commonTeams.length > 0;
        this.validateSelections();

        // If a new catchup, and there is no common team, deselect it.
        if (this.catchup?.team && commonTeams.indexOf(this.catchup.team) < 0 &&
            this.isEditing && this.catchup.entityAspect.entityState.isAdded()) {
            this.catchup.team = undefined;
            this.onTeamSelection(undefined);
        }
    }

    public validateSelections() {
        this.isValidSelection = this.validationStates.pcuEnabledOnTeam;
        this.selectionValidityChange.emit(this.isValidSelection);
        this.displayVisibilityComponent?.refreshText();
    }
}
