import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Layout } from "@common/ADAPT.Common.Model/organisation/layout";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemKnowledgeExpert } from "@common/ADAPT.Common.Model/organisation/system-knowledge-expert";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { LayoutManagerComponent } from "@common/ux/layout/layout-manager/layout-manager.component";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { ProcessMapService } from "app/features/architecture/process-map/process-map.service";
import { IMapStackFramesChangedEvent } from "app/features/architecture/process-map/process-steps-card/process-steps-card.component";
import isEqual from "lodash.isequal";
import { BehaviorSubject, merge, Observable, Subject } from "rxjs";
import { debounceTime, filter, map, switchMap, tap } from "rxjs/operators";
import { systemPageRoute } from "../system-page/system-page.route";
import { SystemisationService } from "../systemisation.service";
import { SystemisationUiService } from "../systemisation-ui.service";
import { systemsPageRoute } from "../systems-page/systems-page.route";

@Component({
    selector: "adapt-system-content",
    templateUrl: "./system-content.component.html",
    styleUrls: ["./system-content.component.scss"],
})
export class SystemContentComponent extends BaseComponent implements OnChanges {
    public readonly EditTier2 = IntegratedArchitectureFrameworkAuthService.EditTier2;
    public readonly ReadTier1 = Tier1ArchitectureAuthService.ReadTier1;

    @Input() public system?: SystemEntity;
    @Input() public showTitle = true;
    @Input() public isEditSystemPage = false;

    @Input() public processMapStates: IMapStackFramesChangedEvent[] = [];
    @Output() public processMapStatesChange = new EventEmitter<IMapStackFramesChangedEvent[]>();
    @Output() public systemDeleted = new EventEmitter<SystemEntity>();
    @Output() public isEditingChange = new EventEmitter<boolean>();
    @Output() public initialised = new EventEmitter<void>();

    public isEditing = false;
    public editingDetailsRerender = new BehaviorSubject<void>(void 0);
    public systemComponents: SystemComponent[] = [];

    public triggerComponentUpdate$ = new Subject<void>();
    public additionalChangedEntities: IBreezeEntity[] = [];
    public canEdit$: Observable<boolean>;

    @Input() public displayContext?: Role | Team | KeyFunction;
    public otherRoleSystems: SystemLocation[] = [];
    public otherTeamSystems: SystemLocation[] = [];
    public otherKeyFunctionSystems: SystemLocation[] = [];
    public zoneSystems: SystemLocation[] = [];

    // tag newly added component
    @Input() public addedComponentId?: number;

    private deleteSystemMenuItem: IAdaptMenuItem = {
        icon: "fal fa-fw fa-trash-alt",
        text: "Delete system",
        onClick: () => this.deleteSystem(this.system!).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(),
        separator: true,
    };

    private switchToEditMenuItem: IAdaptMenuItem = {
        icon: "fal fa-fw fa-edit",
        text: "Switch to edit mode",
        onClick: () => this.toggleEditing(),
        separator: false,
    };

    private switchToViewMenuItem: IAdaptMenuItem = {
        text: "Switch to view mode",
        icon: "fal fa-fw fa-eye",
        onClick: () => this.toggleEditing(),
        separator: true,
    };

    private editSystemDetailsMenuItem: IAdaptMenuItem = {
        icon: "fal fa-fw fa-edit",
        text: "Edit system details",
        onClick: () => this.editSystem().pipe(
            this.takeUntilDestroyed(),
        ).subscribe(),
        separator: false,
    };

    private editLayoutMenuItem: IAdaptMenuItem = {
        icon: "fal fa-fw fa-arrows-alt",
        text: "Edit layout",
        onClick: () => this.layoutManager?.toggleEditing(true).subscribe(),
    };

    public configureSystemMenu: IAdaptMenuItem[] = [{
        text: "Configure system",
        icon: "fal fa-fw fa-cog",
        items: [
            this.editSystemDetailsMenuItem,
            this.editLayoutMenuItem,
            this.switchToViewMenuItem,
            this.switchToEditMenuItem,
            this.deleteSystemMenuItem,
        ],
    }];

    private processMapStatesCache: { [key: number]: IMapStackFramesChangedEvent } = {};

    // prevent layout manager which is used in multiple places in the template from ExpressionChangedAfterItHasBeenCheckedError
    // by not allowing it to be set when view initialized
    public layoutManager?: LayoutManagerComponent;
    private layoutManagerUpdater = this.createThrottledUpdater((value?: LayoutManagerComponent) => this.layoutManager = value);
    @ViewChild(LayoutManagerComponent) public set layoutManagerSetter(value: LayoutManagerComponent | undefined) {
        this.layoutManagerUpdater.next(value);
    }

    private triggerCanEditUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private systemisationService: SystemisationService,
        private systemisationUiService: SystemisationUiService,
        private commonArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
        private archAuthService: IntegratedArchitectureFrameworkAuthService,
        rxjsBreezeService: RxjsBreezeService,
        processMapService: ProcessMapService,
        labellingService: LabellingService,
    ) {
        super();

        this.canEdit$ = this.triggerCanEditUpdate$.pipe(
            switchMap(() => Promise.resolve(this.commonArchAuthService.currentPersonCanEntity<SystemEntity>(this.archAuthService.personCanEditTier2, this.system))),
        );
        this.triggerComponentUpdate$.pipe(
            debounceTime(100),
            filter(() => !!this.system),
            switchMap(() => labellingService.getLabelLocationsForSystem(this.system!.systemEntityId)), // prime system label
            switchMap(() => this.systemisationService.getSystemByIdWithLayout(this.system!.systemEntityId)), // prime layout
            switchMap(() => this.systemisationService.primeLocationsForSystem(this.system!.systemEntityId)), // prime locations
            switchMap(() => this.systemisationService.getSystemComponentsForSystem(this.system!.systemEntityId)),
            switchMap((systemComponents) => this.canEdit$.pipe(
                map((canEdit) => { // only enter editing mode with edit permission if no system component
                    if (!canEdit && (this.isEditing || this.systemisationService.systemContentIsEditing)) {
                        // we don't have edit permissions here, make sure we turn off editing
                        this.isEditing = false;
                        this.systemisationService.systemContentIsEditing = false;
                    }

                    if (canEdit && systemComponents.length < 1 && this.isEditSystemPage) {
                        this.isEditing = true;
                        this.systemisationService.systemContentIsEditing = true;
                    }

                    return systemComponents;
                }),
            )),
            this.takeUntilDestroyed(),
        ).subscribe((systemComponents) => {
            const componentsChanged = !isEqual(systemComponents, this.systemComponents);
            this.systemComponents = systemComponents;
            this.updateConfigureMenu();

            this.otherKeyFunctionSystems = this.system!.systemLocations.filter((i) => i.keyFunction && i.keyFunction !== this.displayContext);
            this.otherTeamSystems = this.system!.systemLocations.filter((i) => i.team && i.team.isActive() && i.team !== this.displayContext);
            this.otherRoleSystems = this.system!.systemLocations.filter((i) => i.role && i.role.isActive() && i.role !== this.displayContext);
            this.zoneSystems = this.system!.systemLocations.filter((i) => i.zone);

            setTimeout(() => {
                // rerender layout as new components added/removed
                if (componentsChanged) {
                    this.editingDetailsRerender.next();
                }
            });

            this.isInitialised = true;
        });

        merge(
            rxjsBreezeService.entityTypeChanged(SystemComponent),
            rxjsBreezeService.entityTypeChanged(SystemKnowledgeExpert),
            rxjsBreezeService.entityTypeChanged(SystemLocation),
        ).pipe(
            filter((i) => i.systemEntityId === this.system?.systemEntityId),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerComponentUpdate$.next());

        rxjsBreezeService.entityTypeChanged(Layout).pipe(
            filter((i) => this.system?.layoutId === i.layoutId),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            if (this.layoutManager?.isEditing) {
                // entity sync already taken care of the confirmation
                // discard editing if layout is unchanged (accepted from remote or no local change)
                if (this.system?.layout?.entityAspect.entityState.isUnchanged()) {
                    this.editingDetailsRerender.next();
                }
                // otherwise, do nothing -> entity modified and still in edit mode, layout manager should be able to continue
            } else {
                this.editingDetailsRerender.next();
            }
        });

        merge(
            rxjsBreezeService.entityTypeChanged(SystemEntity),
            rxjsBreezeService.entityTypeChanged(SystemLocation),
        ).pipe(
            filter((changedEntity) =>
                !(changedEntity.entityAspect.entityState.isDeleted() || changedEntity.entityAspect.entityState.isDetached())),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerCanEditUpdate$.next());

        processMapService.stepCopiedOrMoved$.pipe(
            filter((systemComponent) => systemComponent.systemEntityId === this.system?.systemEntityId),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe((systemComponent) => this.onSystemComponentAdded(systemComponent));

        this.updateConfigureMenu();
    }

    public onLayoutManagerInitialised() {
        this.initialised.emit();
    }

    public get hasOwnerOrKnowledgeExpert() {
        if (this.system) {
            return this.system.ownerId || this.system.systemKnowledgeExperts.length > 0;
        }

        return false;
    }

    public get hasLocations() {
        if (this.system && this.systemComponents.length > 0) {
            // only shows related roles, teams and key functions if the CTA is not shown (looks wierd showing these under CTA)
            return this.otherRoleSystems.length > 0
                || this.otherTeamSystems.length > 0
                || this.otherKeyFunctionSystems.length > 0
                || this.zoneSystems.length > 0;
        }

        return false;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.system && this.system) {
            this.isInitialised = false;
            this.triggerComponentUpdate$.next();
        }

        if (changes.processMapStates && this.processMapStates) {
            this.updateProcessMapStatesCache();
        }

        if (changes.isEditSystemPage) {
            this.isEditing = this.systemisationService.systemContentIsEditing && this.isEditSystemPage;
            this.updateConfigureMenu();
        }
    }

    @Autobind
    public editSystem() {
        return this.systemisationUiService.openEditSystemDialog(this.system!).pipe(
            tap(() => this.editingDetailsRerender.next()),
        );
    }

    @Autobind
    public deleteSystem(system: SystemEntity) {
        return this.systemisationUiService.deleteSystem(system).pipe(
            tap(() => {
                this.systemDeleted.emit(this.system);
                this.system = undefined;
                this.isEditing = false;
                this.systemisationService.systemContentIsEditing = false;
                if (this.isEditSystemPage) {
                    // only change page if is currently is editing system page
                    setTimeout(() => systemsPageRoute.gotoRoute().subscribe());
                }
            }),
        );
    }

    public toggleEditing() {
        this.isEditing = !this.isEditing;
        this.systemisationService.systemContentIsEditing = this.isEditing;
        if (!this.isEditing) {
            this.layoutManager?.toggleEditing(false)
                .subscribe();
        }

        setTimeout(() => this.updateConfigureMenu(), 100);
        this.isEditingChange.emit(this.isEditing);
    }

    public gotoSystemPage(system: SystemEntity) {
        return systemPageRoute.gotoRoute({ systemEntityId: system.systemEntityId });
    }

    public onProcessMapStateChanged(processMapState: IMapStackFramesChangedEvent) {
        const existingState = this.processMapStates.find((i) => i.rootProcessMapId === processMapState.rootProcessMapId);
        if (existingState) {
            if (processMapState.frames.length > 1) {
                ArrayUtilities.updateArrayElement(this.processMapStates, existingState, processMapState);
            } else {
                ArrayUtilities.removeElementFromArray(existingState, this.processMapStates);
            }
        } else if (processMapState.frames.length > 1) {
            this.processMapStates.push(processMapState);
        }

        this.updateProcessMapStatesCache();

        this.processMapStatesChange.emit(this.processMapStates);
    }

    public onSystemComponentAdded(systemComponent: SystemComponent) {
        this.addedComponentId = systemComponent.systemComponentId;
        this.triggerComponentUpdate$.next();
    }

    public onNewComponentAnimated(systemComponent?: SystemComponent) {
        if (systemComponent?.systemComponentId === this.addedComponentId) {
            this.addedComponentId = undefined; // so that any further refresh from layout manager rerender or element deletion won't trigger the animation again
        }
    }

    public getProcessMapState(systemComponent: SystemComponent) {
        if (systemComponent.systemProcessMapId) {
            return this.processMapStatesCache[systemComponent.systemProcessMapId];
        } else {
            return undefined;
        }
    }

    private updateProcessMapStatesCache() {
        this.processMapStatesCache = {};
        for (const state of this.processMapStates) {
            this.processMapStatesCache[state.rootProcessMapId] = state;
        }
    }

    private updateConfigureMenu() {
        this.editLayoutMenuItem.visible = this.isEditing && this.systemComponents.length > 0;
        this.editSystemDetailsMenuItem.visible = this.isEditing;
        this.switchToViewMenuItem.visible = this.isEditing;
        this.switchToEditMenuItem.visible = !this.isEditing;

        if (this.system) {
            const hasTeamSystemConfigure = this.system.systemLocations.some((sl) => sl.team && this.commonArchAuthService.currentPersonCanEntity<Team>(this.commonArchAuthService.personCanConfigureTier2, sl.team));
            const hasGlobalConfigure = this.commonArchAuthService.currentPersonCan(this.commonArchAuthService.personCanConfigureTier2);
            this.deleteSystemMenuItem.visible = hasTeamSystemConfigure || hasGlobalConfigure;
        }
    }
}
