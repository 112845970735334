import { Inject, Injectable, Injector } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TEAM_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseService } from "@common/service/base.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { delay, switchMap } from "rxjs";
import { CommonTeamsService } from "./common-teams.service";
import { ConfigureTeamStewardshipDialogComponent } from "./configure-team-stewardship-dialog/configure-team-stewardship-dialog.component";

@Injectable({
    providedIn: "root",
})
export class CommonTeamsUiService extends BaseService {
    public constructor(
        injector: Injector,
        private commonDialogService: AdaptCommonDialogService,
        private teamsService: CommonTeamsService,
        @Inject(TEAM_CONFIGURATION_PAGE) private teamConfigurationPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
        super(injector);
    }

    public reactivateTeam(team: Team) {
        const dialogData: IConfirmationDialogData = {
            title: "Reactivate team",
            message: "Reactivating the team will make it visible to everyone in the organisation.<br /><br /><b>After reactivating the team, update any required data on team's configuration page (such as the people, action boards, active features).</b>",
            checkboxMessage: "Confirm that you wish to reactivate this team",
        };

        return this.commonDialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => this.teamsService.reactivateTeam(team)),
            delay(50), // force a delay to allow the route change to function without hanging the browser (unknown cause)
            switchMap(() => this.teamConfigurationPageRoute.gotoRoute({ teamId: team.teamId })),
        );
    }

    public configureTeamStewardship(team: Team) {
        return this.commonDialogService.open(ConfigureTeamStewardshipDialogComponent, team);
    }
}
