<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Move action</h3>
    <div adapt-dialog-content>
        <div class="alert alert-info">
            <p>Changing the board associated to an action will have the following effects</p>
            <ul>
                <li>The board on which the action is visible will change.</li>
            </ul>
        </div>

        <!-- board -->
        <label class="mb-2">Destination Board</label>
        <adapt-select-board id="inputBoard"
                            [boards]="item.board ? [item.board] : []"
                            (boardsChange)="onBoardsChange($event[0])"
                            [required]="true"
                            [allowMultiSelection]="false"
                            [showEditableBoardsOnly]="true"
                            [useDropDown]="false"
                            data-test="move-item-board-dropdown"></adapt-select-board>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="item.entityAspect.entityState.isUnchanged() || !item.board"
                                   (saveClick)="save()"
                                   (cancelClick)="cancel()"
                                   data-test="move-item-dialog-buttons">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
