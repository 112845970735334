import { Component, Input, OnInit } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { CatchupRatingValue, RatingValues } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { OrgCommonCatchupUiService } from "../org-common-catchup-ui.service";

const TooltipText: { [key: string]: string } = {
    Green: "All individual ratings were green, indicating no issues exist",
    Orange: "One or more individual ratings were orange, indicating minor issues exist",
    Red: "One or more individual ratings were red, indicating major issues exist",
};

@Component({
    selector: "adapt-catchup-rating-cell",
    templateUrl: "./catchup-rating-cell.component.html",
    styleUrls: ["../peer-catchup-styles.scss"],
})
export class CatchupRatingCellComponent extends BaseComponent implements OnInit {
    @Input() public catchup!: SpeedCatchup;

    public ratingClass = "catchup-rating-unset";
    public tooltipText = "";

    public constructor(
        private catchupUiService: OrgCommonCatchupUiService,
    ) {
        super();
    }

    public ngOnInit() {
        const worstRating = this.catchup.ratings
            .map((i) => i.rating)
            .reduce((worstValue, currentValue) =>
                RatingValues[currentValue] > RatingValues[worstValue] ? currentValue : worstValue,
                CatchupRatingValue.Green);
        this.ratingClass = `catchup-rating-${worstRating}`;
        this.tooltipText = TooltipText[worstRating];
    }

    public showCatchup() {
        this.catchupUiService.showCatchup(this.catchup).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public showHistory() {
        this.catchupUiService.showCatchupHistoryChart(this.catchup.person1!, this.catchup.person2!).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}
