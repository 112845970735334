import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { SystemKnowledgeExpert } from "@common/ADAPT.Common.Model/organisation/system-knowledge-expert";
import { SystemLocation } from "@common/ADAPT.Common.Model/organisation/system-location";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { SelectKeyFunctionComponent } from "@org-common/lib/architecture/key-functions/select-key-function/select-key-function.component";
import { SelectTeamComponent } from "@org-common/lib/teams/team-dashboard-shared/select-team/select-team.component";
import { SelectZoneComponent } from "@org-common/lib/zone-map/select-zone/select-zone.component";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { SelectRoleComponent } from "app/features/architecture/role/select-role/select-role.component";
import { lastValueFrom, merge } from "rxjs";
import { debounceTime, filter, startWith, switchMap, tap } from "rxjs/operators";
import { SystemisationService } from "../systemisation.service";

@Component({
    selector: "adapt-configure-system-locations",
    templateUrl: "./configure-system-locations.component.html",
    styleUrls: ["./configure-system-locations.component.scss"],
})
export class ConfigureSystemLocationsComponent extends BaseComponent implements OnInit {
    @Input() public system!: SystemEntity;
    @Output() public systemLocationChange = new EventEmitter<SystemLocation>();

    public roleLocations: SystemLocation[] = [];
    public teamLocations: SystemLocation[] = [];
    public keyFunctionLocations: SystemLocation[] = [];
    public zoneLocations: SystemLocation[] = [];

    public hasGlobalEdit = false;
    public configurableTeams: Team[] = [];

    public selectedRole?: Role;
    @ViewChild(SelectRoleComponent) private selectRoleComponent?: SelectRoleComponent;

    public selectedTeam?: Team;
    @ViewChild(SelectTeamComponent) private selectTeamComponent?: SelectTeamComponent;

    public selectedKeyFunction?: KeyFunction;
    @ViewChild(SelectKeyFunctionComponent) private selectKeyFunctionComponent?: SelectKeyFunctionComponent;

    public selectedZone?: Zone;
    @ViewChild(SelectZoneComponent) private selectZoneComponent?: SelectZoneComponent;

    public constructor(
        private commonDataService: CommonDataService,
        private systemisationService: SystemisationService,
        private commonArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
        private archAuthService: IntegratedArchitectureFrameworkAuthService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
    }

    public ngOnInit() {
        this.hasGlobalEdit = this.commonArchAuthService.currentPersonCan(this.commonArchAuthService.personCanConfigureTier2);

        merge(
            this.rxjsBreezeService.entityTypeChanged(SystemComponent),
            this.rxjsBreezeService.entityTypeChanged(SystemKnowledgeExpert),
            this.rxjsBreezeService.entityTypeChanged(SystemLocation),
        ).pipe(
            startWith(this.system),
            filter((i) => i.systemEntityId === this.system?.systemEntityId),
            debounceTime(100),
            switchMap(() => this.archAuthService.getConfigurableTeams()),
            tap((teams) => this.configurableTeams = teams),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.systemisationService.primeLocationsForSystem(this.system.systemEntityId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.roleLocations = this.system.systemLocations.filter((i) => i.role && i.role.isActive());
            this.teamLocations = this.system.systemLocations.filter((i) => i.team && i.team.isActive());
            this.keyFunctionLocations = this.system.systemLocations.filter((i) => i.keyFunction);
            this.zoneLocations = this.system.systemLocations.filter((i) => i.zone);
        });
    }

    public onRoleSelected(role: Role) {
        // after a role is selected, selected role will be reset to allow adding another role -> in that case, role will be undefined
        if (role) {
            this.systemisationService.getSystemLocationsForRole(role).pipe(
                switchMap((targetLocations) => this.createSystemLocation(this.roleLocations, targetLocations)),
            ).subscribe((systemLocation) => {
                systemLocation.role = role;
                setTimeout(() => {
                    // need this to be done in the next digest cycle as this is from the callback of a selection change - can't change it here!
                    this.selectedRole = undefined;
                    this.selectRoleComponent?.reload(); // need this reload to refresh the list, not showing already selected roles
                });
            });
        }
    }

    public async deleteRoleLocation(roleLocation: SystemLocation) {
        await this.deleteSystemLocation(roleLocation, this.roleLocations);
        this.selectRoleComponent?.reload();
    }

    @Autobind
    public roleFilter(role: Role) {
        return !this.roleLocations.find((l) => l.roleId === role.roleId);
    }

    public onTeamSelected(team?: Team) {
        if (team) {
            this.systemisationService.getSystemLocationsForTeam(team).pipe(
                switchMap((targetLocations) => this.createSystemLocation(this.teamLocations, targetLocations)),
            ).subscribe((systemLocation) => {
                systemLocation.team = team;
                setTimeout(() => {
                    this.selectedTeam = undefined;
                    this.selectTeamComponent?.reload();
                });
            });
        }
    }

    public async deleteTeamLocation(teamLocation: SystemLocation) {
        await this.deleteSystemLocation(teamLocation, this.teamLocations);
        this.selectTeamComponent?.reload();
    }

    @Autobind
    public teamFilter(team: Team) {
        return !this.teamLocations.find((l) => l.teamId === team.teamId)
            && this.configurableTeams.includes(team);
    }

    public onKeyFunctionSelected(keyFunction: KeyFunction) {
        if (keyFunction) {
            this.systemisationService.getSystemLocationsForKeyFunction(keyFunction).pipe(
                switchMap((targetLocations) => this.createSystemLocation(this.keyFunctionLocations, targetLocations)),
            ).subscribe((systemLocation) => {
                systemLocation.keyFunction = keyFunction;
                setTimeout(() => {
                    this.selectedKeyFunction = undefined;
                    this.selectKeyFunctionComponent?.reload();
                });
            });
        }
    }

    public async deleteKeyFunctionLocation(kfLocation: SystemLocation) {
        await this.deleteSystemLocation(kfLocation, this.keyFunctionLocations);
        this.selectKeyFunctionComponent?.reload();
    }

    @Autobind
    public keyFunctionFilter(keyFunction: KeyFunction) {
        return !this.keyFunctionLocations.find((l) => l.keyFunctionId === keyFunction.keyFunctionId);
    }

    public onZoneSelected(zone: Zone) {
        if (zone) {
            this.systemisationService.getSystemLocationsForZone(zone).pipe(
                switchMap((targetLocations) => this.createSystemLocation(this.zoneLocations, targetLocations)),
            ).subscribe((systemLocation) => {
                systemLocation.zone = zone;
                // this subscription becomes synchronous after data service port, i.e. this is called from the stack
                // of the selectBox value change callback. If changing the selectedZone here without setTimeout,
                // the selectedZone will be set back to the last selected valuea gain. So do this in the next digest cycle
                // or you will see invalid selection immediately after selecting a zone.
                setTimeout(() => {
                    this.selectedZone = undefined;
                    this.selectZoneComponent?.reload();
                });
            });
        }
    }

    public async deleteZoneLocation(zoneLocation: SystemLocation) {
        await this.deleteSystemLocation(zoneLocation, this.zoneLocations);
        this.selectZoneComponent?.reload();
    }

    @Autobind
    public zoneFilter(zone: Zone) {
        return !this.zoneLocations.find((l) => l.zone === zone);
    }

    private createSystemLocation(existingCollection: SystemLocation[], targetLocations: SystemLocation[]) {
        const nextOrdinal = targetLocations.length
            ? Math.max(...targetLocations.map((l) => l.ordinal)) + 1
            : 0;
        return this.systemisationService.createSystemLocation(this.system, nextOrdinal).pipe(
            tap((systemLocation) => {
                existingCollection.push(systemLocation);
                this.systemLocationChange.emit(systemLocation);
            }),
            this.takeUntilDestroyed(),
        );
    }

    public async deleteSystemLocation(systemLocation: SystemLocation, existingCollection: SystemLocation[]) {
        const removeElement = existingCollection.find((l) => l.systemLocationId === systemLocation.systemLocationId);
        if (removeElement) {
            ArrayUtilities.removeElementFromArray(removeElement, existingCollection);
            await lastValueFrom(this.commonDataService.remove(removeElement));
            this.systemLocationChange.emit(systemLocation);
        }
    }
}
