import { Injectable } from "@angular/core";
import { Feature, FeatureModule } from "@common/ADAPT.Common.Model/embed/feature";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { featurePermissionTeamTranslations, featurePermissionTranslations } from "./feature-permission-translations";

@Injectable({
    providedIn: "root",
})
export class FeaturePermissionTranslatorService {
    private readonly FeatureModuleLiterals: { [key in FeatureModule]: string } = {
        [FeatureModule.Architecture]: "Architecture",
        [FeatureModule.Compass]: "Organisation guidance",
        [FeatureModule.CulturalNetwork]: "Cultural network",
        [FeatureModule.People]: "People",
        [FeatureModule.Platform]: "Platform",
        [FeatureModule.Stewardship]: "Stewardship",
        [FeatureModule.Organisation]: "Organisation",
        [FeatureModule.Stakeholders]: "Stakeholders",
    };

    private readonly FeatureGroupLiterals: { [key: string]: string } = {
        ["Profile"]: "Profile",
        ["Position"]: "Profile",
        ["People"]: "Teams",
        ["Team"]: "Teams",
        ["PeerCatchUp"]: "Peer Catch-up",
        ["NetworkHealth"]: "Network health",
        ["Health"]: "Network health",
        ["Cohort"]: "Cultural leadership",
        ["CulturalLeadership"]: "Cultural leadership",
        ["CareerValuation"]: "Career Valuation",
        ["CulturalIndex"]: "Cultural Index",
        ["Kanban"]: "Kanban",
        ["Meetings"]: "Meetings",
        ["Guidance"]: "Guidance",
        ["Stewardship"]: "Organisation stewardship",
        ["MyGuidance"]: "Personal guidance",
        ["GuidingPhilosophy"]: "Purpose & vision",
        ["ResilientBusinessGoals"]: "Resilient business goals",
        ["ValuesConstitution"]: "Values",
        ["TaskMaps"]: "Task maps",
        ["TaskGuidance"]: "Task guidance",
        ["RoleDescriptions"]: "Architectural role",
        ["ValueStreams"]: "Value streams",
        ["Tier1"]: "Tier 1 architecture",
        ["Tier2"]: "Tier 2 architecture",
        ["AccessManagement"]: "Access management",
        ["Billing"]: "Billing",
        ["Api"]: "Developer API",
        ["Standard"]: "Standard",
        ["ExternalDashboard"]: "External dashboard",
        ["EmployeeEngagement"]: "Employee engagement surveys",
        ["TeamAssessment"]: "Team assessment surveys",
        ["ResilientBusinessAssessment"]: "Resilient business assessment",
        ["ValueStreamContents"]: "Value stream contents",
        ["Bullseye"]: "Bullseye",
        ["StrategyBoard"]: "Strategy board",
        ["StrategicInputs"]: "Strategic inputs",
        ["StrategicGoals"]: "Strategic goals",
        ["StrategicAnchors"]: "Strategic anchors",
    };

    private readonly FeatureGroupOverrides: { [key: string]: string } = {
        [FeatureName.StakeholdersPeople]: "People",
        [FeatureName.OrganisationBilling]: "Configuration",
        [FeatureName.Organisation]: "Configuration",
    };

    public translateFeaturePermission(featurePermission: FeaturePermission, team?: Team) {
        if (!featurePermission.name) {
            // TODO We should make this property not null in the DB
            throw new Error(`FeaturePermission should have a name ${featurePermission.featurePermissionId}`);
        }

        if (team?.name) {
            return featurePermissionTeamTranslations[featurePermission.name];
        }

        return featurePermissionTranslations[featurePermission.name];
    }

    public translatePermissionLiteral(featurePermissionName: FeaturePermissionName, team?: Team) {
        return team?.name
            ? featurePermissionTeamTranslations[featurePermissionName]
            : featurePermissionTranslations[featurePermissionName];
    }

    public translateFeatureModule(featureModule: FeatureModule) {
        return this.FeatureModuleLiterals[featureModule];
    }

    public getFeatureGroupName(feature: Feature) {
        const featureGroupNameOverride = this.FeatureGroupOverrides[feature.name];
        if (featureGroupNameOverride) {
            return featureGroupNameOverride;
        }

        const lastDot = feature.name.lastIndexOf(".");
        if (lastDot > 0) {
            const groupKey = feature.name.substring(lastDot + 1);
            let groupName = this.FeatureGroupLiterals[groupKey];

            if (!groupName) {
                groupName = groupKey;
            }

            return groupName;
        } else {
            return "Others";
        }
    }
}
