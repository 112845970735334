<adapt-dialog [maxWidth]="900"
              [padding]="false">
    <h3 adapt-dialog-title>{{title}}</h3>
    <div adapt-dialog-content
         class="catchup-content">
        <ng-container *adaptLoading="!catchup || isCatchupDetached">
            <div class="catchup-header">
                <adapt-link-person [person]="catchup.person1"
                                   [showImage]="true"
                                   [adaptLinkDisabled]="true"></adapt-link-person>
                <adapt-link-person class="ms-4"
                                   [person]="catchup.person2"
                                   [showImage]="true"
                                   [adaptLinkDisabled]="true"></adapt-link-person>
                <adapt-select-catchup-team class="ms-auto"
                                           [catchup]="catchup"
                                           [showSelectionLabel]="false"
                                           [isEditing]="isEditing"></adapt-select-catchup-team>
            </div>

            <dx-accordion [dataSource]="accordionEntries"
                          [selectedItems]="selectedEntries"
                          [multiple]="true"
                          (onItemRendered)="onAccordionItemRendered()">
                <div *dxTemplate="let accordionEntry of 'ratingTypeTitleTemplate'">
                    <h2>{{accordionEntry.ratingType.name}}</h2>
                    <i class="fal fa-info-circle"
                       [adaptTooltip]="accordionEntry.ratingType.shortGuidance"></i>
                </div>
                <div *dxTemplate="let accordionEntry of 'ratingTypeTemplate'">
                    <adapt-edit-catchup-rating-type [catchup]="catchup"
                                                    [ratingType]="accordionEntry.ratingType"
                                                    [isEditing]="isEditing"
                                                    [showTitle]="false"></adapt-edit-catchup-rating-type>
                </div>

                <div *dxTemplate="let title of 'facilitatorTitleTemplate'">
                    <h2>Facilitator</h2>
                </div>
                <div *dxTemplate="let accordionEntry of 'facilitatorTemplate'">
                    <div class="show-speed-catchup-facilitator-container"
                         #catchupFacilitatorSection>
                        <adapt-link-person [person]="catchup.facilitatorPerson"
                                           [imageOnly]="true"></adapt-link-person>
                        <div class="show-speed-catchup-facilitator-input"
                             [ngSwitch]="!isEditing && !catchup.facilitatorComment">
                            <div *ngSwitchCase="true">No facilitator's comments recorded</div>
                            <ng-container *ngSwitchDefault>
                                <div class="facilitator-comment-helper-text">
                                    <span>{{catchup.facilitatorPerson.firstName}}'s comment as facilitator...</span>
                                </div>
                                <adapt-html-editor class="show-speed-catchup-facilitator-content"
                                                   #catchupFacilitatorEditor
                                                   [(contents)]="catchup.facilitatorComment"
                                                   [required]="true"
                                                   placeholderText="Add response here..."
                                                   forceSize="compact"
                                                   [onlyShowToolbarWhenFocussed]="true"
                                                   [readonly]="!isEditing"
                                                   data-test="facilitator-comment"></adapt-html-editor>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div *dxTemplate="let title of 'linksTitleTemplate'"
                     class="d-flex g-2">
                    <h2>Associated actions</h2>
                </div>

                <div *dxTemplate="let title of 'linksTemplate'"
                     class="links-template">
                    <adapt-display-action-link *ngFor="let link of catchup.links"
                                               data-test="catchup-actions"
                                               [link]="link"
                                               [itemLinksDisabled]="true"
                                               [closeAllDialogsOnDelete]="false"
                                               (itemDialogClosed)="updateLinks($event)"></adapt-display-action-link>
                </div>

            </dx-accordion>

            <!-- Date & Time -->
            <div class="row-fluid">
                <div *ngIf="isEditing">
                    <label for="creationDate">Recorded on:</label>
                    <dx-date-box id="creationDate"
                                 type="datetime"
                                 [displayFormat]="DateFormats.globalize.shortdatetime"
                                 [value]="catchup.creationDate"
                                 (valueChange)="catchup.creationDate = $any($event)"></dx-date-box>
                </div>
                <adapt-entity-timestamp *ngIf="!isEditing"
                                        class="text-muted"
                                        [timestamp]="catchup.creationDate"
                                        prefix="Recorded on"></adapt-entity-timestamp>
            </div>
        </ng-container>
    </div>

    <ng-container adapt-dialog-footer>
        <button *ngIf="canEditCatchup && isEditing && hasEditableBoard"
                class="btn-item-add"
                adaptButton="add"
                [adaptBlockingClick]="addAction">action
        </button>
        <div adaptBlockingButtonGroup
             class="ms-auto"
             *adaptLoading="!catchup || isCatchupDetached">
            <button adaptButtonIcon="fal fa-fw fa-history"
                    *ngIf="!data.hideHistoryButton"
                    class="btn btn-default"
                    [disabled]="isEditing"
                    (click)="showCatchupHistory()"
                    data-test="pcu-show-history-button">Show history</button>
            <button adaptButton="delete"
                    *ngIf="canEditCatchup && !isEditing"
                    (click)="deleteCatchup()"
                    data-test="pcu-delete-button"></button>
            <button adaptButton="edit"
                    *ngIf="canEditCatchup && areBothPeopleActive && !isEditing"
                    class="btn btn-warning"
                    (click)="enterEditMode()"
                    data-test="pcu-edit-button"></button>
            <button adaptButton="saveAndClose"
                    *ngIf="isEditing"
                    [disabled]="!canSave"
                    [adaptBlockingClick]="saveAndClose"
                    data-test="pcu-save-button"></button>
            <button adaptButtonIcon="default"
                    (click)="cancel()"
                    data-test="standard-dialog-cancel-button">{{isEditing ? "Cancel" : "Close"}}</button>
        </div>
    </ng-container>
</adapt-dialog>
