<adapt-dialog [maxWidth]="1100">
    <h3 adapt-dialog-title>Capture a Peer Catch-up</h3>

    <ng-container adapt-dialog-content>
        <ol class="step-progress-bar">
            <li *ngFor="let progressStep of progressSteps"
                [ngClass]="progressStep.stepClass">
                {{progressStep.label}}
            </li>
        </ol>

        <div class="edit-catchup row gy-3">
            <div *ngIf="guidanceText"
                 [adaptCollapsible]="guidanceType"
                 class="col-12 col-lg-5">
                <div [froalaView]="guidanceText"
                     [disableViewCallbacks]="true"
                     class="p-3 pe-0"></div>
            </div>

            <div class="col-12"
                 [ngClass]="{'col-lg-7 ps-lg-4': !!guidanceText}">
                <adapt-edit-catchup-people *ngIf="!currentRatingType; else ratingTypeSteps"
                                           [catchup]="catchup"
                                           (validationChange)="nextButtonDisabled = !$event"></adapt-edit-catchup-people>
                <ng-template #ratingTypeSteps>
                    <adapt-edit-catchup-rating-type [catchup]="catchup"
                                                    [ratingType]="currentRatingType"
                                                    [isEditing]="true"
                                                    (ratingChange)="validateCurrentRatingType()"></adapt-edit-catchup-rating-type>
                </ng-template>

                <adapt-collapsible-dashboard-element *ngIf="!hideActionButton && filteredLinks.length"
                                                     [collapsed]="false"
                                                     name="Associated actions">
                    <adapt-display-action-link *ngFor="let link of catchup.links"
                                               [link]="link"
                                               [itemLinksDisabled]="true"
                                               [closeAllDialogsOnDelete]="false"
                                               (itemDialogClosed)="updateLinks($event)">
                    </adapt-display-action-link>
                </adapt-collapsible-dashboard-element>
            </div>
        </div>
    </ng-container>

    <ng-container adapt-dialog-footer>
        <button *ngIf="!hideActionButton && hasEditableBoard"
                class="btn-item-add"
                adaptButton="add"
                [adaptBlockingClick]="addAction">action
        </button>
        <div adaptBlockingButtonGroup
             class="ms-auto">
            <button adaptButton="previous"
                    [adaptBlockingClick]="activatePrevious"
                    [disabled]="previousButtonDisabled"></button>
            <button adaptButton="next"
                    *ngIf="!isLastStep"
                    [adaptBlockingClick]="activateNext"
                    [disabled]="nextButtonDisabled"></button>
            <button adaptButton="saveAndReview"
                    *ngIf="isLastStep"
                    [adaptBlockingClick]="saveAndReview"
                    [disabled]="nextButtonDisabled"></button>
            <button adaptButtonIcon="default"
                    (click)="cancel()"
                    data-test="standard-dialog-cancel-button">Cancel
            </button>
        </div>
    </ng-container>
</adapt-dialog>

<ng-template #linkTemplate></ng-template>