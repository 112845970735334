import { Injectable, Injector } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategory } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { CareerValuationCategoryValue, CareerValuationCategoryValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { UserService } from "@common/user/user.service";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartCustomizePoint, IDxGridColumnCellInfo, IDxGridColumnTemplateCellInfo } from "@common/ux/dx.types";
import { OrgCommonCareerValuationService } from "@org-common/lib/career-valuation/org-common-career-valuation.service";
import { OrgCommonCareerValuationUiService } from "@org-common/lib/career-valuation/org-common-career-valuation-ui.service";
import { AfterOrganisationInitialisation } from "@org-common/lib/organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { dxElement } from "devextreme/core/element";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { BaseChart } from "devextreme/viz/chart_components/base_chart";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { CareerValuationService } from "./career-valuation.service";
import { ICareerValuationChartTooltipOptions } from "./career-valuation-chart-tooltip-options.interface";

export interface ICareerValuationValueState {
    lower: number;
    upper: number;
    state: string;
    bgColor: string;
    fgColor: string;
    textColor: string;
    class: string;
    name: string;
}

export interface ICareerValuationChartData<T extends CareerValuation> {
    value: number;
    creationDate: Date;
    careerValuation: T;
    argument: number;

    [property: string]: any;
}

export interface ICareerValuationActualsData {
    category: CareerValuationCategory;
    averageActual: number;
    averageIdeal: number;
    averageDifferential: number;
    id: number;
    name: string;
}

@Injectable({
    providedIn: "root",
})
@Autobind
export class CareerValuationUiService extends BaseOrganisationService {

    private culturalLeadershipConfiguration: Partial<CulturalLeadership> = {};

    public valueStates: ICareerValuationValueState[];

    public constructor(
        injector: Injector,
        private userService: UserService,
        private careerValuationService: CareerValuationService,
        private orgCommonCareerValuationService: OrgCommonCareerValuationService,
        private orgCommonCareerValuationUiService: OrgCommonCareerValuationUiService,
    ) {
        super(injector);

        this.valueStates = [
            {
                lower: 75,
                upper: 100,
                state: "Very happy",
                bgColor: "rgba(2, 116, 103, 0.8)",
                fgColor: "rgba(2, 116, 103, 0.8)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-very-happy",
                name: "category1",
            },
            {
                lower: 60,
                upper: 75,
                state: "Happy",
                bgColor: "rgba(128,135,17,0.8)",
                fgColor: "rgba(128,135,17,0.8)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-happy",
                name: "category2",
            },
            {
                lower: 50,
                upper: 60,
                state: "Unhappy",
                bgColor: "rgba(200,133,12,0.8)",
                fgColor: "rgba(200,133,12,0.8)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-unhappy",
                name: "category3",
            },
            {
                lower: 0,
                upper: 50,
                state: "Very unhappy",
                bgColor: "rgba(157,41,42,0.8)",
                fgColor: "rgba(157,41,42,0.8)",
                textColor: "rgba(235, 235, 235, 1)",
                class: "catchup-result-very-unhappy",
                name: "category4",
            },
        ];
    }

    @AfterOrganisationInitialisation
    public async promiseToRecordCareerValuation(person: Person) {
        const [author, previousCareerValuation, categories] = await Promise.all([
            this.userService.getCurrentPerson(),
            this.orgCommonCareerValuationService.promiseToGetLatestCareerValuationForPerson(person.personId),
            this.careerValuationService.promiseToGetCareerValuationCategories(),
        ]);

        const careerValuation = await lastValueFrom(this.careerValuationService.createCareerValuation({
            organisationId: this.culturalLeadershipConfiguration.organisationId,
            authorId: author!.personId,
            personId: person.personId,
            creationDate: moment.utc().toDate(),
        }));

        await Promise.all(categories.map((category) => lastValueFrom(this.careerValuationService.createCareerValuationCategoryValue({
            careerValuationId: careerValuation.careerValuationId,
            careerValuationCategoryId: category.careerValuationCategoryId,
            idealValue: 0,
        }))));

        return this.orgCommonCareerValuationUiService.editCareerValuation(careerValuation, previousCareerValuation).subscribe();
    }

    public createCareerValuationChartObject<T extends CareerValuation>(careerValuation: T): ICareerValuationChartData<T> {
        const chartObject: ICareerValuationChartData<T> = {
            careerValuation,
            value: careerValuation.extensions.actualTotal,
            creationDate: careerValuation.creationDate,
            argument: careerValuation.personId,
        };

        careerValuation.categoryValues.forEach(setCategoryValues);

        return chartObject;

        function setCategoryValues(categoryValue: CareerValuationCategoryValue) {
            chartObject["cat" + categoryValue.category.ordinal] = categoryValue.actualValue;
        }
    }

    @AfterOrganisationInitialisation
    public async promiseToGetCareerValuationSingleActualsChartData(careerValuation: CareerValuation) {
        if (careerValuation && Array.isArray(careerValuation.categoryValues)) {
            return careerValuation.categoryValues
                .map(processCategory)
                .sort(SortUtilities.getSortByFieldFunction(CareerValuationCategoryValueBreezeModel.sortField!));
        } else {
            return [];
        }

        function processCategory(categoryValue: CareerValuationCategoryValue) {
            return {
                category: categoryValue.category,
                averageActual: categoryValue.actualValue,
                averageIdeal: categoryValue.idealValue,
                averageDifferential: categoryValue.idealValue - categoryValue.actualValue,
                id: categoryValue.category.ordinal,
                name: categoryValue.category.name,
            } as ICareerValuationActualsData;
        }
    }

    public getChartDataTooltip(careerValuation: CareerValuation, chartDataTooltipOptions: ICareerValuationChartTooltipOptions) {
        const categoryIsDefined = StringUtilities.isString(chartDataTooltipOptions.categoryName) && chartDataTooltipOptions.categoryName !== "Overall";
        let result = "";

        if (chartDataTooltipOptions.showName && careerValuation.person) {
            result += "<h3>" + careerValuation.person.fullName + "</h3>";
        }

        const values = careerValuation.categoryValues.filter(isRequestedCategory);
        values.sort(SortUtilities.getSortByFieldFunction(CareerValuationCategoryValueBreezeModel.sortField!))
            .forEach(displayValue);

        if (!categoryIsDefined) {
            result += "<b>Total: </b>" + careerValuation.extensions.actualTotal + "%<br />";

            if (careerValuation.notes) {
                result += '<div class="fr-view" style="max-width:200px;">' + careerValuation.notes + "</div>";
            }
        }

        if (!DateUtilities.isOutOfRange(careerValuation.creationDate)) {
            result += "<br /><b>Recorded on: </b>" + moment(careerValuation.creationDate).format(DateFormats.moment.long);
        }

        if (careerValuation.author) {
            result += "<br /><b>Recorded by: </b>" + careerValuation.author.fullName;
        }

        if (careerValuation.person && careerValuation.person.culturalLeaderRelationships.length) {
            const rels = careerValuation.person.culturalLeaderRelationships.filter((relationship: CulturalRelationship) => relationship.isActiveAt(careerValuation.creationDate));
            if (rels.length) {
                if (rels.length === 1) {
                    result += "<br /><b>" + this.culturalLeadershipConfiguration.primaryName + ": </b>";
                } else {
                    result += "<br /><b>" + this.culturalLeadershipConfiguration.primaryCollectionName + ": </b>";
                }

                rels.forEach(appendCulturalLeaderName);
            }
        }

        return result;

        function isRequestedCategory(categoryValue: CareerValuationCategoryValue) {
            return categoryValue.category.name === chartDataTooltipOptions.categoryName;
        }

        function appendCulturalLeaderName(relationship: CulturalRelationship, index: number, array: CulturalRelationship[]) {
            if (index > 0 || array.length > 1) {
                result += "<br />";
            }

            result += relationship.culturalLeader.fullName;
        }

        function displayValue(categoryValue: CareerValuationCategoryValue) {
            result += "<b>" + categoryValue.category.name + ":</b> "
                + categoryValue.extensions.score
                + "<br />";

            if (chartDataTooltipOptions.notesColumnVisible && categoryValue.notes) {
                result += '<div class="fr-view" style="max-width:200px;">' + categoryValue.notes + "</div>";
            }
        }
    }

    public customiseChartPoint(info: IDxChartCustomizePoint) {
        if (!info.tag) {
            return {};
        }

        const careerValuation: CareerValuation = info.tag;
        const actualValue = careerValuation.extensions.actualTotal;

        for (const valueState of this.valueStates) {
            if (actualValue >= valueState.lower
                && actualValue <= valueState.upper) {
                return {
                    color: valueState.fgColor,
                };
            }
        }

        return {};
    }

    public setOrbitChartStrips(chart: BaseChart<any>) {
        chart.option("valueAxis.strips", this.valueStates.map(convertStateToChartStrip));

        function convertStateToChartStrip(state: ICareerValuationValueState) {
            return {
                startValue: 100 - state.lower,  // as we are storing higher values closer to the center we must take the state values away from the maximum possible value
                endValue: 100 - state.upper,
                color: "#EAEAEA",
                label: {
                    font: {
                        size: 20,
                        color: "#565757",
                    },
                    text: state.state,
                },
            };
        }
    }

    public customiseValueCellText(cell: IDxGridColumnCellInfo) {
        return cell.valueText + "%";
    }

    // CM-5081 DX 21.1 has screwed up dxElement, remove the prefix once they fix it
    public getValueCellTemplate(element: dxElement, info: IDxGridColumnTemplateCellInfo) {
        const states = this.valueStates.filter(matchValue);

        if (!states || !states[0]) {
            return;
        }

        element.addClass(states[0].class);
        element.html(info.text);

        function matchValue(state: ICareerValuationValueState) {
            return info.value >= state.lower && info.value <= state.upper;
        }
    }

    public getValueCellTemplateForClass(info: CellPreparedEvent) {
        const states = this.valueStates.filter(matchValue);

        if (!states || !states[0]) {
            return;
        }

        return states[0].class;

        function matchValue(state: ICareerValuationValueState) {
            return info.value >= state.lower && info.value <= state.upper;
        }
    }

    public getValueHeaderOptions() {
        const result = this.valueStates.map(createHeaderItem);

        result.unshift({
            text: "(Blanks)",
            value: ["careerValuation.extensions.actualTotal", "=", null],
        });

        return result;

        function createHeaderItem(valueState: ICareerValuationValueState) {
            return {
                text: valueState.state + " (" + valueState.lower + "% - " + valueState.upper + "%)",
                value: [
                    ["careerValuation.extensions.actualTotal", ">=", valueState.lower],
                    "and",
                    ["careerValuation.extensions.actualTotal", "<", valueState.upper]] as string | any[] | null,
            };
        }
    }


    protected organisationInitialisationActions() {
        return [
            this.updateCulturalLeadershipConfiguration(),
        ];
    }

    private async updateCulturalLeadershipConfiguration() {
        this.culturalLeadershipConfiguration = await this.careerValuationService.promiseToGetCulturalLeadershipConfiguration();
    }
}
