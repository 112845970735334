import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-cvt-personal-summary",
    templateUrl: "./cvt-personal-summary.component.html",
    styleUrls: ["./cvt-personal-summary.component.scss"],
})
export class CareerValuationPersonalSummaryComponent extends BaseComponent implements OnChanges {
    @Input() public careerValuation?: CareerValuation;
    public actualTotal?: number;

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.careerValuation) {
            this.actualTotal = this.careerValuation?.extensions.actualTotal;
        }
    }
}
