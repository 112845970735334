import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { TeamsService } from "./teams.service";

@Injectable({
    providedIn: "root",
})
export class TeamsAuthService {

    public static registerAccess(authorisationService: AuthorisationService) {


        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamCatchUps,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpRead,
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.EditTeamCatchUps,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamNetworkHealth,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToViewTeamNetworkHealth(
                        injector.get(TeamsService),
                    ),
            },
        );

        function getVerifyAccessToViewTeamNetworkHealth(teamsService: TeamsService) {
            return async (_currentPerson: Person, team?: Team) => {
                if (!team) {
                    return Promise.reject();
                }

                return teamsService.promiseToVerifyAccessToTeamNetworkHealthForCurrentPerson(team);
            };
        }
    }
}
