import { AfterContentInit, AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Link } from "@common/ADAPT.Common.Model/organisation/link";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { filter } from "rxjs/operators";
import { LinkService } from "../../link/link.service";
import { ItemUtilities } from "../items/item-utilities";
import { KanbanAuthService } from "../kanban-auth.service";

@Component({
    selector: "adapt-kanban-card",
    templateUrl: "./kanban-card.component.html",
    styleUrls: ["./kanban-card.component.scss"],
})
export class KanbanCardComponent extends BaseComponent implements OnInit, AfterViewChecked, AfterContentInit {
    @Input() public item!: Item;
    @Input() public showAssignee = true;
    @Input() public draggable = true;
    @Input() public highlight = false;
    @Input() public blurSensitiveContent = false;

    // other items in column may be needed for the menu
    @Input() public columnItems?: Item[];

    @Input() public inMultipleSelectionMode = false;
    @Input() public inMultipleSelection = false;
    @Output() public inMultipleSelectionChange = new EventEmitter<boolean>();

    // cannot use click and it clashes with ng (click)
    @Output() public itemSelected = new EventEmitter<Item | undefined>();
    @Output() public dialogOpened = new EventEmitter<Item>();
    @Output() public dialogClosed = new EventEmitter<Item>();
    @Output() public contentRendered = new EventEmitter<void>();

    public get editable() {
        return this.item?.extensions.currentPersonCanEdit && this.draggable;
    }

    public denyDrag = false;
    private nonEditableDragStart = false;

    public sortedLinks?: Link[];
    public linkedObjectives: Objective[] = [];

    public constructor(
        elementRef: ElementRef,
        private kanbanAuthService: KanbanAuthService,
        private orgService: OrganisationService,
        rxjsBreezeService: RxjsBreezeService,
        public linkService: LinkService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(Item).pipe(
            filter((item) => this.item?.links.some((l) => l.itemId === item.itemId || l.linkedItemId === item.itemId)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateSortedLinks());

        rxjsBreezeService.entityTypeChanged(Link).pipe(
            filter((link) => link.itemId === this.item?.itemId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateSortedLinks());
    }

    public ngOnInit() {
        this.updateSortedLinks();
        this.kanbanAuthService.hasEditAccessToItem(this.item)
            .subscribe((canEdit) => this.item.extensions.currentPersonCanEdit = canEdit);
    }

    private updateSortedLinks() {
        this.sortedLinks = this.item.links?.filter((l) => l.extensions.isItemToItemLink).sort(ItemUtilities.getItemLinkStatusAndItemCodeSortComparator(this.item));
        this.linkedObjectives = this.item.links.map((l) => l.objective1).filter((l) => l != null);
    }

    public ngAfterViewChecked() {
        // This check for org id here is to prevent the initialization after logging out (i.e. application bar and toolbar disappear, causing
        // more items to get into the view), which will cause error toasters
        if (this.orgService.getOrganisationId() > 0) {
            this.setInitialiseWhenElementComesIntoView(() => this.contentRendered.emit());
        }
    }

    public ngAfterContentInit() {
        this.ngAfterViewChecked();
    }

    @HostListener("click", ["$event"])
    public onClick(event: MouseEvent) {
        // don't want to preview the item if they're clicking the checkbox specifically
        if (!this.inMultipleSelectionMode || !(event.target as HTMLElement).classList.contains("dx-checkbox-icon")) {
            this.itemSelected.emit(this.item);
        }
    }

    @HostListener("pointerdown")
    public pointerDown() {
        if (!this.editable) {
            this.nonEditableDragStart = true;
        }
    }

    @HostListener("pointermove")
    public pointerMove() {
        if (this.nonEditableDragStart) {
            this.denyDrag = true;
        }
    }

    @HostListener("pointerleave")
    @HostListener("pointerup")
    public pointerUp() {
        this.nonEditableDragStart = false;
        this.denyDrag = false;
    }

    public onSelectionChange(selected: boolean) {
        this.inMultipleSelection = selected;
        this.inMultipleSelectionChange.emit(this.inMultipleSelection);
    }
}
