import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Account, PaymentMethod } from "./account";
import { AccountPayment } from "./account-payment";
import { InvoiceItem, InvoiceItemType } from "./invoice-item";
import { InvoiceType } from "./invoice-type";

export enum InvoiceStatus {
    Unpaid = "Unpaid",
    Removed_Pending = "Removed_Pending", // DO NOT USE - this status has not been used for years
    Paid = "Paid",
    Archived = "Archived",
}

export class Invoice extends BaseEntity<Invoice> {
    public invoiceId!: number;
    public accountId!: number;
    public date!: Date;
    public periodStartDate!: Date;
    public periodEndDate!: Date;
    public paymentMethod!: PaymentMethod;
    public status!: InvoiceStatus;
    public type!: InvoiceType;
    public account!: Account | null;
    public accountPayments!: AccountPayment[];
    public invoiceItems!: InvoiceItem[];

    /**
     * Gets the total due for this invoice (charges - deductions)
     */
    public get total() {
        const totalDeduction = this.invoiceItems.reduce((total, item) => total + (item.type === InvoiceItemType.Deduction ? item.quantity * item.amount : 0), 0);
        return this.totalCharges - totalDeduction;
    }

    /**
     * Gets the total charge for the invoice, including tax
     */
    public get totalCharges() {
        let totalCharges = this.invoiceItems.reduce((total, item) => total + (item.type === InvoiceItemType.Charge ? item.quantity * item.amount : 0), 0);
        totalCharges = this.account?.taxExempt
            ? totalCharges
            : totalCharges * 1.1;

        return totalCharges;
    }

    public get remainingCredit() {
        return this.invoiceItems.reduce((total, item) => total + (item.type === InvoiceItemType.RemainingCredit ? item.amount : 0), 0);
    }
}

export const InvoiceBreezeModel = new BreezeModelBuilder("Invoice", Invoice)
    .hasSource()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("invoiceItems")
    .alwaysFetchingNavigationProperty("accountPayments")
    .build();
