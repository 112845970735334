import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { CareerValuation } from "./career-valuation";
import { Item } from "./item";
import { LinkExtensions } from "./link-extensions";
import { Meeting } from "./meeting";
import { MeetingAgendaItem } from "./meeting-agenda-item";
import { Objective } from "./objective";
import { Organisation } from "./organisation";
import { SpeedCatchup } from "./speed-catchup";

export class Link extends BaseEntity<Link> {
    public linkId!: number;
    public organisationId!: number;
    public itemId?: number;
    public linkedItemId?: number;
    public objective1Id?: number;
    public objective2Id?: number;
    public meetingId?: number;
    public meetingAgendaItemId?: number;
    public speedCatchupId?: number;
    public careerValuationId?: number;

    public organisation!: Organisation;
    public item?: Item;
    public linkedItem?: Item;
    public objective1?: Objective;
    public objective2?: Objective;
    public meeting?: Meeting;
    public meetingAgendaItem?: MeetingAgendaItem;
    public speedCatchup?: SpeedCatchup;
    public careerValuation?: CareerValuation;

    @EntityExtensions(LinkExtensions)
    public extensions!: LinkExtensions;
}

export const LinkBreezeModel = new BreezeModelBuilder("Link", Link)
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .addExpandSource("Item", "itemId")
    .addExpandSource("LinkedItem", "linkedItemId")
    .addExpandSource("Objective1", "objective1Id")
    .addExpandSource("Objective2", "objective2Id")
    .addExpandSource("Meeting", "meetingId")
    .addExpandSource("MeetingAgendaItem", "meetingAgendaItemId")
    .addExpandSource("SpeedCatchup", "speedCatchupId")
    .addExpandSource("CareerValuation", "careerValuationId")
    .build();
