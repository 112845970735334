import { Component, Inject, OnInit } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Link } from "@common/ADAPT.Common.Model/organisation/link";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { KanbanService } from "@org-common/lib/kanban/kanban.service";
import { LinkService } from "@org-common/lib/link/link.service";

export interface IConfirmDeleteItemData extends IConfirmationDialogData {
    item: Item;
}

@Component({
    selector: "adapt-delete-item-dialog",
    templateUrl: "./delete-item-dialog.component.html",
    styleUrls: ["./delete-item-dialog.component.scss"],
})
export class DeleteItemDialogComponent extends BaseConfirmationDialogComponent<IConfirmDeleteItemData> implements OnInit {
    public readonly dialogName = "DeleteItemConfirmation";

    public item: Item;
    public loadingRelatedInfo = true;

    public itemLinks: Link[] = [];
    public objectiveLinks: Link[] = [];
    public linkedMeeting?: Meeting;
    public catchupLink?: Link;
    public careerValuationLink?: Link;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmDeleteItemData,
        private kanbanService: KanbanService,
        public linkService: LinkService,
    ) {
        super(dialogData);

        this.item = dialogData.item;
        dialogData.title = "Delete action: " + this.item.code;
        dialogData.confirmButtonPreset = "deleteAndSave";
    }

    public async ngOnInit() {
        this.loadingRelatedInfo = true;

        if (this.item) {
            // prime the related item data
            this.kanbanService.primeRelatedItemData(this.item).pipe(
                this.takeUntilDestroyed(),
            ).subscribe(() => {
                this.item.links.forEach((link) => {
                    if (link.extensions.isItemToItemLink) {
                        this.itemLinks.push(link);
                    }
                    if (link.extensions.isObjectiveToItemLink) {
                        this.objectiveLinks.push(link);
                    }
                    // linked activities
                    if (link.extensions.isItemToMeetingLink) {
                        this.linkedMeeting = link.meeting;
                    }
                    if (link.extensions.isItemToPcuLink) {
                        this.catchupLink = link;
                    }
                    if (link.extensions.isItemToCvtLink) {
                        this.careerValuationLink = link;
                    }
                });
                this.loadingRelatedInfo = false;
            });
        }
    }
}
