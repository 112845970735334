<div class="display-team-membership-directive"
     *ngIf="(team$ | async) as team">
    <div class="team-detail-content"
         *adaptLoadingUntilFirstEmit="let teamMembers of activeTeamMembers$">
        <p *ngIf="team.roleConnections.length === 0; else teamMemberListTemplate">
            No team members added yet
        </p>
        <ng-template #teamMemberListTemplate>
            <ul class="d-flex gap-1">
                <li class="team-member-list-item team-leader-list-item"
                    *ngIf="teamLeaderPerson">
                    <adapt-link-person class="team-leader-person-link"
                                       imageSize="md"
                                       [person]="teamLeaderPerson"
                                       [imageOnly]="true"></adapt-link-person>
                </li>
                <li class="team-member-list-item"
                    *ngFor="let person of teamMembers">
                    <adapt-link-person imageSize="md"
                                       [person]="person"
                                       [imageOnly]="true"></adapt-link-person>
                </li>
            </ul>
        </ng-template>
    </div>
</div>
