import { Injectable, Injector } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Link, LinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/link";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { merge } from "rxjs";
import { filter } from "rxjs/operators";
import { BaseOrganisationService } from "../organisation/base-organisation.service";

@Injectable({
    providedIn: "root",
})
export class LinkService extends BaseOrganisationService {

    public constructor(
        private rxjsBreezeService: RxjsBreezeService,
        injector: Injector,
    ) {
        super(injector);
    }

    public filterForObjectiveItemLinks(links: Link[]) {
        return links.filter((link) => link.extensions.isObjectiveToItemLink);
    }

    public filterForObjectiveToObjectiveLinks(links: Link[]) {
        return links.filter((link) => link.extensions.isObjectiveToObjectiveLink);
    }

    public filterForItemToItemLinks(links: Link[]) {
        return links.filter((link) => link.extensions.isItemToItemLink);
    }

    public filterForFirstMeetingLinkForItem(item: Item) {
        const links = item.links.filter((link) => link.extensions.isItemToMeetingLink);
        return ArrayUtilities.getSingleFromArray(links)?.meeting;
    }

    public filterForFirstPcuLinkForItem(item: Item) {
        const links = item.links.filter((link) => link.extensions.isItemToPcuLink);
        return ArrayUtilities.getSingleFromArray(links)?.speedCatchupId;
    }

    public filterForFirstCvtLinkForItem(item: Item) {
        const links = item.links.filter((link) => link.extensions.isItemToCvtLink);
        return ArrayUtilities.getSingleFromArray(links)?.careerValuationId;
    }

    public createLinkForPeerCatchup(speedCatchup: SpeedCatchup, itemId: number) {
        const initialData: Partial<Link> = {
            speedCatchup: speedCatchup,
            itemId,
            organisationId: this.organisationId,
        };

        return this.commonDataService.create(LinkBreezeModel, initialData);
    }

    public createLinkForCareerValuation(careerValuation: CareerValuation, itemId: number) {
        const initialData: Partial<Link> = {
            careerValuation: careerValuation,
            itemId,
            organisationId: this.organisationId,
        };

        return this.commonDataService.create(LinkBreezeModel, initialData);
    }

    public createLinkForAgendaItem(agendaItem: MeetingAgendaItem, itemId: number) {
        const initialData: Partial<Link> = {
            meetingId: agendaItem.meetingId,
            meetingAgendaItemId: agendaItem.meetingAgendaItemId,
            itemId,
            organisationId: this.organisationId,
        };

        return this.commonDataService.create(LinkBreezeModel, initialData);
    }

    public createObjectiveToObjectiveLink(objective1: Objective, objective2: Objective) {
        const initialData: Partial<Link> = {
            objective1,
            objective2,
            organisationId: this.organisationId,
        };
        return this.commonDataService.create(LinkBreezeModel, initialData);
    }

    /**
     * emits when an item or link has changed based on the items passed in.
     * @param getLinkFn function that return a list. to avoid the reference is not stale
     */
    public linkedItemChanged$(getLinkFn: () => Link[]) {
        return merge(
            this.rxjsBreezeService.entityTypeChanged(Link),
            this.rxjsBreezeService.entityTypeChanged(Item).pipe(
                filter((item) => !!getLinkFn().find((l) => l.item === item || l.linkedItem === item)),
            ),
        );
    }
}
