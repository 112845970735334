import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation, TeamLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/team-location";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { RouteService } from "@common/route/route.service";
import { UserService } from "@common/user/user.service";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { OrgCommonPeerCatchupAuthService } from "@org-common/lib/peer-catchup/org-common-peer-catchup-auth.service";
import { teamCatchupsPageRoute } from "@org-common/lib/peer-catchup/team-catchups-page/team-catchups-page.route";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { from, lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class TeamsService extends BaseOrganisationService {

    private archData = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
        private routeService: RouteService,
        private userService: UserService,
        private authorisationFactory: AuthorisationService,
        private featuresService: FeaturesService,
        private commonTeamsService: CommonTeamsService,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [this.initialiseService()];
    }

    protected initialiseService() {
        return this.commonTeamsService.waitForInitialisation$;
    }

    // Create / Add

    // Get: Team Locations

    public getTeamLocations(team: Team) {
        const predicate = new MethodologyPredicate<TeamLocation>("teamId", "==", team.teamId);
        return this.commonDataService.getWithOptions(TeamLocationBreezeModel, predicate.getKey(TeamLocationBreezeModel.identifier), {
            predicate,
            navProperty: "keyFunction",
        });
    }

    public getAllTeamLocations() {
        return this.commonDataService.getAll(TeamLocationBreezeModel);
    }

    public getActiveTeamsWithoutLocations() {
        return from(this.commonTeamsService.promiseToGetAllTeams()).pipe(
            map((allTeams) => {
                const rootTeams = ArrayUtilities.distinct(allTeams.map((t) => t.rootTeam));
                return rootTeams.filter((team) => ActiveEntityUtilities.isActive(team) && team.teamLocations.length === 0);
            }),
        );
    }

    public promiseToGetTeamNetworkHealthUrl({ teamId }: Team) {
        return this.routeService.getControllerRoute(teamCatchupsPageRoute.id, { teamId });
    }


    // Verify/check

    public promiseToVerifyTeamMemberRoleHasSpeedCatchupReadPermission(team: Team) {
        return this.commonTeamsService.promiseToVerifyTeamRoleHasFeaturePermission(
            team,
            RoleTypeCode.TeamMember,
            FeaturePermissionName.PeoplePeerCatchUpRead,
        );
    }

    public async promiseToVerifyAccessToTeamNetworkHealthForCurrentPerson(team: Team) {
        if (!(await this.featuresService.promiseToCheckIfFeatureActive(FeatureName.PeoplePeerCatchUp, team))) {
            return Promise.reject();
        }

        if (await this.authorisationFactory.promiseToGetHasAccess(CommonTeamsAuthService.ViewTeamCatchUps, team)) {
            return Promise.resolve();
        }

        if (!(await this.authorisationFactory.promiseToGetHasAccess(OrgCommonPeerCatchupAuthService.OpenPeerCatchupDashboard))) {
            return Promise.reject();
        }

        const currentPerson = await this.userService.getCurrentPerson();
        const activeInTeam = await this.commonTeamsService.promiseToVerifyPersonActiveInTeam(currentPerson!, team);
        return activeInTeam
            ? Promise.resolve()
            : Promise.reject();
    }

    // Update

    public async promiseToSetTeamMemberRoleSpeedCatchupPermission(team: Team, enabled: boolean) {
        await this.promiseToSetFeaturePermissionForTeamRoleTypeCode(team, enabled, FeaturePermissionName.PeoplePeerCatchUpRead, RoleTypeCode.TeamMember);
        await this.promiseToSetFeaturePermissionForTeamRoleTypeCode(team, enabled, FeaturePermissionName.PeoplePeerCatchUpRead, RoleTypeCode.TeamParticipant);
    }

    private async promiseToSetFeaturePermissionForTeamRoleTypeCode(team: Team, enabled: boolean, featurePermission: FeaturePermissionName, roleTypeCode: RoleTypeCode) {
        // for the case where the team is deleted, then ignore any calls to this function
        if (team.entityAspect.entityState.isDetached()) {
            return Promise.reject();
        }

        const role = await this.commonTeamsService.promiseToGetTeamRoleByRoleTypeCode(team.teamId, roleTypeCode);
        if (!role) {
            this.log.warn("Something went wrong with team member roles");
            return Promise.reject();
        }

        const permission = role.extensions.getPermission(featurePermission);
        if (enabled && !permission) {
            return lastValueFrom(this.archData.createRolePermission(role, featurePermission));
        } else if (!enabled && permission) {
            return lastValueFrom(this.commonDataService.remove(permission));
        }

        return Promise.resolve();
    }

    // Remove


}
