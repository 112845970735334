import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EMPTY } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { CatchupView } from "../catchup-views";
import { OrgCommonCatchupUiService } from "../org-common-catchup-ui.service";
import { OrgCommonPeerCatchupAuthService } from "../org-common-peer-catchup-auth.service";

@Component({
    selector: "adapt-catchups-page-content",
    templateUrl: "./catchups-page-content.component.html",
})
export class CatchupsPageContentComponent extends BaseComponent implements OnChanges {
    @Input() public person?: Person;
    @Input() public team?: Team;

    public readonly CatchupView = CatchupView;
    public graphOptions: any;
    public viewSelection = CatchupView.Graph;

    public canEditPerson = false;
    public canEditMyCatchup = false;

    constructor(
        private catchupAuthService: OrgCommonPeerCatchupAuthService,
        private OrgCommonCatchupAuthService: OrgCommonPeerCatchupAuthService,
        private catchupUiService: OrgCommonCatchupUiService,
        private userService: UserService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.person && this.person) {
            this.catchupAuthService.canEditCatchupsForPerson(this.person).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((canEdit) => {
                this.canEditPerson = canEdit;
            });
        } else if (changes.team && this.team) {
            this.OrgCommonCatchupAuthService.canEditPersonalCatchups.pipe(
                this.takeUntilDestroyed(),
            ).subscribe((canEdit) => this.canEditMyCatchup = canEdit);
        }
    }

    @Autobind
    public recordPersonCatchup() {
        if (this.person) {
            return this.catchupUiService.recordNewCatchup(this.person.personId);
        } else {
            return EMPTY;
        }
    }

    @Autobind
    public recordTeamCatchup() {
        if (this.team) {
            return this.userService.currentPerson$.pipe(
                take(1),
                switchMap((person) => this.catchupUiService.recordNewCatchup(person!.personId, undefined, this.team!.teamId)),
            );
        } else {
            return EMPTY;
        }
    }
}
