import { Component, Input, OnInit } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CareerValuationUiService, ICareerValuationActualsData } from "../career-valuation-ui.service";

@Component({
    selector: "adapt-cvt-personal-differentials-chart",
    templateUrl: "./cvt-personal-differentials-chart.component.html",
    styleUrls: ["./cvt-personal-differentials-chart.component.scss"],
})
export class CareerValuationPersonalDifferentialsChartComponent extends BaseComponent implements OnInit {

    @Input() public careerValuation!: CareerValuation;

    public chartData: ICareerValuationActualsData[] = [];

    public constructor(
        private careerValuationUiService: CareerValuationUiService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.chartData = await this.careerValuationUiService.promiseToGetCareerValuationSingleActualsChartData(this.careerValuation);
    }
}
