import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Link } from "@common/ADAPT.Common.Model/organisation/link";

@Component({
    selector: "adapt-display-action-link",
    templateUrl: "./display-action-link.component.html",
    styleUrls: ["../../meetings/meeting-styles.scss", "../../kanban/items/items-styles.scss"],
})
export class DisplayActionLinkComponent {
    public readonly ItemStatusMetadata = ItemStatusMetadata;

    @Input() public link!: Link;
    @Input() public showStatus = true;
    @Input() public itemLinksDisabled = false;
    @Input() public closeAllDialogsOnDelete = true;
    @Output() public itemDialogClosed = new EventEmitter<Item>();
}
