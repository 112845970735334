import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "../../route/organisation-page-route-builder";
import { OrgCommonPeerCatchupAuthService } from "../org-common-peer-catchup-auth.service";
import { MyCatchupsPageComponent } from "./my-catchups-page.component";

export const myCatchupsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-my-catchups-page", MyCatchupsPageComponent)
    .atOrganisationUrl("/people/peer-catchup/my-peer-catchups")
    .redirectToThisRouteFromOrganisationUrl("/people/peerCatchUp/myPeerCatchUps")
    .verifyingFeatures(FeatureName.PeoplePeerCatchUp)
    .verifyingAccess(OrgCommonPeerCatchupAuthService.OpenPeerCatchupDashboard)
    .withTitle("Peer Catch-ups")
    .withSearchKeyword("Network Health")
    .withSearchKeyword("PCU")
    .build();

export const personalCatchupsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-my-catchups-page", MyCatchupsPageComponent)
    .atOrganisationUrl("/people/peer-catchup/:personId/my-peer-catchups")
    .redirectToThisRouteFromOrganisationUrl("/people/peerCatchUp/:personId/myPeerCatchUps")
    .verifyingFeatures(FeatureName.PeoplePeerCatchUp)
    .verifyingAccess(OrgCommonPeerCatchupAuthService.OpenPeerCatchupDashboard)
    .withTitle("Peer Catch-ups")
    .withSearchKeyword("Network Health")
    .withSearchKeyword("PCU")
    .build();
