<div *ngIf="catchup"
     class="d-flex gap-1 align-items-baseline">
    <span adaptTooltip="Peer Catch-up"
          class="catchup-link-badge"></span>
    <a *ngIf="!linkDisabled else plainTextLink"
       (click)="openCatchup($event)"
       class="text-break catchup-link-summary"
       data-test="catchup-link-summary"
       href="#"
       target="_blank">{{catchup.extensions.linkText}}
    </a>
    <ng-template #plainTextLink>
        <span data-test="catchup-plain-text-link-summary">{{catchup.extensions.linkText}}</span>
    </ng-template>
    <button class="btn btn-link"
            [adaptBlockingClick]="deleteLink"
            adaptTooltip="Delete link"
            data-test="catchup-link-delete"
            *ngIf="canEdit"
            adaptButtonIcon="fal fa-fw fa-trash-alt"></button>
</div>
