import { Injectable, Injector } from "@angular/core";
import { CareerValuation, CareerValuationBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategory, CareerValuationCategoryBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { CareerValuationCategoryValue, CareerValuationCategoryValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { AfterOrganisationInitialisation } from "@org-common/lib/organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { CulturalLeadershipCatchupStatus } from "app/features/culture/cultural-leadership/cultural-leadership-catchup-status.enum";
import { CulturalLeadershipFrameworkService, ICulturalLeadershipOverdueData } from "app/features/culture/cultural-leadership/cultural-leadership-framework.service";
import { ICulturalLeadershipPersonalAnalysisData } from "app/features/culture/cultural-leadership/cultural-leadership-personal-analysis-data.interface";
import { lastValueFrom } from "rxjs";
import { CareerValuationAnalysisDataService } from "./career-valuation-analysis-data.service";
import { ICareerValuationAnalysisFilter } from "./career-valuation-analysis-filter";

interface ICareerValuationCategoryStatistic {
    category: CareerValuationCategory;
    averageActual: number;
    averageIdeal: number;
    averageDifferential: number;
    id: number;
    name: string;
}

export interface ICareerValuationStatistics {
    totalPeople: number;

    missingCareerValuations: Person[];
    overdueCatchups: ICulturalLeadershipOverdueData[];

    averageCareerValuation: number;

    categoryStats: ICareerValuationCategoryStatistic[];
}

export interface ICareerValuationDifferential {
    index: number;

    [property: string]: any;
}

@Injectable({
    providedIn: "root",
})
@Autobind
export class CareerValuationService extends BaseOrganisationService {
    private categories: CareerValuationCategory[] = [];
    private configuration: Partial<CulturalLeadership> = {};

    private culturalLeadershipQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
        private careerValuationAnalysisDataService: CareerValuationAnalysisDataService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
    ) {
        super(injector);
    }

    @AfterOrganisationInitialisation
    public promiseToGetCareerValuationAnalysisData(initialFilter?: ICareerValuationAnalysisFilter, prefetchAllData?: boolean) {
        return this.careerValuationAnalysisDataService.promiseToGetCareerValuationAnalysisData(initialFilter, prefetchAllData);
    }

    @AfterOrganisationInitialisation
    public async promiseToGetCareerValuationCategories() { // async here to make the function return type correct to match the decorator changes
        return this.categories;
    }

    @AfterOrganisationInitialisation
    public async promiseToGetCulturalLeadershipConfiguration() {
        return this.configuration as CulturalLeadership;
    }

    public promiseToGetAllCulturalRelationships() {
        return this.culturalLeadershipQueryUtilities.promiseToGetAllCulturalRelationships();
    }

    public createCareerValuation(initialData?: Partial<CareerValuation>) {
        return this.commonDataService.create(CareerValuationBreezeModel, initialData);
    }

    public createCareerValuationCategoryValue(initialData?: Partial<CareerValuationCategoryValue>) {
        return this.commonDataService.create(CareerValuationCategoryValueBreezeModel, initialData);
    }

    public generateCareerValuationStatistics(data: ICulturalLeadershipPersonalAnalysisData[]) {
        const self = this;
        const missingCareerValuations: any[] = [];
        let total = 0.0;
        const overdueCatchups: ICulturalLeadershipOverdueData[] = [
            {
                status: CulturalLeadershipCatchupStatus.Overdue,
                label: "Overdue",
                value: 0,
            }, {
                status: CulturalLeadershipCatchupStatus.Due,
                label: "Due Now",
                value: 0,
            }, {
                status: CulturalLeadershipCatchupStatus.OnTime,
                label: "On Time",
                value: 0,
            },
        ];

        data.forEach(calculateData);

        const categoryStats: ICareerValuationCategoryStatistic[] = [];
        this.categories.forEach(appendStatistics);

        return {
            totalPeople: data.length - missingCareerValuations.length,
            missingCareerValuations,
            overdueCatchups,
            averageCareerValuation: total / (data.length - missingCareerValuations.length),
            categoryStats,
        } as ICareerValuationStatistics;

        function calculateData(personalData: ICulturalLeadershipPersonalAnalysisData) {
            if (!personalData.careerValuation) {
                missingCareerValuations.push(personalData.person);
                overdueCatchups[0].value++;
            } else {
                total += personalData.careerValuation.extensions.actualTotal;

                if (personalData.catchupStatus === CulturalLeadershipCatchupStatus.Overdue) {
                    overdueCatchups[0].value++;
                } else if (personalData.catchupStatus === CulturalLeadershipCatchupStatus.Due) {
                    overdueCatchups[1].value++;
                } else {
                    overdueCatchups[2].value++;
                }
            }
        }

        function appendStatistics(category: CareerValuationCategory) {
            const values = data
                .filter((singleData: ICulturalLeadershipPersonalAnalysisData) => !!singleData.careerValuation)
                .map(extractCategoryValue);
            const averageActual = ArrayUtilities.averageField(values, "actualValue");
            const averageIdeal = ArrayUtilities.averageField(values, "idealValue");

            categoryStats[category.ordinal] = {
                category,
                averageActual,
                averageIdeal,
                averageDifferential: averageIdeal - averageActual,
                id: category.ordinal,
                name: category.name,
            };

            function extractCategoryValue(personalData: ICulturalLeadershipPersonalAnalysisData) {
                const result = ArrayUtilities.getSingleFromArray(personalData.careerValuation.categoryValues.filter(matchCategory));

                if (!result) {
                    self.log.warn("Category Value not found for " + category.name + " in career valuation " + personalData.careerValuation.careerValuationId);
                }

                return result;

                function matchCategory(testCategoryValue: CareerValuationCategoryValue) {
                    return testCategoryValue.careerValuationCategoryId === category.careerValuationCategoryId;
                }
            }
        }
    }

    public getLatestCareerValuationDifferentials(data: ICulturalLeadershipPersonalAnalysisData[], filter: ICareerValuationAnalysisFilter) {
        return data
            .filter((personalData: ICulturalLeadershipPersonalAnalysisData) => !!personalData.careerValuation)
            .map(buildDifferentialRecord);

        function buildDifferentialRecord(personalCulturalData: ICulturalLeadershipPersonalAnalysisData, index: number) {
            const record: ICareerValuationDifferential = {
                index,
            };

            for (const catValue of personalCulturalData.careerValuation.categoryValues) {
                const tagField = "category" + catValue.category.ordinal;
                const valueField = "category" + catValue.category.ordinal + "value";

                if (matchFilterCriteria(catValue)) {
                    record[tagField] = catValue;
                    record[valueField] = catValue.idealValue - catValue.actualValue;
                } else {
                    // to avoid DX data field absent warnings W2002
                    record[valueField] = null;
                }
            }

            return record;

            function matchFilterCriteria(value: CareerValuationCategoryValue) {
                if (value.idealValue >= filter.minimumIdealValue!) {
                    const pct = value.idealValue > 0
                        ? value.actualValue / value.idealValue * 100
                        : 0;

                    return (pct < filter.maximumActualPercentage!);
                }

                return false;
            }
        }
    }

    protected organisationInitialisationActions() {
        return [
            this.updateConfigurationCategories(),
        ];
    }

    private async updateConfigurationCategories() {
        this.configuration = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
        this.categories = await lastValueFrom(this.commonDataService.getAll(CareerValuationCategoryBreezeModel));
        this.categories.sort((a, b) => a.ordinal - b.ordinal);
    }
}
