import { Injectable } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { UserService } from "@common/user/user.service";
import { switchMap, take } from "rxjs/operators";
import { AuthorisationService } from "../authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class OrgCommonPeerCatchupAuthService {

    public static readonly ReadAnyPeerCatchups = "readAnyPeerCatchups";
    public static readonly OpenPeerCatchupDashboard = "openPeerCatchupDashboard";
    public static readonly EditMyPeerCatchup = "editMyPeerCatchup";
    public static readonly ReadMyPeerCatchup = "readMyPeerCatchup";
    public static readonly EditAllPeerCatchups = "editAllPeerCatchups";
    public static readonly ReadAllPeerCatchups = "readAllPeerCatchups";
    public static readonly EditPeerCatchupsForPerson = "editPeerCatchupsForPerson";
    public static readonly ReadPeerCatchupsForPerson = "readPeerCatchupsForPerson";

    public constructor(
        private authService: AuthorisationService,
        private userService: UserService,
    ) {
    }

    public get canEditPersonalCatchups() {
        return this.authService.getHasAccess(OrgCommonPeerCatchupAuthService.EditMyPeerCatchup).pipe(
            take(1), // not using $ suffix if observable is not long-live
        );
    }

    public canReadCatchupsForPerson(person: Person) {
        return this.authService.getHasAccess(OrgCommonPeerCatchupAuthService.ReadPeerCatchupsForPerson, person).pipe(
            take(1),
        );
    }

    public get canEditAllCatchups() {
        return this.authService.getHasAccess(OrgCommonPeerCatchupAuthService.EditAllPeerCatchups).pipe(
            take(1),
        );
    }

    public canEditCatchup(catchup: SpeedCatchup) {
        return this.userService.currentPerson$.pipe(
            switchMap((currentPerson) => {
                if (currentPerson === catchup.person1 || currentPerson === catchup.person2 ||
                    currentPerson === catchup.facilitatorPerson) {
                    return this.canEditPersonalCatchups;
                } else {
                    return this.canEditAllCatchups;
                }
            }),
        );
    }

    public canEditCatchupsForPerson(person: Person) {
        return this.authService.getHasAccess(OrgCommonPeerCatchupAuthService.EditPeerCatchupsForPerson, person).pipe(
            take(1),
        );
    }
}
