<div class="card"
     [ngClass]="{'nested': nested}"
     [style.background]="hideBackground ? 'transparent' : ''">
    <div *ngIf="!hideHeader"
         class="card-header flex-column flex-lg-row justify-content-center justify-content-lg-start">
        <h3 data-test="dashboard-element-card-header">
            <a *ngIf="linkObject; else noUrlTemplate"
               [routerLink]="linkObject.path"
               [queryParams]="linkObject.queryParams">
                {{name}} <i class="fal fa-angle-right"></i>
            </a>

            <ng-template #noUrlTemplate>
                <span *ngIf="hasClickSubscribers; else noActionAtAllTemplate"
                      class="clickable-header"
                      (click)="onHeaderClicked()">
                    {{name}} <i class="fal fa-angle-right"></i>
                </span>
            </ng-template>

            <ng-template #noActionAtAllTemplate>
                {{name}}
            </ng-template>

            <i *ngIf="info"
               class="ms-1 fal fa-circle-info"
               [adaptTooltip]="info"></i>
        </h3>
        <ng-content select="[adapt-dashboard-title]"></ng-content>

        <div class="buttons btn-collection-transparent-pill text-end">
            <ng-content select="[adapt-dashboard-title-buttons]"></ng-content>
        </div>
    </div>

    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>
