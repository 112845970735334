<adapt-dialog [maxWidth]="1000">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <div class="career-valuation display-career-valuation">
            <!-- overall -->
            <div class="card">
                <div class="card-header">
                    <h2 class="card-title">Overall</h2>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-lg-5">
                            <span class="career-valuation-total">{{actualTotal}}%</span>
                            <div *ngIf="careerValuation.notes"
                                 [froalaView]="careerValuation.notes"></div>
                            <div *ngIf="filteredLinks.length"
                                 class="mt-3">
                                <label>Associated actions</label>
                                <adapt-display-action-link *ngFor="let link of careerValuation.links"
                                                           [link]="link"
                                                           [itemLinksDisabled]="true"
                                                           [closeAllDialogsOnDelete]="false">
                                </adapt-display-action-link>
                            </div>
                        </div>
                        <div class="d-none d-md-block d-print-block col-lg-7">
                            <dx-pie-chart #pieChart
                                          class="career-valuation-ideals-chart"
                                          [pathModified]="true"
                                          [dataSource]="idealsChartData"
                                          title="Ideal Ratings"
                                          [palette]="idealsChartPalette">
                                <dxo-legend [visible]="false">
                                </dxo-legend>
                                <dxi-series type="doughnut"
                                            argumentField="argument"
                                            valueField="value">
                                    <dxo-label [visible]="true"
                                               [customizeText]="customiseLabelText">
                                        <dxo-connector [visible]="true"
                                                       [width]="1">
                                        </dxo-connector>
                                    </dxo-label>
                                </dxi-series>
                            </dx-pie-chart>
                        </div>
                    </div>
                </div>
            </div>

            <!-- each category -->
            <div class="card career-valuation-category"
                 [class]="getCategoryHeadingClass(categoryValue)"
                 *ngFor="let categoryValue of careerValuation.categoryValues">
                <adapt-cvt-category-value [categoryValue]="categoryValue"></adapt-cvt-category-value>
            </div>


            <adapt-cultural-measurement-meta [culturalEntity]="careerValuation"
                                             [showCulturalLeaderData]="true">
            </adapt-cultural-measurement-meta>
        </div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button adaptButton="edit"
                [adaptBlockingClick]="editCareerValuation"
                *ngIf="hasEditPermission"></button>
        <button adaptButton="delete"
                (click)="deleteCareerValuation()"
                *ngIf="hasEditPermission"></button>
        <button class="btn btn-primary"
                adaptButtonIcon="fa"
                (click)="closeDialog()">OK</button>
    </div>
</adapt-dialog>