<div *adaptLoading="!isInitialised"
     class="m-3 d-flex flex-column w-100">
    <ng-container *ngIf="item; else noSelectedItem">
        <div class="d-flex align-items-center gap-2">
            <h3 class="m-0">{{item.code}}</h3>
            <adapt-display-item-status [itemStatus]="item.status"
                                       class="mx-1"></adapt-display-item-status>

            <div class="ms-auto">
                <button adaptButton="editBorderless"
                        [adaptBlockingClick]="openItem"
                        data-test="open-preview-item"></button>
                <button adaptButton="closeBorderless"
                        (click)="closeClicked.emit()"></button>
            </div>
        </div>
        <adapt-display-labels class="mt-2"
                              [labelLocations]="item.labelLocations"></adapt-display-labels>

        <div class="mt-4 d-flex flex-row align-items-center gap-2">
            <adapt-link-person [person]="item.assignee"
                               [imageOnly]="true"></adapt-link-person>
            <div class="d-flex flex-column">
                <adapt-item-link [item]="item"
                                 [hideItemCode]="true"></adapt-item-link>
                <small *ngIf="item.dueDate"
                       class="d-block">Due: {{item.dueDate | adaptDate}}</small>
            </div>
        </div>
        <div class="d-flex">
            <adapt-see-more [maxHeight]="110">
                <div class="mt-3"
                     [froalaView]="item.description"
                     data-test="preview-description"></div>
            </adapt-see-more>
        </div>

        <div class="mt-4 d-flex flex-column"
             *ngIf="item.links?.length">
            <h4 class="mb-0">Links</h4>
            <div class="mt-1 d-flex flex-column gap-1">
                <ng-container *ngIf="linkedObjectives.length">
                    <small>Objectives</small>
                    <adapt-display-linked-objectives [objectives]="linkedObjectives"
                                                     [showGroupObjectiveTextSuffix]="false"></adapt-display-linked-objectives>
                </ng-container>
                <small *ngIf="itemToItemLinks.length">Actions</small>
                <adapt-item-link *ngFor="let link of itemToItemLinks"
                                 [item]="link.linkedItem"
                                 [showPersonImageLink]="true"
                                 [showDialogLink]="true"
                                 [showStatus]="true"
                                 [showSummary]="true"></adapt-item-link>

                <div *ngIf="linkedMeeting || catchupLink || careerValuationLink"
                     class="d-flex flex-column gap-1">
                    <small>
                        Associated activities
                    </small>
                    <adapt-link-team-meeting *ngIf="linkedMeeting"
                                             [meeting]="linkedMeeting"></adapt-link-team-meeting>
                    <adapt-catchup-link *ngIf="catchupLink"
                                        [catchupLink]="catchupLink"></adapt-catchup-link>
                    <adapt-cvt-link *ngIf="careerValuationLink"
                                    [careerValuationLink]="careerValuationLink"></adapt-cvt-link>
                </div>
            </div>
        </div>

        <div class="mt-4 d-flex flex-column flex-grow-1">
            <h4>Comments</h4>
            <adapt-comment-chain class="d-flex flex-column flex-grow-1 mb-n3 add-comment-smaller-width"
                                 [comments]="itemComments"
                                 [allowNewCommentSave]="!!newComment$"
                                 [createComment$]="newComment$"
                                 [createNewCommentAfterSave]="false"
                                 [focusAddCommentOnInit]="true"
                                 (saved)="onNewCommentSaved()"
                                 (newCommentDiscarded)="newComment$ = undefined"></adapt-comment-chain>
        </div>

        <ng-container *ngIf="hasEditAccessToItem$ | async">
            <div class="panel-bottom align-items-end pb-3"
                 *ngIf="!newComment$">
                <adapt-add-comment-place-holder [item]="item"
                                                (newCommentAdded)="onNewCommentAdded($event)"
                                                data-test="add-comment-place-holder"></adapt-add-comment-place-holder>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #noSelectedItem>
        <span>No action selected</span>
    </ng-template>
</div>
