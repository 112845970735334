import { Injectable } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { lastValueFrom } from "rxjs";
import { KanbanService } from "../kanban/kanban.service";
import { KanbanAuthService } from "../kanban/kanban-auth.service";
import { MeetingsService } from "../meetings/meetings.service";
import { CommonTeamsService } from "./common-teams.service";

@Injectable({
    providedIn: "root",
})
export class CommonTeamsAuthService {
    public static readonly ConfigureTeam = "configureTeam";
    public static readonly EditTeamKanban = "editTeamKanban";
    public static readonly ViewTeamKanban = "viewTeamKanban";
    public static readonly ViewTeamMeetings = "viewTeamMeetings";
    public static readonly ViewAnyTeamMeeting = "viewAnyTeamMeeting";
    public static readonly ViewTeamAccessPermissions = "viewTeamAccessPermissions";
    public static readonly AccessPrivateTeam = "accessPrivateTeam";

    public static readonly EditTeamMeetings = "editTeamMeetings";
    public static readonly EditTeamMeetingsAsParticipant = "EditTeamMeetingsAsParticipant";
    public static readonly ReadTeamMeetings = "readTeamMeetings";

    public static readonly ViewTeamCatchUps = "viewTeamCatchUps";
    public static readonly EditTeamCatchUps = "editTeamCatchUps";
    public static readonly ViewTeamNetworkHealth = "viewTeamNetworkHealth";

    public constructor(private authService: AuthorisationService) { }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.EditTeamMeetings,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipWorkMeetingsEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ReadTeamMeetings,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipWorkMeetingsEdit,
                    FeaturePermissionName.StewardshipWorkMeetingsRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.EditTeamMeetingsAsParticipant,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipWorkMeetingsEdit,
                    FeaturePermissionName.StewardshipWorkMeetingsParticipantEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ConfigureTeam,
            {
                requirePermissions: [
                    FeaturePermissionName.OrganisationTeamConfigure,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.AccessPrivateTeam,
            {
                requirePermissions: [
                    FeaturePermissionName.OrganisationTeamRead,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.EditTeamKanban,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToEditTeamKanban(
                        injector.get(KanbanService),
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamKanban,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToViewTeamKanban(
                        injector.get(KanbanService),
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamMeetings,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToViewTeamMeeting(
                        injector.get(FeaturesService),
                        injector.get(MeetingsService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewAnyTeamMeeting,
            {
                invokeToGetAccessVerifier: (injector) =>
                    getVerifyAccessToViewAnyTeamMeeting(
                        injector.get(CommonTeamsService),
                        injector.get(MeetingsService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            CommonTeamsAuthService.ViewTeamAccessPermissions,
            {
                invokeToGetEntityAccessVerifier: () =>
                    () => AdaptClientConfiguration.IsDebug ? Promise.resolve() : Promise.reject(),
            },
        );

        function getVerifyAccessToViewTeamKanban(kanbanService: KanbanService, kanbanAuthService: KanbanAuthService) {
            return async (currentPerson: Person, team?: Team) => {
                if (!team) {
                    return kanbanAuthService.personCanReadBoards(currentPerson)
                        ? Promise.resolve()
                        : Promise.reject();
                } else {
                    // prime boards first before checking (getVerifyAccessToEditTeamKanban already did that)
                    // - otherwise refresh on a team work page will result false negative
                    if (!kanbanAuthService.personCanReadBoards(currentPerson)) {
                        return Promise.reject();
                    }

                    await lastValueFrom(kanbanService.getBoardsByTeam(team.teamId));
                    return kanbanAuthService.personCanReadBoardsForTeam(currentPerson, team)
                        ? Promise.resolve()
                        : Promise.reject();
                }
            };
        }

        function getVerifyAccessToViewTeamMeeting(featuresService: FeaturesService, meetingsService: MeetingsService) {
            return async (currentPerson: Person, team?: Team) => {
                if (!team || !featuresService.isFeatureActive(FeatureName.StewardshipWorkMeetings, team)) {
                    // not allow if no team (no public read here and global org permission is covered by the KanbanAuthService.personCanRead...)
                    return Promise.reject();
                } else {
                    const hasAccess = await authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.ReadTeamMeetings, team);
                    if (hasAccess) {
                        return Promise.resolve();
                    } else if (authorisationService.personHasPermission(currentPerson, FeaturePermissionName.StewardshipWorkMeetingsParticipantEdit, team)) {
                        const isParticipant = await lastValueFrom(meetingsService.currentPersonInAnyTeamMeetings(team?.teamId));
                        return isParticipant
                            ? Promise.resolve()
                            : Promise.reject();
                    } else {
                        return Promise.reject();
                    }
                }
            };
        }

        function getVerifyAccessToEditTeamKanban(kanbanService: KanbanService, kanbanAuthService: KanbanAuthService) {
            return async (currentPerson: Person, team?: Team) => {
                if (!team || !kanbanAuthService.personCanReadBoards(currentPerson)) {
                    return Promise.reject();
                }

                const boards = await lastValueFrom(kanbanService.getBoardsByTeam(team.teamId));
                return boards.some((b) => kanbanAuthService.personCanEditBoard(currentPerson, b))
                    ? Promise.resolve()
                    : Promise.reject();
            };
        }

        // this permission is only used when getting first live meeting and from personal dashboard to determine if meeting element should be loaded
        function getVerifyAccessToViewAnyTeamMeeting(teamsService: CommonTeamsService, meetingsService: MeetingsService) {
            return async () => {
                // allow this if person has org level meeting permission
                const personHasGlobalMeetingsRead = await authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.ReadTeamMeetings);
                // Don't have to check feature as that's already done in verify access above
                if (personHasGlobalMeetingsRead) {
                    return Promise.resolve();
                }

                const teams = await teamsService.promiseToGetActiveTeamsForCurrentPerson() as Team[];
                if (teams?.length) {
                    for (const team of teams) {
                        const canViewTeamMeetings = await authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.ViewTeamMeetings, team);
                        if (canViewTeamMeetings) {
                            return Promise.resolve();
                        }
                    }
                }

                if (authorisationService.currentPersonHasPermission(FeaturePermissionName.StewardshipWorkMeetingsParticipantEdit)) {
                    // if participant edit, just check if there is any readable meeting
                    const inAnyTeamMeetings = await lastValueFrom(meetingsService.currentPersonInAnyTeamMeetings());
                    if (inAnyTeamMeetings) {
                        return Promise.resolve();
                    }
                }

                return Promise.reject();
            };
        }
    }

    public currentPersonCanEditTeam(team: Team) {
        return this.authService.currentPersonHasPermission(FeaturePermissionName.OrganisationTeamConfigure, team) &&
            (!team.isPrivate || this.authService.currentPersonHasPermission(FeaturePermissionName.OrganisationTeamRead, team));
    }
}
