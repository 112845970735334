import moment, { MomentInput } from "moment";
import { IGroupedData } from "./grouped-data.interface";
import { ObjectUtilities } from "./object-utilities";

export class ArrayUtilities {
    /**
     * Merging array of arrays into a single array consisting of all elements of the arrays.
     * This is particularly used to merge result from $q.all(promises) where each promise
     * return an array.
     * As the function name 'mergeArrays' suggests, the arrays will be merged to get rid of any
     * duplicates.
     * Duplicate elements from the arrays will NOT be added to the resultant array, e.g.
     *  [ [ 'a', 'a', 'b', 'c', 'd' ], [ 'd', 'e', 'f'] ]
     * will return result:
     *  [ 'a', 'b', 'c', 'd', 'e', 'f' ]
     * Notice that the second 'a' from the first array, and 'd' from the second array
     * are discarded.
     *
     * @param {Array} arrayOfArrays E.g. [ ['a', 'b', 'c'], ['d', 'e', 'f'] ]
     * @returns {Array} Result of the above would be [ 'a', 'b', 'c', 'd', 'e', 'f' ]
     */
    public static mergeArrays<T>(arrayOfArrays: T[][]) {
        const result: T[] = [];

        if (Array.isArray(arrayOfArrays)) {
            arrayOfArrays.forEach(appendResult);
        }

        return result;

        function appendResult(array: T[]) {
            // can't just concat as we want to filter out duplicates
            if (Array.isArray(array)) {
                array.forEach(addToResultIfNotExists);
            }

            function addToResultIfNotExists(arrayElement: T) {
                if (result.indexOf(arrayElement) < 0) {
                    result.push(arrayElement);
                }
            }
        }
    }

    /**
     * Groups an array of objects by the specified property.
     * @param dataItems The Items to group
     * @param propertyLookup How to get the property to group by from the item.
     * @param preProcessPropertyValue Use this if some sort of transform on the values is desired (e.g. group timestamps
     * by date)
     * @returns An array of groupings. For DX convenience this is in the same format as DX grouped data sources
     * (i.e. {key: "", items: []})
     */
    public static groupArrayBy<K, V>(dataItems: V[], propertyLookup: (val: V) => K, preProcessPropertyValue?: (k: K) => K): IGroupedData<K, V>[] {
        const groupedData: IGroupedData<K, V>[] = [];

        for (const item of dataItems) {
            const propertyValue = propertyLookup(item);
            const group = getGroupWithValue(propertyValue);
            group.items.push(item);
        }

        return groupedData;

        function getGroupWithValue(value: K) {
            if (preProcessPropertyValue) {
                value = preProcessPropertyValue(value);
            }

            let dataGroup = groupedData.find((d) => {
                if (value instanceof Date) {
                    return moment(value).isSame(d.key as MomentInput);
                } else {
                    return d.key === value;
                }
            });

            if (!dataGroup) {
                dataGroup = { key: value, items: [] };
                groupedData.push(dataGroup);
            }

            return dataGroup;
        }
    }

    /**
     * Removes an element from an array. Only does a shallow comparison. Keep in functions-provider
     * as this function is actually used in other providers.
     * @param element The element to remove
     * @param array The array to remove from
     * @returns The array with the element removed
     */
    public static removeElementFromArray<T>(element: T, array: T[]) {
        const index = array.indexOf(element);

        if (index >= 0) {
            array.splice(index, 1);
        }

        return array;
    }

    /**
     * Takes an array returns only the distinct values. Is not guaranteed to preserve order.
     * @param array The array to remove duplicates from
     */
    public static distinct<T>(array: T[]): T[] {
        const set = new Set(array);
        return Array.from(set);
    }

    public static addElementIfNotAlreadyExists<T>(array: T[], ...elements: T[]) {
        for (const element of elements) {
            const index = array.indexOf(element);

            if (index < 0) {
                array.push(element);
            }
        }

        return array;
    }

    /**
     * Assuming the passed array is already sorted chronologically, insert the given element in the correct place
     * @param element The element to insert
     * @param array The array to insert into
     */
    public static insertIntoReverseChronologicalArray<T>(element: T, array: T[], getDate: (t: T) => Date) {
        const activityDateMoment = moment(getDate(element));
        let activityIndexToInsert = 0;

        for (let i = 0; i < array.length; i++) {
            if (activityDateMoment.isAfter(getDate(array[i]))) {
                break;
            }

            activityIndexToInsert = i + 1;
        }

        array.splice(activityIndexToInsert, 0, element);
        return array;
    }

    /**
     * If given an array with a single element, unwraps the array and return that element's data.
     *
     * @param {Array|Object} array - An array that should contain no more than one element or any object parameter.
     * @returns {*|Object} The first element of the provided array or the original object that was passed in.
     */
    public static getSingleFromArray<T>(array: T[]) {
        if (!Array.isArray(array)) {
            return array;
        }

        switch (array.length) {
            case 0:
                return undefined;
            case 1:
                return array[0];
            default:
                throw new Error("Expected single array");
        }
    }

    /**
     * Calculate the average for an array of numbers.
     *
     * @param {Array} array - The array to be averaged.
     * @param {string} fieldPath - The path to the field.
     * @returns {number} The average value.
     */
    public static averageField<T>(array: T[], fieldPath: string) {
        return ArrayUtilities.sumField(array, fieldPath) / array.length;
    }

    /**
     * Calculate the sum of values for a field in an array of objects.
     *
     * @param {Array} array - The array of objects.
     * @param {string} fieldPath - The path to the field.
     * @returns {number} The sum of values.
     */
    public static sumField<T>(array: T[], fieldPath: string) {
        return array.reduce(addItems, 0); // set initial value to 0 so that prev is a value not an object

        function addItems(prev: number, curr: T) {
            return prev + parseFloat(ObjectUtilities.getObjectByPath<string>(curr, fieldPath) as string);
        }
    }

    /**
     * Gets the intersection of two arrays.
     * @param {Array} a The first array
     * @param {Array} b The second array
     * @returns {Array} An array that contains the intersection of the two arrays.
     */
    public static intersectArrays<T>(a: T[], b: T[]) {
        if (!Array.isArray(a) || !Array.isArray(b)) {
            throw new Error("intersectArrays: Invalid Parameters");
        }

        const result: T[] = [];

        a.forEach(isUniqueAndInB);

        return result;

        function isUniqueAndInB(val: T) {
            if (result.indexOf(val) === -1
                && b.indexOf(val) > -1) {
                result.push(val);
            }
        }
    }
    /**
     * Replaces the first occurrence of the first element with the second in the array. If
     * the old element doesn't exist in the array the new element will be added to the end
     * of the array.
     * @param {Array<any>} array The array to modify in place
     * @param {any} oldElement The element to remove from the array
     * @param {any} newElement The element to add to the array
     * @returns {Array<any>} The modified array
     */
    public static updateArrayElement<T>(array: T[], oldElement: T, newElement: T) {
        if (!Array.isArray(array)) {
            throw new Error("Not a valid array");
        }

        let oldElementIndex = -1;

        if (typeof oldElement !== "undefined") {
            oldElementIndex = array.indexOf(oldElement);

            if (oldElementIndex >= 0) {
                array.splice(oldElementIndex, 1);
            }
        }

        if (typeof newElement !== "undefined") {
            if (oldElementIndex >= 0) {
                array.splice(oldElementIndex, 0, newElement);
            } else {
                array.push(newElement);
            }
        }

        return array;
    }

    /**
     * Removes and returns the element at the given index from the array. Returns undefined if
     * index is out of range.
     * @param {Array<any>} array The array to modify in place
     * @param {number} index The index to remove from the array
     * @returns {any} The removed element
     */
    public static removeArrayIndex<T>(array: T[], index: number) {
        if (index < 0 || index > array.length - 1) {
            return undefined;
        }

        return ArrayUtilities.getSingleFromArray(array.splice(index, 1));
    }

    public static getMapArrayValuesFunctionForProperty<T>(property: string) {
        return mapArrayValuesFunction;

        function mapArrayValuesFunction(array: T[]) {
            return ArrayUtilities.mapArrayValues(array, property);
        }
    }

    public static mapArrayValues<T>(array: T[], property: string) {
        return array.map(getProperty);

        function getProperty(arrayValue: T) {
            return ObjectUtilities.getObjectByPath(arrayValue, property);
        }
    }

    /** Partitions the given array according to the provided predicate, returning the results
     * in an array where the first element is the matching elements and the second elements are the
     * non matching elements.
     */
    public static partition<T>(array: T[], predicate: (t: T) => boolean): [T[], T[]] {
        const matchContainer: T[] = [];
        const notMatchContainer: T[] = [];

        for (const t of array) {
            const container = predicate(t)
                ? matchContainer
                : notMatchContainer;
            container.push(t);
        }

        return [matchContainer, notMatchContainer];
    }

    public static splitArrayIntoChunksOfSize<T>(array: T[], size: number) {
        const chunks: T[][] = [];
        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }

        return chunks;
    }

    public static copyArrayAndRemoveLastElement<T>(array: T[]) {
        return array.slice(0, -1);
    }

    /**
     * Checks if both arrays are: Valid, the same length and the contents are the same in both
     * does not care about items order
     * @param array first comparator
     * @param other second comparator
     * @returns if contents matches (does not care about order)
     */
    public static contentsAreEqual<T>(array?: T[], other?: T[]): boolean {
        return ((!!array && !!other) && (array.length === other.length))
            ? array!.every((a) => other.includes(a))
            : array === other;
    }

    /**
     * Filter the given array using an async function.
     * @param array array to filter
     * @param predicate async function to filter array using
     */
    public static async asyncFilter<T>(array: T[], predicate: (t: T) => Promise<boolean>) {
        const resolvedPredicates = await Promise.all(array.map(predicate));
        return array.filter((_, idx) => resolvedPredicates[idx]);
    }

    /**
     * Returns a new array without any null or undefined values
     */
    public static withoutNullOrUndefined<T>(array: (T | undefined | null)[]): T[] {
        return array.filter((i) => i !== undefined && i !== null) as T[];
    }
}
