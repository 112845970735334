import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import { aboutPageRoute } from "@common/lib/pages/about-page/about-page.route";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { UserService } from "@common/user/user.service";
import { map, of, switchMap, tap } from "rxjs";
import { SubscriptionPageRoute } from "../configuration/subscription-page/subscription-page.component";
import { myOrganisationsPageRoute } from "../my-organisations/my-organisations-page/my-organisations-page.route";
import { AccountService } from "./account/account.service";

const AllowedPagesWithZeroDayTrial = [
    SubscriptionPageRoute.id,
    myOrganisationsPageRoute.id,
    aboutPageRoute.id,
];

export function validateSubscription(injector: Injector, canActivate: boolean, targetRouteId?: string) {
    if (canActivate) {
        // canActivate -> organisation guard successful. Person logged in.
        // stakeholder manager or coach won't force redirection to subscription page
        const userService = injector.get(UserService);
        if (userService.currentPerson?.isCoach() || userService.currentPerson?.isStakeholderManager) {
            return of(canActivate);
        }

        if (!AllowedPagesWithZeroDayTrial.includes(targetRouteId ?? "")) {
            const accountService = injector.get(AccountService);
            return accountService.getAccount(false).pipe(
                switchMap((account) => {
                    if (account?.extensions.isZeroDayTrial) {
                        const router = injector.get(Router);
                        return SubscriptionPageRoute.getRoute().pipe(
                            tap((route) => {
                                // need to change selected node too in the nav hierarchy (this will refresh breadcrumbs as well)
                                const navHierarchyService = injector.get(NavigationHierarchyService);
                                const node = navHierarchyService.getNodeByUrl(route);
                                if (node) {
                                    navHierarchyService.processActiveNode(node);
                                }
                            }),
                            // it will redirect if returning URLTree
                            map((route) => router.createUrlTree([route], {
                                queryParams: {
                                    showSubscriptionDialog: true,
                                },
                            })),
                        );
                    } else {
                        return of(canActivate);
                    }
                }),
            );
        }
    }

    return of(canActivate);
}
