<adapt-dialog [maxWidth]="1000">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <div id="top"
             class="career-valuation">
            <div *ngIf="showingIdealRating">
                <div class="career-valuation-ideal-help">
                    <h2>Ideal scores</h2>
                    <p>Set the ideal value you place on each of the categories, to a total of 100.</p>
                    <div class="row">
                        <div [class]="{ 'career-valuation-ideal-total-invalid': totalIdeal !== 100 }"
                             class="career-valuation-ideal-total col-8 col-lg-9">
                            <strong class="pe-2">Points allocated:</strong> <span>{{ totalIdeal }}</span> / 100
                            <span [hidden]="totalIdeal !== 100"
                                  class="fal fa-check"></span>
                        </div>
                        <div *ngIf="allowCopyPrevious"
                             class="career-valuation-copy-previous col-4 col-lg-3"
                             adaptTooltip="Copy ideal values from previous career valuation">
                            <button class="btn btn-primary"
                                    (click)="copyPreviousValuationIdealValues()"
                                    data-test="copy-previous-button">Copy previous
                            </button>
                        </div>
                    </div>
                </div>
                <div class="career-valuation-category-container">
                    <div class="card career-valuation-ideal-category"
                         [class]="'category-heading-' + categoryValue.category.ordinal"
                         *ngFor="let categoryValue of careerValuation.categoryValues"
                         data-test="career-valuation-category">
                        <div class="card-body">
                            <div class="category-title career-valuation-category-toggle"
                                 (click)="toggleAccordion(categoryValue)"
                                 adaptTooltip="Show/hide guidance questions"
                                 tooltipPlacement="top-left">
                                <h2>
                                    {{ categoryValue.category.name }}
                                    <i class="ms-1 fa"
                                       [ngClass]="showQuestionArray[categoryValue.careerValuationCategoryId] ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                                </h2>
                            </div>
                            <div class="career-valuation-category-questions"
                                 [hidden]="!showQuestionArray[categoryValue.careerValuationCategoryId]">
                                <div [froalaView]="categoryValue.category.idealQuestions"></div>
                            </div>
                            <div class="ideal-slider">
                                <dx-slider class="slider"
                                           [min]="0"
                                           [max]="50"
                                           [(value)]="categoryValue.idealValue"
                                           (onValueChanged)="handleIdealCategoryValueChange()"
                                           data-test="career-valuation-slider">
                                </dx-slider>

                                <div class="career-valuation-ideal-category-value">{{categoryValue.idealValue}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngTemplateOutlet="linkTemplate"></div>
            </div>
            <div *ngIf="!showingIdealRating">
                <div class="career-valuation-actual-help">
                    <h2>Actual scores</h2>
                    <p>Now, rate each category on how well you think your current role measures up to your ideal
                        situation.</p>
                </div>
                <div class="career-valuation">
                    <!-- each category -->
                    <div class="card career-valuation-actual-category"
                         [class]="'category-heading-' + categoryValue.category.ordinal"
                         *ngFor="let categoryValue of careerValuation.categoryValues"
                         data-test="career-valuation-category">
                        <div class="card-body">
                            <div class="category-title career-valuation-category-toggle"
                                 (click)="toggleAccordion(categoryValue)"
                                 adaptTooltip="Show/hide guidance questions"
                                 tooltipPlacement="top-left">
                                <h2>
                                    {{ categoryValue.category.name }}
                                    <i class="ms-1 fa"
                                       [ngClass]="showQuestionArray[categoryValue.careerValuationCategoryId] ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                                </h2>
                            </div>
                            <div class="row">
                                <div class="col-12 career-valuation-category-questions"
                                     [hidden]="!showQuestionArray[categoryValue.careerValuationCategoryId]">
                                    <div [froalaView]="categoryValue.category.actualQuestions"></div>
                                </div>
                                <div class="col-8 col-lg-4 career-valuation-actual-category-value">
                                    <div class="career-valuation-value-slider-section"
                                         *ngIf="categoryValue.idealValue">
                                        <dx-slider [min]="0"
                                                   [max]="categoryValue.idealValue"
                                                   [(value)]="categoryValue.actualValue"
                                                   (onValueChanged)="handleEntityChanges()"
                                                   data-test="career-valuation-slider">
                                            <dxo-label [visible]="true"
                                                       position="bottom">
                                            </dxo-label>
                                        </dx-slider>
                                    </div>
                                </div>
                                <div class="col-4 col-lg-2 career-valuation-category-summary">
                                    <p>{{ categoryValue.actualValue + " / " + categoryValue.idealValue }}</p>
                                </div>
                                <div class="col-12 col-lg-6 career-valuation-notes">
                                    <h4>{{categoryValue.category.name}} notes</h4>
                                    <adapt-html-editor [contents]="categoryValue.notes"
                                                       (contentsChange)="onContentsChange(categoryValue, $event)"
                                                       [required]="true"
                                                       (isValidChange)="categoryValue.extensions.isEditorValid = $event; handleEntityChanges()"
                                                       [onlyShowToolbarWhenFocussed]="true"
                                                       forceSize="compact"></adapt-html-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- overall -->
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-title">Overall</h2>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-4 col-lg-3">
                                    <span class="career-valuation-total">{{careerValuation.extensions.actualTotal}}%</span>
                                </div>
                                <div class="col-12 col-md-8 col-lg-9 career-valuation-notes">
                                    <h4>Overall notes</h4>
                                    <adapt-html-editor [contents]="careerValuation.notes"
                                                       (contentsChange)="onContentsChange(careerValuation, $event)"
                                                       [required]="true"
                                                       [onlyShowToolbarWhenFocussed]="true"
                                                       (isValidChange)="careerValuation.extensions.isEditorValid = $event; handleEntityChanges()"
                                                       forceSize="compact"
                                                       data-test="overall-notes-editor"></adapt-html-editor>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngTemplateOutlet="linkTemplate"></div>
                <!-- Date & Time -->
                <div class="form-group form-inline mt-3">
                    <label for="creationDate">Recorded on:</label>
                    <dx-date-box id="creationDate"
                                 type="date"
                                 [value]="careerValuation.creationDate"
                                 (valueChange)="setDate($event)"
                                 [displayFormat]="dateFormat">
                    </dx-date-box>
                </div>
            </div>
        </div>
    </div>
    <ng-container adapt-dialog-footer>
        <button *ngIf="hasEditableBoard"
                class="btn-item-add"
                adaptButton="add"
                [adaptBlockingClick]="addAction">action
        </button>
        <div adaptBlockingButtonGroup
             class="ms-auto">
            <button *ngIf="showingIdealRating"
                    [disabled]="actualButtonDisabled"
                    adaptButtonIcon="fal fa-arrow-circle-right"
                    class="btn btn-primary"
                    (click)="setPageChange()"> Set actual scores
            </button>
            <button *ngIf="!showingIdealRating"
                    adaptButtonIcon="fal fa-arrow-circle-left"
                    (click)="setPageChange()"> Set ideal scores
            </button>
            <button *ngIf="!showingIdealRating"
                    [disabled]="saveButtonDisabled"
                    adaptButton="saveAndClose"
                    [adaptBlockingClick]="saveAndClose"></button>
            <button adaptButton="cancel"
                    (click)="cancel()"></button>
        </div>

    </ng-container>
</adapt-dialog>

<ng-template #linkTemplate>
    <adapt-collapsible-dashboard-element *ngIf="careerValuation.links.length"
                                         [collapsed]="false"
                                         name="Associated actions">
        <adapt-display-action-link *ngFor="let link of careerValuation.links"
                                   [link]="link"
                                   [itemLinksDisabled]="true"
                                   [closeAllDialogsOnDelete]="false">
        </adapt-display-action-link>
    </adapt-collapsible-dashboard-element>
</ng-template>