import { AfterViewInit, Component, ElementRef } from "@angular/core";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { BullseyeQuadrant, BullseyeQuadrantStatement } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, Subject } from "rxjs";
import { IInputQuadrantStatementsData } from "../input-quadrant-statements/input-quadrant-statements.component";

@WorkflowStepComponent("adapt-set-bullseye-step")
@Component({
    selector: "adapt-set-bullseye-step",
    templateUrl: "./set-bullseye-step.component.html",
    styleUrls: ["./set-bullseye-step.component.scss"],
})
export class SetBullseyeStepComponent extends WorkflowStepComponentAdapter implements AfterViewInit {
    public stepData?: IInputQuadrantStatementsData;
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public quadrants: BullseyeQuadrant[] | undefined;
    public bullseyeWidth?: number;
    public updateDimensionsTrigger = new Subject<void>();
    public isFullscreen = false;
    public fitHeight = false;

    private fitHeightUpdater = this.createThrottledUpdater<boolean>((fitHeight) => this.fitHeight = fitHeight);
    private bullseye?: Bullseye;

    public constructor(
        elementRef: ElementRef,
        private bullseyeService: BullseyeService,
    ) {
        super(elementRef);
    }

    public onDialogFullscreenToggled(fullscreen: boolean) {
        this.isFullscreen = fullscreen;
    }

    public ngAfterViewInit() {
        this.fitHeightUpdater.next(!this.quadrants || this.quadrants.length === 4);
        this.updateDimensionsTrigger.next();
    }

    public workflowStepOnInit() {
        this.stepData = this.workflowStep?.customData;
        this.quadrants = this.stepData?.requiredQuadrants;

        this.bullseyeService.getBullseye().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((bullseye) => {
            this.bullseye = bullseye;
            this.checkStepCompletion();
        });
    }

    public workflowStepNext() {
        return this.bullseyeService.saveEntities(
            this.bullseye!.quadrantStatements
                .filter((statement) => !this.quadrants || this.quadrants.includes(statement.quadrant)),
        );
    }

    public onBullseyeStatementChanged(statement: BullseyeQuadrantStatement) {
        this.emitEntityChange(statement);
        this.checkStepCompletion();
    }

    private checkStepCompletion() {
        if (this.bullseye) {
            this.workflowStepCompleted.next(this.bullseye.extensions.areQuadrantsCompleted(this.stepData?.requiredQuadrants));
        } else {
            this.workflowStepCompleted.next(false);
        }
    }
}
