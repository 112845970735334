import { Injectable } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { MeetingAttendee } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { RouteService } from "@common/route/route.service";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { KanbanService } from "@org-common/lib/kanban/kanban.service";
import { teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { teamMeetingsPageRoute } from "@org-common/lib/meetings/team-meetings-page/team-meetings-page.route";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { teamObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { OrganisationAuthService } from "@org-common/lib/organisation/organisation-auth.service";
import { teamCatchupsPageRoute } from "@org-common/lib/peer-catchup/team-catchups-page/team-catchups-page.route";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { analyseTeamAssessmentSurveyPageRoute } from "@org-common/lib/survey/analyse-survey-page/analyse-survey-page.route";
import { manageTeamAssessmentsPageRoute } from "@org-common/lib/survey/team-assessment/manage-team-assessments-page/manage-team-assessments-page.route";
import { TeamAssessmentAuthService } from "@org-common/lib/survey/team-assessment/team-assessment-auth.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { TeamPrivateIconComponent } from "@org-common/lib/teams/team-dashboard-shared/team-private-icon/team-private-icon.component";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { navigateTeamsPageRoute } from "app/features/people/teams/navigate-teams-page/navigate-teams-page.route";
import { teamDashboardPageRoute } from "app/platform/team-dashboard/team-dashboard-page/team-dashboard-page.route";
import { filter, lastValueFrom, merge, Subject, switchMap } from "rxjs";
import { debounceTime, map, tap } from "rxjs/operators";

@Injectable()
export class TeamHierarchyNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Teams.Navigation.Hierarchy";
    public static readonly IconClass = "fal fa-users";

    public id = TeamHierarchyNavigationService.HierarchyId;

    private teamsRootNode?: INavigationNode;
    private activeTeamNode?: INavigationNode;
    private currentActiveTeam?: INavigationNode;
    private currentProcessingTeamId?: number;
    private _dynamicTeamAdded$ = new Subject<INavigationNode>();
    private _dynamicTeamRemoved$ = new Subject<INavigationNode>();
    private readonly TeamsLabelWithoutTier1 = "Teams";

    // store the count of boards the user has access to
    // so we can refresh the team hierarchy when this number changes
    private boardCount = 0;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private authService: AuthorisationService,
        private orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
        private kanbanService: KanbanService,
        private routeService: RouteService,
        private teamsService: CommonTeamsService,
        meetingsService: MeetingsService,
    ) {
        super(orgService, authNotification);
        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChanged(Team), // still need this for team name change
            rxjsBreezeService.entityTypeChanged(ExternalDashboard),
            // this will determine if work node will be shown
            rxjsBreezeService.entityTypeChanged(Board).pipe(
                switchMap(() => kanbanService.getAllAccessibleBoards()),
                filter((boards) => this.boardCount !== boards.length),
                tap((boards) => this.boardCount = boards.length),
            ),
            // this will trigger a hierarchy nav refresh if you are the attendee and you don't already has meeting read from global or team permission
            rxjsBreezeService.entityTypeChanged(MeetingAttendee).pipe(
                // this is needed for attendee detach as nav property will be nulled off
                switchMap((meetingAttendee) => meetingsService.getMeetingById(meetingAttendee.meetingId).pipe(
                    map((meeting) => ({ meeting, meetingAttendee })),
                )),
                switchMap(({ meeting, meetingAttendee }) => authService.getHasAccess(CommonTeamsAuthService.ReadTeamMeetings, meeting).pipe(
                    map((hasReadAccess) => ({ hasReadAccess, meetingAttendee })),
                )),
                filter(({ hasReadAccess, meetingAttendee }) => !hasReadAccess && authService.currentPerson?.personId === meetingAttendee.attendeeId),
            ),
        ).pipe(
            debounceTime(100), // only a single rebuild required for multiple boards loaded
        );
    }

    public get dynamicTeamAdded$() {
        return this._dynamicTeamAdded$.asObservable();
    }

    public get dynamicTeamRemoved$() {
        return this._dynamicTeamRemoved$.asObservable();
    }

    public onNodeActivated(activeNode: INavigationNode) {
        if (this.currentActiveTeam) { // this will only be defined for team dynamic node which is not part of 'My Teams'
            let activeNodeInTeamHierarchy = false;
            let nodeIterator: INavigationNode | undefined = activeNode;
            while (nodeIterator != null) {
                if (nodeIterator === this.currentActiveTeam) {
                    activeNodeInTeamHierarchy = true;
                    break;
                }

                nodeIterator = nodeIterator.parent;
            }

            if (!activeNodeInTeamHierarchy) {
                // out of current active team -> will remove
                this.activeTeamNode?.removeChild(this.currentActiveTeam);
                this._dynamicTeamRemoved$.next(this.currentActiveTeam);
                this.currentActiveTeam = undefined;
            }
        }
    }

    public async onDynamicNodeCreated(dynamicNode?: INavigationNode) {
        if (!this.activeTeamNode || !dynamicNode || !this.teamsRootNode) {
            // won't bother doing the rest if no active team node (which is only created for architecture v2)
            return;
        }

        // check if dynamic node has parent of team root node, not doing anything if is not a team node
        let isTeamNode = false;
        let parentNode = dynamicNode.parent;
        while (parentNode) {
            if (parentNode === this.teamsRootNode || parentNode.url === this.teamsRootNode.url || parentNode.title === this.TeamsLabelWithoutTier1) {
                isTeamNode = true;
                break;
            }
            parentNode = parentNode.parent;
        }

        const teamId = this.routeService.currentActivatedRoute?.snapshot.paramMap.get("teamId");
        if (isTeamNode && teamId
            && teamId !== this.currentProcessingTeamId?.toString()) {
            // use currentProcessingTeamId to block double-up of node creation,
            // e.g. team dashboard redirected to team dashboard with query param page=guidance, where each will create a dynamic node.
            // - already prevent that from the nav hierarchy service callback when $locationChangeSuccess
            // - this may still happen if going to a child node of a team from outside and then redirected to another child node before the promises finish
            //   (url different but both under the same team)
            this.currentProcessingTeamId = Number(teamId);
            const teamDashboardUrl = await lastValueFrom(teamDashboardPageRoute.getRoute({ teamId: this.currentProcessingTeamId }));
            if (!this.isTeamDashboardNodeFound(teamDashboardUrl)) {
                const team = await lastValueFrom(this.teamsService.getTeamById(this.currentProcessingTeamId));
                if (team) {
                    // build and add new child
                    this.currentActiveTeam = await this.promiseToBuildTeamNavigationNode(team);

                    // remove all existing children of activeTeamNode
                    const existingChildren = this.activeTeamNode.children.slice();
                    for (const child of existingChildren) {
                        this.activeTeamNode.removeChild(child);
                        this._dynamicTeamRemoved$.next(child);
                    }

                    this.activeTeamNode.addChild(this.currentActiveTeam);
                    this._dynamicTeamAdded$.next(this.currentActiveTeam);
                }
            }

            this.currentProcessingTeamId = undefined;
        }
    }

    private isTeamDashboardNodeFound(teamDashboardUrl: string) {
        if (this.teamsRootNode) {
            return this.teamsRootNode.children.some((node) => matchTeamNode(node, teamDashboardUrl));
        } else {
            return false;
        }

        function matchTeamNode(node: INavigationNode, url: string): boolean {
            if (node.url === url) {
                return true;
            } else if (node.controller !== teamDashboardPageRoute.id) {
                return node.children.some((n) => matchTeamNode(n, url));
            } else { // not search beyond team dashboard node - this node matches teamDashboardPageRoute.id
                return false;
            }
        }
    }

    protected async buildHierarchy() {
        const self = this;

        const hasTier1Access = await this.authService.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1);
        const rootTeamBuilder = hasTier1Access
            ? this.navUtilitiesFactory.nodeBuilderForControllerAndParams(navigateTeamsPageRoute.id).setTitle("All Teams")
            : this.navUtilitiesFactory.nodeBuilder().setTitle(this.TeamsLabelWithoutTier1);
        this.teamsRootNode = await rootTeamBuilder
            .keepChildrenInAddedOrder()
            .setIconClass(TeamHierarchyNavigationService.IconClass)
            .setHideIconInBreadcrumb(true)
            .promiseToAddChild(promiseToBuildActiveTeamNode())
            .promiseToAddChild(promiseToBuildMyTeamsNode(false))
            .promiseToAddChild(promiseToBuildMyTeamsNode(true))
            .promiseToAddChild(promiseToBuildOtherTeamsNode(false))
            .promiseToAddChild(promiseToBuildOtherTeamsNode(true))
            .promiseToBuild();

        // get this after all the teams have been fetched by the above
        const accessibleBoards = await lastValueFrom(this.kanbanService.getAllAccessibleBoards());
        this.boardCount = accessibleBoards.length;

        return this.teamsRootNode;

        async function promiseToBuildActiveTeamNode() {
            self.activeTeamNode = await self.navUtilitiesFactory.nodeBuilder()
                .setTitle("Current Team")
                .setCustomKeyValue("expandOnLoad", true)
                .setCustomKeyValue("isHiddenInBreadcrumbs", true)
                .setCustomKeyValue("displayAsAccordionInHierarchy", true)
                .promiseToBuild();
            return self.activeTeamNode;
        }

        async function promiseToBuildMyTeamsNode(isPrivate: boolean) {
            const myTeamsNodeBuilder = self.navUtilitiesFactory.nodeBuilder()
                .setTitle(isPrivate ? "My Private Teams" : "My Teams")
                .setCustomKeyValue("expandOnLoad", true)
                .setCustomKeyValue("isHiddenInBreadcrumbs", true)
                .setCustomKeyValue("displayAsAccordionInHierarchy", true);

            const activeTeams = await self.teamsService.promiseToGetActiveTeamsForCurrentPerson();
            buildTeamNodes(activeTeams.filter((team) => team.isPrivate === isPrivate));
            return myTeamsNodeBuilder.promiseToBuild();

            function buildTeamNodes(teams: Team[]) {
                teams.map(self.promiseToBuildTeamNavigationNode)
                    .forEach(myTeamsNodeBuilder.promiseToAddChild);
            }
        }

        async function promiseToBuildOtherTeamsNode(isPrivate: boolean) {
            const activeTeamIds = (await self.teamsService.promiseToGetActiveTeamsForCurrentPerson())
                .map((team) => team.teamId);
            const otherTeamsNodeBuilder = self.navUtilitiesFactory.nodeBuilder()
                .setTitle(isPrivate ? "Other Private Teams" : "Other Teams")
                .setCustomKeyValue("expandOnLoad", activeTeamIds.length === 0)
                .setCustomKeyValue("isHiddenInBreadcrumbs", true)
                .setCustomKeyValue("displayAsAccordionInHierarchy", true);
            const allTeams = await self.teamsService.promiseToGetAllActiveTeams();
            allTeams
                .filter((team) => !activeTeamIds.includes(team.teamId))
                .filter((team) => team.isPrivate === isPrivate) // only gets private team you are not in if you are a stakeholder
                .map((team) => self.promiseToBuildTeamNavigationNode(team))
                .forEach((nodeBuildPromise) => otherTeamsNodeBuilder.promiseToAddChild(nodeBuildPromise));
            return otherTeamsNodeBuilder.promiseToBuild();
        }
    }

    @Autobind
    private async promiseToBuildTeamNavigationNode(team: Team) {
        const teamParams = {
            teamId: team.teamId,
        };

        const promiseToBuildTeamSystemsNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(teamDashboardPageRoute.id, teamParams, undefined, { page: "systems" })
            .setTitle("Systems")
            .ifAuthorised(IntegratedArchitectureFrameworkAuthService.ReadTier2, team)
            .promiseToBuild();

        const promiseToBuildTeamObjectivesNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(teamObjectivesPageRoute.id, teamParams)
            .ifAuthorised(ObjectivesAuthService.ReadObjectivesForTeam, team)
            .promiseToBuild();
        const promiseToBuildTeamWorkNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(teamKanbanPageRoute.id, teamParams)
            .ifAuthorised(CommonTeamsAuthService.ViewTeamKanban, team)
            .promiseToBuild();
        const promiseToBuildTeamMeetingsNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(teamMeetingsPageRoute.id, teamParams)
            .ifAuthorised(CommonTeamsAuthService.ViewTeamMeetings, team)
            .promiseToBuild();

        const promiseToBuildTeamNetworkHealthNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(teamCatchupsPageRoute.id, teamParams)
            .ifAuthorised(CommonTeamsAuthService.ViewTeamNetworkHealth, team)
            .promiseToBuild();

        const promiseToBuildAnalyseTeamAssessmentNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(analyseTeamAssessmentSurveyPageRoute.id, teamParams)
            .ifAuthorised(TeamAssessmentAuthService.ReadTeamAssessment, team)
            .promiseToBuild();

        const promiseToBuildTeamAssessmentNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(manageTeamAssessmentsPageRoute.id, teamParams)
            .ifAuthorised(TeamAssessmentAuthService.ReadTeamAssessment, team)
            .promiseToAddChild(promiseToBuildAnalyseTeamAssessmentNode)
            .promiseToBuild();

        const builder = this.navUtilitiesFactory.nodeBuilderForControllerAndParams(teamDashboardPageRoute.id, teamParams)
            .setTitle(team.name)
            .keepChildrenInAddedOrder()
            .setCustomKeyValue("exclusiveExpand", true);

        const hasExternalDashboardAccess = await this.authService.promiseToGetHasAccess(OrganisationAuthService.ViewExternalDashboard, team);
        if (hasExternalDashboardAccess) {
            const externalDashboards = (await this.orgService.promiseToGetExternalDashboards(team.teamId)).map(
                (externalDashboard) => this.promiseToBuildExternalDashboardNode(externalDashboard, this.navUtilitiesFactory, team));
            builder.promiseToAddChildren(externalDashboards);
        }

        builder.promiseToAddChild(promiseToBuildTeamSystemsNode)
            .promiseToAddChild(promiseToBuildTeamObjectivesNode)
            .promiseToAddChild(promiseToBuildTeamWorkNode)
            .promiseToAddChild(promiseToBuildTeamMeetingsNode)
            .promiseToAddChild(promiseToBuildTeamNetworkHealthNode)
            .promiseToAddChild(promiseToBuildTeamAssessmentNode);
        if (team.isPrivate) {
            builder.setIconClass(TeamPrivateIconComponent.IconClass)
                .setIconPositionRight(true);
        }

        return builder.promiseToBuild();
    }
}
