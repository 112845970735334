<adapt-toolbar-inactive [entity]="person"
                        type="person">
</adapt-toolbar-inactive>

<div adaptToolbarContent
     *ngIf="hasEditPermission">
    <button adaptButtonIcon="add"
            (click)="recordCareerValuation()"
            data-test="record-new-button">Record new</button>
    <button adaptButtonIcon="edit"
            *ngIf="currentValuation"
            [adaptBlockingClick]="editCareerValuation"
            data-test="edit-latest-button">Edit latest</button>
</div>
<adapt-two-column-page>
    <div left-column>
        <adapt-dashboard-element #currentCVTDashboardElement
                                 name="{{fullName}} Career Valuation"
                                 data-test="view-latest-career-valuation">
            <div *adaptLoading="isLoading">
                <div *ngIf="!currentValuation">
                    No career valuations have been recorded.
                </div>
                <div *ngIf="currentValuation">
                    <adapt-cvt-personal-summary [careerValuation]="currentValuation">
                    </adapt-cvt-personal-summary>

                    <div *ngIf="currentValuation.links?.length"
                         class="my-3">
                        <label>Associated actions</label>
                        <adapt-display-action-link *ngFor="let link of currentValuation.links"
                                                   [link]="link"
                                                   [itemLinksDisabled]="true">
                        </adapt-display-action-link>
                    </div>

                    <adapt-cultural-measurement-meta [culturalEntity]="currentValuation"
                                                     [showCulturalLeaderData]="true"
                                                     data-test="cultural-measurement-metadata">
                    </adapt-cultural-measurement-meta>
                </div>
            </div>
        </adapt-dashboard-element>
    </div>
    <div right-column>
        <adapt-dashboard-element name="{{fullName}} Career Valuation history"
                                 *ngIf="currentValuation">
            <button adapt-dashboard-title-buttons
                    class="btn btn-primary btn-pill"
                    adaptButtonIcon="fal fa-download"
                    (click)="onClickExport()"
                    data-test="export-data-button">Export data</button>

            <adapt-cvt-history [personId]="person?.personId"
                               data-test="career-valuation-history">
            </adapt-cvt-history>
        </adapt-dashboard-element>
    </div>
</adapt-two-column-page>
