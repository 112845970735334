<div class="display-tree-objective d-flex flex-column"
     *ngIf="!!objective"
     [ngClass]="{'root': !objective.parentObjectiveId, 'external-objective': isExternalObjective, 'unfocussed': !isFocussed, 'compact': isCompactView}"
     [attr.id]="objective.objectiveId">
    <div class="objective-body"
         [class.has-progress]="showProgress">
        <div class="d-flex flex-column align-middle">
            <div class="d-flex flex-row objective-tree-title">
                <adapt-link-person *ngIf="objective.assigneePersonId && isFocussed"
                                   [personId]="objective.assigneePersonId"
                                   [showImage]="true"
                                   [showText]="false"></adapt-link-person>

                <adapt-link-objective class="me-auto"
                                      [objective]="objective"
                                      [showStatus]="false"
                                      [showStatusIcon]="false"></adapt-link-objective>
                <adapt-link-objectives-page *ngIf="isExternalObjective && !isFocussed"
                                            class="align-self-center px-2"
                                            [team]="objective.team"
                                            [view]="treeViewType"></adapt-link-objectives-page>
                <ng-container *ngIf="!isCompactView">
                    <adapt-select-objective-status *ngIf="hasEditPermissions"
                                                   class="status-offset"
                                                   [objective]="objective"></adapt-select-objective-status>
                    <adapt-display-objective-status *ngIf="!hasEditPermissions"
                                                    class="status-offset"
                                                    [objectiveStatus]="objective.status"></adapt-display-objective-status>
                </ng-container>

                <adapt-objective-actions *ngIf="hasEditPermissions"
                                         [objective]="objective"
                                         [currentPageTeamId]="currentPageTeamId"></adapt-objective-actions>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div class="d-flex flex-row flex-wrap align-items-center gap-2">
                <ng-container *ngIf="isFocussed">
                    <span><b>Due</b> {{objective.dueDate | adaptDate}}</span>
                    <adapt-link-objectives-page *ngIf="isExternalObjective"
                                                [team]="objective.team"
                                                [view]="treeViewType"></adapt-link-objectives-page>
                </ng-container>
                <ng-container *ngIf="isCompactView">
                    <adapt-select-objective-status *ngIf="hasEditPermissions"
                                                   class="d-inline-flex"
                                                   [objective]="objective"></adapt-select-objective-status>
                    <adapt-display-objective-status *ngIf="!hasEditPermissions"
                                                    class="d-inline-flex"
                                                    [isCompactView]="isCompactView"
                                                    [objectiveStatus]="objective.status"></adapt-display-objective-status>
                </ng-container>
                <adapt-display-labels *ngIf="objective.labelLocations?.length"
                                      [labelLocations]="objective.labelLocations"></adapt-display-labels>
            </div>

            <ng-container *ngIf="isFocussed && !isCompactView">
                <div *ngIf="linkedObjectives.length > 0
                || linkedActions.length > 0"
                     class="related">
                    <ng-container *ngIf="linkedObjectives.length > 0">
                        <small>Linked objectives</small>
                        <adapt-display-linked-objectives [objective]="objective"
                                                         [showGroupObjectiveTextSuffix]="false"
                                                         [currentPageTeamId]="currentPageTeamId"></adapt-display-linked-objectives>
                    </ng-container>
                    <ng-container *ngIf="linkedActions.length > 0">
                        <small>Linked actions</small>
                        <div *ngFor="let item of linkedActions">
                            <adapt-item-link class="item-title"
                                             [item]="item"
                                             [showSummary]="true"
                                             [showStatus]="true"
                                             [showPersonImageLink]="true"></adapt-item-link>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="showProgress"
         class="objective-footer">
        <adapt-progress-bar [percentageProgress]="objective.currentProgress"
                            [scaleHeight]="1.25"
                            colour="var(--adapt-open-blue-500)"
                            backgroundColour="#f5f5f5"></adapt-progress-bar>
    </div>
</div>
