import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Link } from "@common/ADAPT.Common.Model/organisation/link";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EntityUpdateUtilities } from "@org-common/lib/entity-sync/entity-update-utilities";
import { ItemUtilities } from "@org-common/lib/kanban/items/item-utilities";
import { merge, ReplaySubject } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { LinkService } from "../../link/link.service";
import { ObjectiveFilterService } from "../objective-filter/objective-filter.service";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesAuthService } from "../objectives-auth.service";

@Component({
    selector: "adapt-display-tree-objective",
    templateUrl: "./display-tree-objective.component.html",
    styleUrls: ["./display-tree-objective.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DisplayTreeObjectiveComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() public objective!: Objective;
    @Input() public isExternalObjective = false;
    @Input() public isCompactView = false;
    @Input() public applyFilter = false;
    @Input() public currentPageTeamId: number | null = null;
    @Output() public heightChange = new EventEmitter();

    public readonly treeViewType = ObjectiveViewType.ObjectiveTreeView;
    public isFocussed = true;
    public hasEditPermissions = false;
    public linkedActions: Item[] = [];
    public linkedObjectives: Objective[] = [];
    private objective$ = new ReplaySubject<Objective>(1);

    constructor(
        private objectivesAuthService: ObjectivesAuthService,
        private filterService: ObjectiveFilterService,
        public linkService: LinkService,
        entityUpdateUtilities: EntityUpdateUtilities,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        entityUpdateUtilities.onEntityChange(() => this.objective).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((objective) => {
            this.heightChange.emit(objective);
        });

        this.objective$.pipe(
            switchMap(() => this.objectivesAuthService.hasWriteAccessToObjective(this.objective.teamId)),
            this.takeUntilDestroyed(),
        ).subscribe((hasPermissions) => {
            this.hasEditPermissions = hasPermissions;
            this.updateLinks();
        });

        merge(
            rxjsBreezeService.entityTypeChanged(Item).pipe(
                filter((item) => this.objective?.links.some((l) => l.itemId === item.itemId)),
            ),
            rxjsBreezeService.entityTypeChanged(Link).pipe(
                filter((l) => l.objective1Id === this.objective?.objectiveId),
            ),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateLinks());
    }

    private updateLinks() {
        this.linkedActions = this.objective.links.filter((l) => l.extensions.isObjectiveToItemLink).map((l) => l.item)
            .filter((i) => i !== undefined)
            .sort(ItemUtilities.getItemStatusAndItemCodeSortComparator());

        this.linkedObjectives = this.objective.links.filter((l) => l.extensions.isObjectiveToObjectiveLink).map((l) => l.objective2)
            .filter((i) => i !== undefined);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.objective && changes.objective.currentValue) {
            this.objective$.next(this.objective);
            setTimeout(() => this.heightChange.emit(this.objective));
        }

        if (this.isExternalObjective && !this.isCompactView) {
            this.isCompactView = true;
        }
    }

    public ngOnInit() {
        if (this.applyFilter) {
            this.filterService.isFocussedObjective(this.objective$).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((isFocussedObjective) => {
                this.isFocussed = isFocussedObjective;
                this.heightChange.emit();
            });
        }
    }

    public get showProgress() {
        return this.objective.hasBeenScored && this.isFocussed;
    }
}
