import { Injectable, Injector } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategory } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { switchMap } from "rxjs";
import { AfterOrganisationInitialisationObservable } from "../organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { EditCareerValuationDialogComponent, IEditCareerValuationDialogData } from "./edit-cvt-dialog/edit-cvt-dialog.component";

@Injectable({
    providedIn: "root",
})
export class OrgCommonCareerValuationUiService extends BaseOrganisationService {
    public static readonly CareerValuationPaletteName = "careerValuation";

    constructor(injector: Injector,
                private dialogService: AdaptCommonDialogService) {
        super(injector);
    }

    /**
     * Deletes a career valuation.
     *
     * @param {CareerValuation} careerValuation - The career valuation to delete
     * @returns {Promise} A promise to delete the career valuation
     */
    public promiseToDeleteCareerValuation(careerValuation: CareerValuation) {
        let itemLinkMessage = "";

        if (careerValuation.links.length) {
            itemLinkMessage = "<p>Note that all actions will be unlinked from the Career Valuation.</p>";
        }
        return this.dialogService.openConfirmationDialog({
            title: "Delete career valuation",
            message: "<p>Are you sure you wish to delete this career valuation?</p>" + itemLinkMessage,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).pipe(
            switchMap(() => this.commonDataService.remove(careerValuation)),
            switchMap(() => this.commonDataService.saveEntities(careerValuation)),
        );
    }

    @AfterOrganisationInitialisationObservable
    public editCareerValuation(careerValuation: CareerValuation, previousCareerValuation?: CareerValuation) {
        // although is just a single careerValuation entity here, still pass in a data object
        // so that dialog state can be persisted in the same data object and restored.
        const data: IEditCareerValuationDialogData = {
            careerValuation,
            previousCareerValuation,
        };

        return this.dialogService.open(EditCareerValuationDialogComponent, data);
    }

    public getCategoryColour(category: CareerValuationCategory) {
        // todo: work out how to get this from the DevExpress palette
        // todo: these are now also in our style.less file
        switch (category.ordinal) {
            case 0:
                return "rgb(153, 180, 86)";
            case 1:
                return "rgb(81, 175, 167)";
            case 2:
                return "rgb(19, 149, 186)";
            case 3:
                return " rgb(15, 92, 120)";
            case 4:
                return "rgb(192, 46, 29)";
            case 5:
                return "rgb(239, 139, 44)";
            case 6:
                return "rgb(235, 200, 68)";
            case 7:
                return "rgb(241, 108, 32)";
            default:
                return "rgb(50,50,50)";
        }
    }
}
