import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { OrgCommonPeerCatchupAuthService } from "@org-common/lib/peer-catchup/org-common-peer-catchup-auth.service";
import { lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class PeerCatchupAuthService {

    public constructor(
        private authService: AuthorisationService,
    ) { }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.ReadMyPeerCatchup,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpPersonalUseAndRead,
                    FeaturePermissionName.PeoplePeerCatchUpPersonalEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.EditMyPeerCatchup,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpPersonalEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.ReadAllPeerCatchups,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpRead,
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.EditAllPeerCatchups,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.OpenPeerCatchupDashboard,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePeerCatchUpPersonalUseAndRead,
                    FeaturePermissionName.PeoplePeerCatchUpPersonalEdit,
                    FeaturePermissionName.PeoplePeerCatchUpRead,
                    FeaturePermissionName.PeoplePeerCatchUpEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.ReadPeerCatchupsForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToReadPeerCatchUpsForPerson(),
            },
        );
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.EditPeerCatchupsForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToEditPeerCatchUpsForPerson(),
            },
        );
        authorisationService.registerAccessVerifier(
            OrgCommonPeerCatchupAuthService.ReadAnyPeerCatchups,
            {
                invokeToGetAccessVerifier: (injector) => {
                    const commonDataService = injector.get(CommonDataService);
                    return getVerifyAccessToReadAnyPeerCatchUps(commonDataService);
                },
            },
        );

        function getVerifyAccessToReadPeerCatchUpsForPerson() {
            return verifyAccessToReadPeerCatchUpsForPersonFunction;

            function verifyAccessToReadPeerCatchUpsForPersonFunction(currentPerson: Person, destPerson: Person) {
                if (destPerson.personId === currentPerson.personId) {
                    return authorisationService.promiseToVerifyAccess(OrgCommonPeerCatchupAuthService.ReadMyPeerCatchup);
                }

                // this verifier is only used in the personContent popup and personal dashboard
                return authorisationService.promiseToVerifyAccess(OrgCommonPeerCatchupAuthService.ReadAllPeerCatchups)
                    .then(verifyDestPersonCanReadPcu);

                function verifyDestPersonCanReadPcu() {

                    // if they don't have permission and it's an active connection, then disallow
                    // (if they don't have an active connection, we just let it through because we never know if they had a historical connection...
                    // ...because we delete access level history)
                    const hasPermission = authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpPersonalUseAndRead)
                        || authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpPersonalEdit)
                        || authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpRead)
                        || authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpEdit);
                    const hasActiveConnection = !!destPerson.getActiveConnections().length;
                    if (!hasPermission && hasActiveConnection) {
                        return Promise.reject();
                    }

                    return Promise.resolve();
                }
            }
        }

        function getVerifyAccessToEditPeerCatchUpsForPerson() {
            return verifyAccessToEditPeerCatchUpsForPersonFunction;

            function verifyAccessToEditPeerCatchUpsForPersonFunction(currentPerson: Person, destPerson: Person) {
                if (destPerson.personId === currentPerson.personId) {
                    return authorisationService.promiseToVerifyAccess(OrgCommonPeerCatchupAuthService.EditMyPeerCatchup);
                }

                return authorisationService.promiseToVerifyAccess(OrgCommonPeerCatchupAuthService.EditAllPeerCatchups)
                    .then(verifyDestPersonCanEditPcu);

                function verifyDestPersonCanEditPcu() {
                    // if the person is not allowed to edit his own PCU, no one else should be
                    // - this includes person made inactive - TSL won't be able to edit PCU for person who left
                    if (authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpPersonalEdit) ||
                        authorisationService.personHasPermission(destPerson, FeaturePermissionName.PeoplePeerCatchUpEdit)) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject();
                    }
                }
            }
        }

        function getVerifyAccessToReadAnyPeerCatchUps(commonDataService: CommonDataService) {
            return verifyAccessToReadAnyPeerCatchUpsFunction;

            function verifyAccessToReadAnyPeerCatchUpsFunction(currentPerson: Person) {
                return authorisationService.promiseToVerifyAccess(OrgCommonPeerCatchupAuthService.ReadMyPeerCatchup)
                    .catch(verifyAccessToTeamCatchUp);

                async function verifyAccessToTeamCatchUp() {
                    const roleConnections = await lastValueFrom(new IntegratedArchitectureFrameworkQueryUtilities(commonDataService).getRoleConnectionsForPersonId(currentPerson.personId));
                    if (roleConnections.some((roleConnection: RoleConnection) =>
                        authorisationService.roleConnectionHasPermission(roleConnection, FeaturePermissionName.PeoplePeerCatchUpRead))) {
                        return Promise.resolve();
                    } else {
                        return Promise.reject();
                    }
                }
            }
        }
    }

    public get canReadAnyPeerCatchups() {
        return this.authService.getHasAccess(OrgCommonPeerCatchupAuthService.ReadAnyPeerCatchups).pipe(
            take(1),
        );
    }

    public get canReadAllCatchups() {
        return this.authService.getHasAccess(OrgCommonPeerCatchupAuthService.ReadAllPeerCatchups).pipe(
            take(1),
        );
    }
}
