<adapt-context-sidebar-implementation-kit *adaptIfAlto="false"
                                          [articleId]="ImplementationKitArticle.IntroducingObjectivesAndKeyResults"
                                          context="page"></adapt-context-sidebar-implementation-kit>
<adapt-context-sidebar-implementation-kit *adaptIfAlto="true"
                                          [articleId]="ImplementationKitArticle.ObjectivesOverview"
                                          context="page"></adapt-context-sidebar-implementation-kit>
<div adaptBlockingButtonGroup
     *ngIf="hasEditPermissions && (primedObjective$ | async) as objective">
    <button adaptButtonIcon="edit"
            adaptToolbarContent
            [adaptBlockingClick]="editObjective"
            [adaptBlockingClickParam]="objective"
            data-test="edit-objective-button">Edit objective</button>
    <adapt-objective-actions [objective]="objective"
                             [currentPageTeamId]="objective.teamId"
                             adaptToolbarContent
                             [ordinal]="2001"
                             [showEditAction]="false"
                             (objectiveDeleted)="onObjectiveDeleted($event)"></adapt-objective-actions>
</div>

<div class="edit-objective-page-component-container"
     *ngIf="(primedObjective$ | async) as objective; else notFoundTemplate">
    <adapt-toolbar-title *ngIf="isXL$ | async">
        <div class="d-flex align-items-center">
            <adapt-link-objective class="objective-title"
                                  [objective]="objective"
                                  [generateHref]="false"
                                  [showStatusIcon]="false"></adapt-link-objective>
            <adapt-display-labels class="ms-2"
                                  *ngIf="objective.labelLocations.length > 0"
                                  [labelLocations]="objective.labelLocations"
                                  data-test="objective-labels-display"></adapt-display-labels>
        </div>
    </adapt-toolbar-title>

    <div class="card card-borderless">
        <div class="card-body">
            <div class="row">
                <div class="mainbar col-lg-8 d-flex flex-column">
                    <dx-scroll-view showScrollbar="always">
                        <div class="scroll-content">
                            <div class="objective-section d-flex flex-column"
                                 *ngIf="(isXL$ | async) === false">
                                <adapt-link-objective class="objective-title"
                                                      [objective]="objective"
                                                      [generateHref]="false"
                                                      [showStatus]="false"
                                                      [showStatusIcon]="false"></adapt-link-objective>
                                <adapt-display-labels class="mt-2"
                                                      *ngIf="objective.labelLocations.length > 0"
                                                      [labelLocations]="objective.labelLocations"
                                                      data-test="objective-labels-display"></adapt-display-labels>
                            </div>

                            <div *ngIf="showReviewReminder; else showReview"
                                 class="mt-3 alert alert-warning d-flex align-items-center gap-3">
                                <span>
                                    How was your progress with this objective? Please take a moment to reflect on the aspects that
                                    have been successful and those that have been challenging.
                                </span>
                                <button adaptButton="primary"
                                        class="flex-grow-0 flex-shrink-0"
                                        (click)="reviewObjective(objective)"
                                        data-test="start-objective-review-button">Review objective</button>
                                <button adaptButton="dismissBorderless"
                                        class="ms-auto"
                                        (click)="dismissReview(objective)"
                                        data-test="dismiss-objective-review-button"></button>
                            </div>
                            <ng-template #showReview>
                                <div *ngIf="objective.objectiveReview?.hasContent"
                                     class="objective-section objective-section-print-styling">
                                    <h3>Objective review
                                        <button [(adaptCollapsibleButton)]="reviewCollapsed"></button>
                                        <button adaptButton="editBorderless"
                                                *ngIf="hasEditPermissions"
                                                class="ms-auto"
                                                (click)="reviewObjective(objective)"></button>
                                    </h3>
                                    <div [collapse]="reviewCollapsed"
                                         class="alert alert-info pb-1">
                                        <div class="objective-review">
                                            <ng-container *ngTemplateOutlet="reviewItem; context: {
                                                                                            title: 'What went well?',
                                                                                            content: objective.objectiveReview?.whatWentWell,
                                                                                        }"></ng-container>
                                            <ng-container *ngTemplateOutlet="reviewItem; context: {
                                                                                            title: 'What didn\'t go well?',
                                                                                            content: objective.objectiveReview?.whatWentWrong,
                                                                                        }"></ng-container>
                                            <ng-container *ngTemplateOutlet="reviewItem; context: {
                                                                                            title: 'What did we learn?',
                                                                                            content: objective.objectiveReview?.whatWeLearned,
                                                                                        }"></ng-container>

                                            <ng-template #reviewItem
                                                         let-title="title"
                                                         let-content="content">
                                                <div class="card"
                                                     *ngIf="content">
                                                    <h4 class="card-header">{{title}}</h4>
                                                    <div class="card-body pb-2">
                                                        <dx-scroll-view>
                                                            <div [froalaView]="content"
                                                                 class="p-2"></div>
                                                        </dx-scroll-view>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <div class="objective-section objective-section-print-styling">
                                <div class="w-100 d-flex">
                                    <h3>Key results
                                        <button [(adaptCollapsibleButton)]="isKeyResultsCollapsed"></button>
                                    </h3>
                                    <button *ngIf="!isClosed && hasEditPermissions"
                                            adaptButton="addLink"
                                            class="ms-auto"
                                            data-tour="add-key-result"
                                            (click)="addKeyResult()">Add key result</button>
                                </div>
                                <div [collapse]="isKeyResultsCollapsed">
                                    <adapt-key-results-list [keyResults]="objective.keyResults"
                                                            [hasEditPermissions]="hasEditPermissions"
                                                            [comments]="chainComments$ | async"
                                                            [updates]="chainUpdates$ | async"
                                                            (chainUpdateDeleted)="deleteChainUpdate($event)"
                                                            (chainUpdateEdited)="editChainUpdate($event)"
                                                            (keyResultUpdated)="triggerActivityUpdate$.next()"></adapt-key-results-list>
                                </div>
                            </div>

                            <div class="objective-section objective-section-print-styling">
                                <h3>Description
                                    <button [(adaptCollapsibleButton)]="isDescriptionCollapsed"></button>
                                </h3>
                                <div [collapse]="isDescriptionCollapsed">
                                    <div [froalaView]="objective.description ? objective.description : 'No description'"></div>
                                </div>
                            </div>

                            <div class="objective-section  objective-section-print-styling objective-section-activity">
                                <div class="d-flex">
                                    <h3>Activity
                                        <button [(adaptCollapsibleButton)]="isActivityCollapsed"></button>
                                    </h3>

                                    <div class="ms-auto d-flex gap-3">
                                        <dx-check-box text="Key results"
                                                      [value]="showKeyResultActivities"
                                                      (valueChange)="showKeyResultActivities = $any($event); triggerActivityUpdate$.next()"></dx-check-box>
                                        <dx-check-box text="Comments"
                                                      [value]="showCommentActivities"
                                                      (valueChange)="showCommentActivities = $any($event); triggerActivityUpdate$.next()"></dx-check-box>
                                    </div>
                                </div>
                                <adapt-comment-chain class="adapt-comment-chain-container"
                                                     [collapse]="isActivityCollapsed"
                                                     (chainUpdateDeleted)="deleteChainUpdate($event)"
                                                     (chainUpdateEdited)="editChainUpdate($event)"
                                                     (updateRequired)="refresh()"
                                                     [comments]="filteredChainComments$ | async"
                                                     [updates]="filteredChainUpdates$ | async"
                                                     [createComment$]="newComment$"
                                                     noCommentsText="No activity yet."></adapt-comment-chain>
                            </div>
                        </div>
                    </dx-scroll-view>
                </div>
                <div class="sidebar col-lg-4">
                    <dx-scroll-view showScrollbar="always">
                        <div class="scroll-content">
                            <div class="form-group objective-section d-print-none">
                                <h3 class="gap-2">Status
                                    <adapt-select-objective-status *ngIf="hasEditPermissions"
                                                                   [objective]="objective"></adapt-select-objective-status>
                                    <adapt-display-objective-status *ngIf="!hasEditPermissions"
                                                                    [objectiveStatus]="objective.status"></adapt-display-objective-status>
                                </h3>
                            </div>
                            <div class="form-group objective-section d-none d-print-block my-2">
                                <h3 class="gap-2">Status </h3>
                                <adapt-display-objective-status [objectiveStatus]="objective.status"></adapt-display-objective-status>
                            </div>

                            <div class="form-group objective-section ">
                                <h3>Score</h3>
                                <ng-container *ngIf="objective.hasKeyResults; else noScoreGuidance">
                                    <adapt-display-objective-progress [objective]="objective"
                                                                      data-test="objective-score"></adapt-display-objective-progress>
                                </ng-container>
                            </div>
                            <ng-template #noScoreGuidance>
                                <div class="alert alert-info">
                                    <p>This objective cannot be scored as no key results have been defined yet.</p>
                                    <div *ngIf="hasEditPermissions && !isClosed">
                                        <p>Add a key result to start scoring.</p>
                                        <button adaptButtonIcon="add"
                                                class="btn btn-primary btn-small"
                                                (click)="addKeyResult()"
                                                data-tour="add-key-result"
                                                data-test="add-key-result-button">Add key result</button>
                                    </div>
                                </div>
                            </ng-template>

                            <div class="form-group mt-4 objective-section objective-section-print-styling">
                                <h3>Owner</h3>
                                <div>
                                    <adapt-link-person [person]="objective.assigneePerson"
                                                       [showImage]="true"></adapt-link-person>
                                </div>
                            </div>
                            <div class="form-group mb-5">
                                <h3>Due: {{objective.dueDate | adaptDate}}</h3>
                            </div>

                            <div class="objective-section objective-section-print-styling"
                                 *ngIf="objective.parentObjective as parentObjective">
                                <h3>Supports</h3>
                                <adapt-link-objective [objective]="parentObjective"
                                                      [showAssigneeImage]="true"></adapt-link-objective>
                            </div>

                            <div class="objective-section objective-section-print-styling"
                                 *ngIf="objective.childObjectives.length > 0">
                                <h3>Supported by</h3>
                                <adapt-display-linked-objectives [objectives]="objective.childObjectives">
                                </adapt-display-linked-objectives>
                            </div>

                            <div class="form-group objective-section objective-section-print-styling mb-3">
                                <h3>Links</h3>
                                <div class="d-flex align-items-center gap-1">
                                    <small>Objectives</small>
                                    <button adaptButtonIcon="add"
                                            *ngIf="!isClosed && hasEditPermissions"
                                            class="btn btn-link"
                                            adaptTooltip="Add a link to an existing objective"
                                            (click)="addObjectiveLink()"
                                            data-test="add-related-objective-button">
                                    </button>
                                </div>
                                <div *ngIf="objective.links.length === 0; else displayLinkedObjectives">No linked objectives.</div>
                                <ng-template #displayLinkedObjectives>
                                    <adapt-display-linked-objectives [objective]="objective"
                                                                     [hasEditPermissions]="hasEditPermissions"></adapt-display-linked-objectives>
                                </ng-template>
                            </div>

                            <div *adaptIfFeatureActive="FeatureName.StewardshipWorkKanban"
                                 class="form-group objective-section">
                                <div class="d-flex align-items-center gap-1">
                                    <small>Actions</small>
                                    <button adaptButtonIcon="add"
                                            *ngIf="!isClosed && hasEditPermissions"
                                            class="btn btn-link"
                                            adaptTooltip="Add a link to a new or existing action"
                                            (click)="addObjectiveItemLink()"
                                            data-test="add-related-item-button">
                                    </button>
                                </div>
                                <div *ngIf="objective.links.length === 0">No linked actions.</div>
                                <div *ngFor="let item of linkedActions"
                                     class="link-section mt-1">
                                    <adapt-item-link [item]="item"
                                                     [showStatus]="true"
                                                     [showSummary]="true"
                                                     [showPersonImageLink]="true"></adapt-item-link>
                                    <button adaptButtonIcon="delete"
                                            *ngIf="!isClosed && hasEditPermissions"
                                            class="btn btn-link link-delete-button"
                                            adaptTooltip="Delete action link"
                                            (click)="removeObjectiveItemLink(item)"></button>
                                </div>
                            </div>
                        </div>
                    </dx-scroll-view>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #notFoundTemplate>
    <div class="alert alert-danger"
         *ngIf="isInitialised">
        <p>We're sorry, but we couldn't locate the objective you're looking for. This could be due to one of three reasons:</p>
        <ol>
            <li>The objective may have been deleted.</li>
            <li>You might not have the necessary permissions to access it.</li>
            <li>The objective ID in the URL path might not be valid.</li>
        </ol>
    </div>
</ng-template>
