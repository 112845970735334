import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { myCatchupsPageRoute, personalCatchupsPageRoute } from "@org-common/lib/peer-catchup/my-catchups-page/my-catchups-page.route";
import { OrgCommonPeerCatchupModule } from "@org-common/lib/peer-catchup/org-common-peer-catchup.module";
import { teamCatchupsPageRoute } from "@org-common/lib/peer-catchup/team-catchups-page/team-catchups-page.route";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxAccordionModule, DxChartModule, DxDataGridModule, DxDateBoxModule, DxPieChartModule, DxSelectBoxModule, DxSliderModule } from "devextreme-angular";
import { AnalyseCatchupsPageComponent } from "./analyse-catchups-page/analyse-catchups-page.component";
import { analyseCatchupsPageRoute } from "./analyse-catchups-page/analyse-catchups-page.route";
import { CatchupRatingsGraphComponent } from "./catchup-ratings-graph/catchup-ratings-graph.component";
import { PeerCatchUpDashboardElementComponent } from "./peer-catch-up-dashboard-element/peer-catch-up-dashboard-element.component";
import { PeerCatchupAuthService } from "./peer-catchup-auth.service";


@NgModule({
    declarations: [
        PeerCatchUpDashboardElementComponent,
        AnalyseCatchupsPageComponent,
        CatchupRatingsGraphComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptFeatureModule,
        AdaptShellModule,
        AdaptAuthorisationModule,
        AdaptTeamDashboardSharedModule,
        AdaptDateModule,
        AdaptDirectorySharedModule,
        AdaptCommonDialogModule,
        AdaptHtmlEditorModule,
        AdaptCommonUserModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxAccordionModule,
        DxSliderModule,
        DxDataGridModule,
        DxChartModule,
        DxPieChartModule,
        AdaptDashboardElementModule,
        AdaptLinkDisabledModule,
        AdaptCollapsibleModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptMeetingsSharedModule,
        AdaptCommonTeamsModule,
        OrgCommonPeerCatchupModule,
    ],
    exports: [
        PeerCatchUpDashboardElementComponent,
    ],
})
export class AdaptPeerCatchupModule {
    public static readonly Routes = [
        ...analyseCatchupsPageRoute.routes,
        ...personalCatchupsPageRoute.routes,
        ...myCatchupsPageRoute.routes,
        ...teamCatchupsPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        PeerCatchupAuthService.registerAccess(authorisationService);
    }
}
