import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptCareerValuationSharedModule } from "app/features/people/career-valuation/career-valuation-shared.module";
import { AdaptEmbedShellModule } from "app/services/layout/embed-shell.module";
import { DxDataGridModule, DxListModule, DxTextBoxModule, DxTooltipModule } from "devextreme-angular";
import { CulturalLeadershipFrameworkGridComponent } from "./clf-grid/clf-grid.component";
import { CulturalLeadershipFrameworkPageComponent } from "./clf-page/clf-page.component";
import { culturalLeadershipFrameworkPageRoute } from "./clf-page/clf-page.route";
import { CulturalCohortPersonalDashboardElementComponent } from "./cultural-cohort-personal-dashboard-element/cultural-cohort-personal-dashboard-element.component";
import { initialiseCulturalLeadershipFramework } from "./cultural-leadership-framework-initialiser";
import { EditCulturalCohortDialogComponent } from "./edit-cultural-cohort-dialog/edit-cultural-cohort-dialog.component";
import { NoCulturalMeasurementComponent } from "./no-cultural-measurement/no-cultural-measurement.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptEmbedShellModule,
        DxListModule,
        AdaptDateModule,
        AdaptDirectorySharedModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptTwoColumnModule,
        AdaptCareerValuationSharedModule,
        DxDataGridModule,
        AdaptTooltipModule,
        AdaptAuthorisationModule,
        AdaptShellModule,
        AdaptCommonDialogModule,
        DxTooltipModule,
        AdaptDashboardElementModule,
        AdaptLinkDisabledModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleModule,
        DxTextBoxModule,
        AdaptCollapsibleDashboardElementModule,
    ],
    declarations: [
        CulturalLeadershipFrameworkPageComponent,
        NoCulturalMeasurementComponent,
        CulturalCohortPersonalDashboardElementComponent,
        CulturalLeadershipFrameworkGridComponent,
        EditCulturalCohortDialogComponent,
    ],
    exports: [
        NoCulturalMeasurementComponent,
        CulturalCohortPersonalDashboardElementComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: () => initialiseCulturalLeadershipFramework,
        },
    ],
})
export class AdaptCulturalLeadershipModule {
    public static readonly Routes = [
        ...culturalLeadershipFrameworkPageRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        CulturalLeadershipFrameworkAuthService.registerAccess(authorisationService);
    }
}
