import moment from "moment/moment";
import { DateFormats } from "../../ux/date-formats";
import { SpeedCatchup } from "./speed-catchup";
import { CatchupRatingType, CatchupRatingValue, RatingValues } from "./speed-catchup-rating";

// these are moved from speed-catchu-model.ts
export class SpeedCatchupExtensions {
    public constructor(private catchup: SpeedCatchup) {}

    public get worstRatingValue() {
        let worstRating = CatchupRatingValue.Green;
        if (Array.isArray(this.catchup.ratings)) {
            worstRating = this.catchup.ratings.reduce((worst, rating) =>
                RatingValues[rating.rating] > RatingValues[worst]
                    ? rating.rating
                    : worst,
                CatchupRatingValue.Green);
        }

        return worstRating;
    }

    public get linkText() {
        return `Peer Catch-up - ${moment(this.catchup.creationDate).format(DateFormats.moment.short)}`;
    }

    public getRatingByTypePersonAndPerspective(ratingType: CatchupRatingType, personId: number, isPersonAuthor: boolean) {
        // work out the subject person id
        let subjectPersonId;

        if (isPersonAuthor) {
            if (ratingType.authorIsSubject) {
                subjectPersonId = this.catchup.person1Id === personId
                    ? this.catchup.person1Id
                    : this.catchup.person2Id;
            } else {
                subjectPersonId = this.catchup.person1Id === personId
                    ? this.catchup.person2Id
                    : this.catchup.person1Id;
            }
        } else if (ratingType.authorIsSubject) {
            subjectPersonId = this.catchup.person1Id === personId
                ? this.catchup.person2Id
                : this.catchup.person1Id;
        } else {
            subjectPersonId = this.catchup.person1Id === personId
                ? this.catchup.person1Id
                : this.catchup.person2Id;
        }

        // now get the rating based on type and subject
        for (const rating of this.catchup.ratings) {
            if (rating.ratingType === ratingType.name) {
                if (rating.subjectPersonId === subjectPersonId) {
                    return rating;
                }
            }
        }

        return undefined;
    }
}
