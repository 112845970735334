import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom, Observable } from "rxjs";
import { CommonTeamsService } from "../../teams/common-teams.service";
import { CatchupView } from "../catchup-views";
import { PeerCatchupService } from "../peer-catchup.service";

// ths is to replace ajs adapt-catchup-graphs - handle switching of view for PCU
// Move the graphs configurations from catchups-page-content into here
@Component({
    selector: "adapt-catchup-views",
    templateUrl: "./catchup-views.component.html",
})
export class CatchupViewsComponent extends BaseComponent implements OnChanges {
    public readonly CatchupView = CatchupView;

    @Input() public viewSelection = CatchupView.Graph;
    @Input() public focusPerson?: Person;
    @Input() public teamId?: number;

    public graphOptions: any;
    public teamMembers?: Person[];

    // analyse PCUs page for org - without @Input specified, ngOnChanges won't be called
    public allowSelectPerson = true;
    public allowTeamSelection = true;
    public showExternalStakeholder = false;
    public showTeamRelationships = false;
    public getCatchups$: (fromDate?: Date, toDate?: Date, activeOnly?: boolean) => Observable<SpeedCatchup[]>;

    public gridOptions: any = {
        showExternalStakeholders: true,
        activeOnly: true,
    };

    public constructor(
        elementRef: ElementRef,
        private catchupService: PeerCatchupService,
        private teamsService: CommonTeamsService,
    ) {
        super(elementRef);

        this.getCatchups$ = (fromDate?: Date, toDate?: Date, activeOnly?: boolean) => this.catchupService.getCatchupsByDateRange(fromDate, toDate, activeOnly);
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.focusPerson && this.focusPerson) {
            this.gridOptions.filterForPersonId = this.focusPerson.personId;
            this.gridOptions.activeOnly = this.focusPerson.isActive();

            this.allowSelectPerson = false;
            this.showExternalStakeholder = true;
            this.allowTeamSelection = false;
            this.getCatchups$ = (fromDate?: Date, toDate?: Date, activeOnly?: boolean) =>
                this.catchupService.getCatchupsForPersonByDateRange(this.focusPerson!.personId, fromDate, toDate, activeOnly);
        }

        if (changes.teamId && this.teamId) {
            this.gridOptions.filterForTeamId = this.teamId;

            this.allowSelectPerson = true;
            this.showExternalStakeholder = true;
            this.showTeamRelationships = true;
            this.allowTeamSelection = false;
            this.getCatchups$ = (fromDate?: Date, toDate?: Date, activeOnly?: boolean) =>
                this.catchupService.getCatchupsForTeamByDateRange(this.teamId!, fromDate, toDate, activeOnly);

            const team = await lastValueFrom(this.teamsService.getTeamById(this.teamId));
            if (team) {
                this.teamMembers = await this.teamsService.promiseToGetAllTeamMembers(team);
            }
        }
    }
}
