import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Board, BoardBreezeModel } from "@common/ADAPT.Common.Model/organisation/board";
import { Item, ItemBreezeModel } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemComment, ItemCommentBreezeModel } from "@common/ADAPT.Common.Model/organisation/item-comment";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Link, LinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/link";
import { ObjectiveCode } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ServiceUri } from "@common/configuration/service-uri";
import { BreezePredicateUtilities } from "@common/lib/data/breeze-predicate-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import moment from "moment";
import { EMPTY, forkJoin, from, lastValueFrom, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { MeetingsService } from "../meetings/meetings.service";
import { AfterOrganisationInitialisationObservable } from "../organisation/after-organisation-initialisation.decorator";
import { CommonTeamsService } from "../teams/common-teams.service";
import { IEditItemDialogOptions } from "./items/edit-item-dialog/edit-item-dialog.component";
import { IFilterParameterAdditionalOptions, ItemFilterParameterRequestBuilder } from "./items/item-filter-parameter-request-builder";
import { IItemFilterParameters } from "./items/item-filter-parameters.interface";
import { ItemUtilities } from "./items/item-utilities";
import { KanbanAuthService } from "./kanban-auth.service";

// we don't handle any options other than these
type IDefaultItemOptions = Partial<Pick<Item, "assignee" | "status">>

export interface ICreateItemOptions extends Partial<IEditItemDialogOptions> {
    itemOptions?: IDefaultItemOptions;
    skipMeetingAgendaItemLinkPrompt?: boolean;
}

export interface IItemStatusCount {
    status: ItemStatus;
    count: number;
    overdueCount: number;
}

export interface ITeamKanbanStats {
    statusCounts: IItemStatusCount[];

    newItemsLastWeekCount: number;
    newCommentsLastWeekCount: number;
}

export const KANBAN_RANK_INCREMENT = 1000;

@Injectable({
    providedIn: "root",
})
export class KanbanService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private httpClient: HttpClient,
        private userService: UserService,
        private dialogService: AdaptCommonDialogService,
        private authService: AuthorisationService,
        private kanbanAuthService: KanbanAuthService,
        private labellingService: LabellingService,
        private teamsService: CommonTeamsService,
        private meetingsService: MeetingsService,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [
            // prime as boards data is not very big anyway
            // - otherwise, access verifier will get board for each team when that's called during sidebar initialisation
            //   causing {number of teams}x requests to server and taking up the entire connection pool if there are many teams
            this.getAllBoards(),
        ];
    }

    public getBoardById(boardId: number) {
        return this.commonDataService.getById(BoardBreezeModel, boardId);
    }

    public getItemById(itemId: number) {
        return this.commonDataService.getById(ItemBreezeModel, itemId);
    }

    public getAllItemsDueThisMonth() {
        const fromDate = moment().startOf("month");
        const toDate = moment().endOf("month");
        return this.getItemsByPredicate(new MethodologyPredicate<Item>("dueDate", ">=", fromDate.toDate())
            .and(new MethodologyPredicate<Item>("dueDate", "<=", toDate.toDate()))
            .and(new MethodologyPredicate<Item>("status", "!=", ItemStatus.Closed)));
    }

    public getItemsByPredicate(predicate: MethodologyPredicate<Item>) {
        return this.commonDataService.getByPredicate(ItemBreezeModel, predicate);
    }

    public getAllBoards() {
        if (this.kanbanAuthService.currentPersonCanReadBoards()) {
            return this.commonDataService.getAll(BoardBreezeModel);
        } else {
            return of([]);
        }
    }

    public getAllAccessibleBoards() {
        if (this.kanbanAuthService.currentPersonCanReadBoards()) {
            const predicate = new MethodologyPredicate<Board>("isArchived", "==", false)
                .and(new MethodologyPredicate<Board>("personId", "!=", null)
                    .or(BreezePredicateUtilities.getIsActivePredicateByPath("team")));

            return this.commonDataService.getByPredicate(BoardBreezeModel, predicate).pipe(
                // the query didn't take into account team feature disable - so check it here
                // - was already broken in production, e.g. go to team config, disable actions, then to personal actions and it still allowed you to create action on disabled team board
                map((boards) => boards.filter((board) => this.kanbanAuthService.currentPersonCanViewBoard(board))),
            );
        } else {
            return of([]);
        }
    }

    public createBoardForTeam(team: Team) {
        const boardDefaults = {
            organisationId: team.organisationId,
            ordinal: team.boards.length,
            teamId: team.teamId,
        };

        return this.commonDataService.create(BoardBreezeModel, boardDefaults);
    }

    public createBoardForPerson(person: Person) {
        return this.currentOrganisation$.pipe(
            switchMap((currentOrg) => {
                const boardDefaults = {
                    organisationId: currentOrg.organisationId,
                    ordinal: person.boards.length,
                    personId: person.personId,
                };

                return this.commonDataService.create(BoardBreezeModel, boardDefaults);
            }),
        );
    }

    public getBoardsByPerson(personId: number, forceRemote?: boolean) {
        const predicate = new MethodologyPredicate<Board>("personId", "==", personId);

        return this.commonDataService.getByPredicate(BoardBreezeModel, predicate, forceRemote);
    }

    @AfterOrganisationInitialisationObservable
    public getBoardsByTeam(teamId: number, forceRemote?: boolean) {
        if (this.kanbanAuthService.currentPersonCanReadBoards()) {
            const predicate = new MethodologyPredicate<Board>("teamId", "==", teamId);

            return this.commonDataService.getByPredicate(BoardBreezeModel, predicate, forceRemote);
        } else {
            return of([]);
        }
    }

    public hasAnyEditableBoard() {
        return this.getAllEditableBoards().pipe(
            map((boards) => boards.length > 0),
        );
    }

    /**
     * Gets all boards that the current user has access to edit
     * @returns {array} - a promise to get all the active boards
     */
    public getAllEditableBoards() {
        if (this.authService.currentPerson && this.kanbanAuthService.personCanReadBoards(this.authService.currentPerson)) {
            return this.kanbanAuthService.canEditAllStewardship().pipe(
                switchMap((canEditAllStewardship) => canEditAllStewardship
                    ? this.getAllAccessibleBoards()
                    : this.getEditableBoards()),
            );
        } else { // empty array if no permission instead of reject
            return of([]);
        }
    }

    // This was previously in stewardship-data.service where it gets team boards of all teams the current person is in
    // (and then merge with all public writable and personal boards) - only used by getAllEditableBoards above
    private getEditableBoards() {
        return this.initialisation$.pipe(
            take(1),
            switchMap(() => this.teamsService.promiseToGetActiveTeamsForCurrentPerson()),
            switchMap((activeTeams: Team[]) => activeTeams?.length
                ? forkJoin(activeTeams.map((team) => {
                    const predicate = new MethodologyPredicate<Board>("teamId", "==", team.teamId)
                        .and(new MethodologyPredicate<Board>("isArchived", "==", false));
                    return this.commonDataService.getByPredicate(BoardBreezeModel, predicate);
                }))
                : of([])),
            map((boardsArray) => ([] as Board[]).concat(...boardsArray)), // arrays of boards in each team this person is in -> single array
            switchMap((boardsInAllActiveTeams) => {
                const predicate = new MethodologyPredicate<Board>("isPublicWriteAccess", "==", "true");
                // The backend will not allow us to view other people's boards so this predicate is sufficient
                predicate.or(new MethodologyPredicate<Board>("teamId", "==", null));

                return this.commonDataService.getByPredicate(BoardBreezeModel, predicate).pipe(
                    map((personalOrPublicWriteBoards) => ArrayUtilities.distinct(boardsInAllActiveTeams.concat(personalOrPublicWriteBoards))),
                );
            }),
            // need this extra filter as no all members will have edit permission for team boards or public edit, e.g. participants
            map((boards) => boards.map((board) => ({
                board,
                canEdit: this.kanbanAuthService.currentPersonCanEditBoard(board),
            }))
                .filter((boardCanEdit) => boardCanEdit.canEdit) // only returning all boards you can edit
                .map((boardCanEdit) => boardCanEdit.board)),
        );
    }

    /**
     * (Moved from stewardship-data.service which is now removed)
     * Checks whether a board (other than the optionally provided existing id) with the provided item prefix exists
     * @param {string} itemPrefix The board item prefix
     * @param {int} existingBoardId The existing board id to exclude
     * @returns {Promise} A promise that resolves with the found item category
     */
    public getBoardByAbbreviation(itemPrefix: string, existingBoardId?: number) {
        const predicate = new MethodologyPredicate<Board>("itemPrefix", "==", itemPrefix);
        if (existingBoardId) {
            predicate.and(new MethodologyPredicate<Board>("boardId", "!=", existingBoardId));
        }

        return this.commonDataService.getByPredicate(BoardBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    // this is moved here from items service
    public getProposedKanbanItemRank(column: Item[], index: number) {
        const item = column[index];
        if (!item) {
            return 0;
        }

        const nextItem = index < column.length - 1
            ? column[index + 1]
            : null; // get next item if not already the last item
        const previousItem = index > 0
            ? column[index - 1]
            : null; // get previous item if not already the first item

        if (nextItem) {
            if (item.rank > nextItem.rank) {
                // rank is too high, propose existing ceiling
                return nextItem.rank;
            }

            if (previousItem) {
                if (item.rank < previousItem.rank) {
                    // rank is too low, propose existing ceiling
                    return nextItem.rank;
                }
            }

            // rank is less than next item and there is no previous, so just right
            return item.rank;
        }

        if (!previousItem) {
            // there is no previous or next item, so just right
            return item.rank;
        }

        if (item.rank < previousItem.rank) {
            // rank is less than the previous item, propose new ceiling
            return previousItem.rank + 1;
        }

        // rank is higher than the previous item and no next item, so just right
        return item.rank;
    }

    public getItemsByItemIds(itemIds: number[]) {
        const predicate = new MethodologyPredicate<Item>("itemId", "in", ArrayUtilities.distinct(itemIds));

        return itemIds.length ? this.getItemsByPredicate(predicate) : of([]);
    }

    public getItemsByBoardId(boardId: number) {
        const predicate = new MethodologyPredicate<Item>("boardId", "==", boardId);

        return this.getItemsByPredicate(predicate);
    }

    // this is only called from kanban page - so primed according to what it needs
    public getItemsByTeamId(teamId: number) {
        // prime boards first - otherwise local query below will fail trying process predicate with board.teamId
        return this.commonDataService.getAll(BoardBreezeModel).pipe(
            switchMap(() => {
                const predicate = new MethodologyPredicate<Item>("board.teamId", "==", teamId);
                predicate.and(new MethodologyPredicate<Item>("status", "!=", ItemStatus.Closed));
                predicate.and(new MethodologyPredicate<Item>("board.isArchived", "==", false));

                const key = `itemsForTeamId${predicate.getKey()}`;
                return this.commonDataService.getWithOptions(ItemBreezeModel, key, {
                    predicate,
                    navProperty: "comments",
                    orderBy: ItemBreezeModel.orderBy,
                });
            }),
            // prime item & objective links - which is going to be shown in the kanban card
            switchMap((items) => this.getItemLinksForTeamId(teamId).pipe(
                map(() => items),
            )),
            // prime labels
            switchMap((items) => this.labellingService.getLabelLocationsByPredicate(new MethodologyPredicate<LabelLocation>("item.board.teamId", "==", teamId)).pipe(
                map(() => items),
            )),
        );
    }

    // this is only called from kanban page only
    public getItemsByPersonId(personId: number) {
        const predicate = new MethodologyPredicate<Item>("assigneeId", "==", personId);
        predicate.and(new MethodologyPredicate<Item>("status", "!=", ItemStatus.Closed));
        predicate.and(new MethodologyPredicate<Item>("board.isArchived", "==", false));

        const key = `itemsForPersonId${predicate.getKey()}`;
        return this.commonDataService.getWithOptions(ItemBreezeModel, key, {
            predicate,
            navProperty: "comments",
            orderBy: ItemBreezeModel.orderBy,
        }).pipe(
            // prime item and objective links
            switchMap((items) => this.getItemLinksForPersonId(personId).pipe(
                map(() => items),
            )),
            // prime labels
            switchMap((items) => this.labellingService.getLabelLocationsByPredicate(new MethodologyPredicate<LabelLocation>("item.assigneeId", "==", personId)).pipe(
                map(() => items),
            )),
        );
    }

    /**
     * Get an item by its category and ID
     * @param itemPrefix (e.g. NAV)
     * @param id the item ID
     * @returns the item
     */
    public getItemByBoardAndId(itemPrefix: string, id: number) {
        const predicate = this.getItemByBoardAndIdPredicate(itemPrefix, id);

        return this.getItemsByPredicate(predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    private getItemByBoardAndIdPredicate(itemPrefix: string, id: number) {
        return new MethodologyPredicate<Item>("boardIndex", "==", id)
            .and(new MethodologyPredicate<Item>("board.itemPrefix", "==", itemPrefix));
    }

    /**
     * Gets an item by it's code: e.g. NAV-1 as defined in the itemModel.
     * @param itemCodeOrId The item code or item ID
     * @return Observable that resolves with the item, or undefined if not found.
     */
    public getItemByCodeOrId(itemCodeOrId: string) {
        const itemCodeCandidate = ItemUtilities.getItemCodeBreakDown(itemCodeOrId);
        if (itemCodeCandidate.boardAbbreviation && itemCodeCandidate.boardIndex) {
            // no items can have AO/QO board abbreviation anymore due to objective numbering
            if (itemCodeCandidate.boardAbbreviation === ObjectiveCode.AO || itemCodeCandidate.boardAbbreviation == ObjectiveCode.QO) {
                return of(undefined);
            }

            return this.getItemByBoardAndId(itemCodeCandidate.boardAbbreviation, itemCodeCandidate.boardIndex);
        } else if (itemCodeCandidate.boardIndex) {
            return this.getItemById(itemCodeCandidate.boardIndex).pipe(
                switchMap((item) => {
                    if (item?.boardId && !item.board) {
                        // board not primed -> do it
                        return this.getBoardById(item.boardId).pipe(
                            map(() => item),
                        );
                    } else {
                        return of(item);
                    }
                }),
            );
        }

        return of(undefined);
    }

    private getItemComments(item: Item) {
        const predicate = new MethodologyPredicate<ItemComment>("itemId", "==", item.itemId);

        return this.commonDataService.getByPredicate(ItemCommentBreezeModel, predicate);
    }

    private getItemLinksForTeamId(teamId: number) {
        const primaryItemPredicate = new MethodologyPredicate<Link>("item.board.teamId", "==", teamId);
        return this.getPrimedItemLinksByPredicate(primaryItemPredicate, `itemLinksForTeamId${teamId}`);
    }

    private getItemLinksForPersonId(personId: number) {
        const predicate = new MethodologyPredicate<Link>("item.assigneeId", "==", personId);

        return this.getPrimedItemLinksByPredicate(predicate, `itemLinksForPersonId${personId}`);
    }

    private getPrimedItemLinksByPredicate(predicate: MethodologyPredicate<Link>, key: string) {
        predicate.and(new MethodologyPredicate<Link>("item.status", "!=", ItemStatus.Closed));

        return this.commonDataService.getWithOptions(LinkBreezeModel, key, {
            predicate,
            navProperty: "objective1",
        }).pipe(
            switchMap((links) => {
                const itemsToFetch = ArrayUtilities.distinct(links
                    .filter((l) => (l.itemId && !l.item) || (l.linkedItemId && !l.linkedItem))
                    .flatMap((l) => [l.itemId, l.linkedItemId]))
                    .filter((id) => id != null);
                return this.getItemsByItemIds(itemsToFetch).pipe(
                    map(() => links),
                );
            }),
        );
    }

    public getItemsByFilterParameters(
        filterParameters: IItemFilterParameters,
        additionalOptions?: IFilterParameterAdditionalOptions,
    ) {
        return from(this.authService.promiseToGetHasAccess(KanbanAuthService.ViewAnyBoard)).pipe(
            switchMap((hasPermission) => {
                if (!hasPermission) {
                    return of([]);
                }

                const initialTop = additionalOptions?.top;
                if (additionalOptions?.top && filterParameters.excludedItemIds) {
                    additionalOptions.top += filterParameters.excludedItemIds.length;
                }

                const settings = ItemFilterParameterRequestBuilder.builder()
                    .forModel(ItemBreezeModel)
                    .withFilterParameters(filterParameters)
                    .useStartsWith()
                    .withAdditionalOptions(additionalOptions)
                    .build();

                if (initialTop) {
                    // restore back after already used to build  settings
                    additionalOptions.top = initialTop;
                }

                const itemPromises = settings.map((s) => this.commonDataService.getWithOptions(ItemBreezeModel,
                    s.requestKey,
                    s.options,
                ));

                return itemPromises.length > 0
                    ? forkJoin(itemPromises).pipe(map(ArrayUtilities.mergeArrays))
                    : of([]);
            }),
            switchMap(async (items: Item[]) => {
                const hasPersonalKanban = await lastValueFrom(this.kanbanAuthService.canEditPersonalStewardshipKanban());
                if (hasPersonalKanban) {
                    return items;
                }

                // filter for non-personal board items
                return items.filter((i) => !(i.board && i.board.personId));
            }),
            map((items) => items
                .filter((item) => !(filterParameters.excludedItemIds ?? []).some((id) => id === item.itemId))
                .slice(0, additionalOptions?.top)), // items not necessarily contains all excluded items
        );
    }

    public getKanbanStatusCounts(organisationId: number, teamId: number) {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/TeamKanbanStats`;
        const params = new HttpParams()
            .set("organisationId", organisationId.toString())
            .set("teamId", teamId.toString());

        return this.httpClient.get<ITeamKanbanStats>(uri, { params });
    }

    public primeRelatedItemData(item: Item) {
        if (item.itemId <= 0 || item.entityAspect.entityState.isAdded() || item.entityAspect.entityState.isDetached()) {
            return of(undefined);
        } else {
            return this.getItemComments(item).pipe(
                switchMap(() => this.meetingsService.getLinksForItem(item.itemId)),
                map(() => undefined),  // make it clearer that the emitted value is not going to be used for anything
            );
        }
    }

    public createItem(board?: Board, options?: IDefaultItemOptions) {
        return forkJoin({
            organisation: this.currentOrganisation$,
            editableBoards: this.getAllEditableBoards(),
        }).pipe(
            switchMap(({ organisation, editableBoards }) => {
                const boards = editableBoards.sort(SortUtilities.getSortByFieldFunction("personId", "teamId", "ordinal"));

                const defaults = {
                    organisationId: organisation.organisationId,
                    createdDateTime: new Date(),
                    createdById: this.userService.getCurrentPersonId(),
                    status: ItemStatus.ToDo, // Default to ToDo rather than backlog as we can also create items outside of kanban view, e.g. meeting,
                    // dashboard
                    board: board
                        ? board
                        : boards[0],
                    visible: true,
                    assignee: options?.assignee,
                };

                if (options?.status) {
                    defaults.status = options.status;
                }

                return this.commonDataService.create(ItemBreezeModel, defaults);
            }),
        );
    }

    public createItemToItemLink(item: Item, linkedItem: Item) {
        const defaults: Partial<Link> = {
            item,
            linkedItem,
            organisationId: this.organisationId,
        };

        return this.commonDataService.create(LinkBreezeModel, defaults);
    }

    public createItemLink(item: Item, link: Link) {
        const defaults: Partial<Link> = {
            item,
            linkedItemId: link.linkedItemId,
            objective1Id: link.objective1Id,
            objective2Id: link.objective2Id,
            meetingId: link.meetingId,
            meetingAgendaItemId: link.meetingAgendaItemId,
            speedCatchupId: link.speedCatchupId,
            careerValuationId: link.careerValuationId,
            organisationId: this.organisationId,
        };

        return this.commonDataService.create(LinkBreezeModel, defaults);
    }

    public createItemComment(item: Item, person?: Person) {
        const defaults = {
            item,
            addedById: person ? person.personId : this.userService.getCurrentPersonId(),
            dateTime: new Date(),
        };

        return this.commonDataService.create(ItemCommentBreezeModel, defaults);
    }

    public cloneAndSaveItem(item: Item) {
        const getItemEntities = (newItem: Item) => [newItem, ...newItem.links, ...newItem.comments];

        // store the new item externally so we can revert changes upon failure
        let clonedItem: Item;

        if (!item.entityAspect.validateEntity()) {
            const errors = item.entityAspect.getValidationErrors();
            return this.dialogService.showErrorDialog("Error duplicating item", errors.map((v) => v.errorMessage).join(", "));
        }

        return this.commonDataService.create(ItemBreezeModel, {
            organisation: item.organisation,
            board: item.board,
            summary: "Copy of " + item.summary,
            description: item.description,
            assignee: item.assignee,
            status: item.status,
            dueDate: item.dueDate,
            createdById: this.userService.getCurrentPersonId(),
            createdDateTime: new Date(),
        }).pipe(
            switchMap((newItem: Item) => this.cloneItemComments(item, newItem)),
            emptyIfUndefinedOrNull(),
            switchMap((newItem: Item) => this.cloneItemLinks(item, newItem)),
            switchMap((newItem) => {
                clonedItem = newItem;
                return this.commonDataService.saveEntities([...getItemEntities(newItem)]).pipe(
                    map(() => newItem),
                );
            }),
            catchError((err: AdaptError) => this.dialogService.showMessageDialog("Error duplicating item", err.message)
                .pipe(
                    switchMap(() => this.commonDataService.rejectChanges(getItemEntities(clonedItem))),
                    switchMap(() => EMPTY), // won't be triggering next for error
                ),
            ),
        );
    }

    private cloneItemComments(item: Item, dest: Item) {
        if (!item.comments) {
            return of(dest);
        }

        const existingComments = item.comments.filter((comment) => !comment.entityAspect.entityState.isAdded());
        if (!existingComments || !existingComments.length) {
            return of(dest);
        }

        return forkJoin(existingComments.map((comment) =>
            this.createItemComment(dest, comment.addedBy)),
        ).pipe(
            map((comments) => {
                comments.forEach((itemComment, idx) => {
                    itemComment.comment = existingComments[idx].comment;
                    itemComment.dateTime = existingComments[idx].dateTime;
                });
                return dest;
            }),
        );
    }

    private cloneItemLinks(item: Item, dest: Item) {
        if (!item.links || !item.links.length) {
            return of(dest);
        }

        return forkJoin(item.links.map((link) =>
            this.createItemLink(dest, link)),
        ).pipe(
            map(() => dest),
        );
    }
}
