import { Injectable, Injector } from "@angular/core";
import { Link, LinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/link";
import { SpeedCatchup, SpeedCatchupBreezeModel } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { SpeedCatchupConfiguration, SpeedCatchupConfigurationBreezeModel } from "@common/ADAPT.Common.Model/organisation/speed-catchup-configuration";
import { SpeedCatchupRating, SpeedCatchupRatingBreezeModel } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import moment from "moment/moment";
import { Observable, of, Subject } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { AfterOrganisationInitialisationObservable } from "../organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { CommonTeamsService } from "../teams/common-teams.service";

export interface ICatchupQueryOptions {
    /**
     * When querying catchups, only get the latest of all catchups between two people.
     * When false/undefined, will return every Team catchup between the two people
     * e.g. One from team A, one from Team B, one from All Teams
     * When true, will only return the latest of the above three.
     */
    consolidateTeamCatchups?: boolean;
}

@Injectable({
    providedIn: "root",
})
export class PeerCatchupService extends BaseOrganisationService {
    private catchupChangedNotificationSubject = new Subject<SpeedCatchup>();
    private _catchupConfiguration?: SpeedCatchupConfiguration;

    public constructor(
        private teamsService: CommonTeamsService,
        injector: Injector,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [
            this.initialiseService(),
        ];
    }

    public getCatchupById = (catchupId: number) => this.commonDataService.getById(SpeedCatchupBreezeModel, catchupId);
    public createRating = (createData: Partial<SpeedCatchupRating>) => this.commonDataService.create(SpeedCatchupRatingBreezeModel, createData);

    public get catchupChange$() {
        return this.catchupChangedNotificationSubject.asObservable();
    }

    public emitCatchupChange(catchup: SpeedCatchup) {
        this.catchupChangedNotificationSubject.next(catchup);
    }

    @AfterOrganisationInitialisationObservable
    public getAllCatchupsBetweenPeople(person1Id: number, person2Id: number) {
        const predicate1 = new MethodologyPredicate<SpeedCatchup>("person1Id", "==", person1Id);
        const predicate2 = new MethodologyPredicate<SpeedCatchup>("person1Id", "==", person2Id);
        const predicate = new MethodologyPredicate<SpeedCatchup>();

        predicate1.and(new MethodologyPredicate<SpeedCatchup>("person2Id", "==", person2Id));
        predicate2.and(new MethodologyPredicate<SpeedCatchup>("person2Id", "==", person1Id));

        predicate
            .or(predicate1)
            .or(predicate2);

        return this.commonDataService.getByPredicate(SpeedCatchupBreezeModel, predicate);
    }

    @AfterOrganisationInitialisationObservable
    public getCatchupFrequency() {
        return of(this._catchupConfiguration!.catchupFrequency);
    }

    @AfterOrganisationInitialisationObservable
    public getCatchupConfiguration() {
        return of(this._catchupConfiguration);
    }

    public calculateNextCatchupDate(creationDate: Date) {
        return moment(creationDate)
            .add(this._catchupConfiguration?.catchupFrequency, "months");
    }

    public initialiseService() {
        return this.commonDataService.getAll(SpeedCatchupConfigurationBreezeModel).pipe(
            map((configurations) => ArrayUtilities.getSingleFromArray(configurations)),
            tap((configuration) => this._catchupConfiguration = configuration),
        );
    }

    public createNewCatchup(person1Id: number, person2Id?: number, teamId?: number) {
        return this.currentOrganisation$.pipe(
            switchMap((org) => {
                const newCatchup = {
                    organisationId: org.organisationId,
                    creationDate: new Date(),
                    person1Id,
                    person2Id,
                    teamId,
                } as Partial<SpeedCatchup>;
                return this.commonDataService.create(SpeedCatchupBreezeModel, newCatchup);
            }),
        );
    }

    @AfterOrganisationInitialisationObservable
    public getCatchupsByDateRange(fromDate?: Date, toDate?: Date, activeOnly = true) {
        const dateRangePredicate = this.createDateRangePredicate(fromDate, toDate);

        if (dateRangePredicate) {
            return this.commonDataService.getByPredicate(SpeedCatchupBreezeModel, dateRangePredicate).pipe(
                switchMap((catchups) => this.primeActivePeople(catchups)),
            );
        } else {
            return this.getLatestCatchups({}, activeOnly);
        }
    }

    private createDateRangePredicate(fromDate?: Date, toDate?: Date) {
        let dateRangePredicate: MethodologyPredicate<SpeedCatchup> | undefined;

        if (fromDate) {
            dateRangePredicate = new MethodologyPredicate<SpeedCatchup>("creationDate", ">=", fromDate);
        }

        if (toDate) {
            if (dateRangePredicate) {
                dateRangePredicate.and(new MethodologyPredicate<SpeedCatchup>("creationDate", "<=", toDate));
            } else {
                dateRangePredicate = new MethodologyPredicate<SpeedCatchup>("creationDate", "<=", toDate);
            }
        }

        return dateRangePredicate;
    }

    @AfterOrganisationInitialisationObservable
    public getLatestCatchups(options: ICatchupQueryOptions = {}, activeOnly = true) {
        const key = this.wrapKeyForOptions("latestSpeedCatchups", options);
        const queryOptions = this.generateQueryOptionsForOptions(options);
        if (!queryOptions.namedParams) {
            queryOptions.namedParams = {};
        }

        queryOptions.namedParams.activeOnly = activeOnly;

        return this.commonDataService.getWithOptions(SpeedCatchupBreezeModel, key, queryOptions).pipe(
            switchMap((catchups) => this.primeActivePeople(catchups)),
        );
    }

    @AfterOrganisationInitialisationObservable
    public getLinksForCatchup(catchupId: number) {
        const predicate = new MethodologyPredicate<Link>("speedCatchupId", "==", catchupId);
        const key = `LinksForCatchupId${catchupId}`;
        return this.commonDataService.getWithOptions(LinkBreezeModel, key, {
            predicate: predicate,
            navProperty: "item",
        });
    }

    @AfterOrganisationInitialisationObservable
    public getCatchupsForPersonByDateRange(personId: number, fromDate?: Date, toDate?: Date, activeOnly = true) {
        const dateRangePredicate = this.createDateRangePredicate(fromDate, toDate);
        if (dateRangePredicate) {
            const personPredicate = new MethodologyPredicate<SpeedCatchup>("person1Id", "==", personId);
            personPredicate.or(new MethodologyPredicate<SpeedCatchup>("person2Id", "==", personId));

            return this.commonDataService.getByPredicate(SpeedCatchupBreezeModel, dateRangePredicate.and(personPredicate)).pipe(
                switchMap((catchups) => this.primeActivePeople(catchups)),
            );
        } else {
            return this.getLatestCatchupsForPerson(personId, {}, activeOnly);
        }
    }

    @AfterOrganisationInitialisationObservable
    public getCatchupsForTeamByDateRange(teamId: number, fromDate?: Date, toDate?: Date, activeOnly = true) {
        const dateRangePredicate = this.createDateRangePredicate(fromDate, toDate);
        if (!dateRangePredicate) {
            return this.getLatestCatchupsForTeam(teamId);
        }

        return this.teamsService.getTeamById(teamId).pipe(
            filter((team) => !!team),
            // will have to include other inactive team members too as there can be filter to include inactive team members
            switchMap((team) => this.teamsService.promiseToGetTeamMemberRoleConnections(team!, false)),
            map((roleConnections) => roleConnections.map((i) => i.connection.personId)),
            switchMap((personIds) => {
                // this is previously from speed-catchup-data.service
                // note: have to do client side filtering here as even if we use 'in' operator for breeze,
                //  the actual query through the wire is expanded to 'or' each of the personIds.
                //  With a team of 20 people, it will hit the limit and result in error:
                //      The node count limit of '100' has been exceeded...
                //  from breeze.
                // Otherwise, I would have just used person1Id in personIds and person2Id in personIds.
                const fromKey = fromDate
                    ? "from:" + fromDate.toISOString()
                    : "";
                const toKey = toDate
                    ? "to:" + toDate.toISOString()
                    : "";
                const key = `latestSpeedCatchupsForPeopleInTeam:${teamId}${fromKey}${toKey}`;
                const options: IBreezeQueryOptions = {
                    predicate: dateRangePredicate,
                    namedParams: {
                        latestOnly: true,
                        activeOnly,
                        teamId,
                    },
                    customFilterFunction: this.filterCatchupsForTeamFunction(teamId, personIds),
                };

                return this.commonDataService.getWithOptions(SpeedCatchupBreezeModel, key, options) as Observable<SpeedCatchup[]>;
            }),
        );
    }

    @AfterOrganisationInitialisationObservable
    public getLatestCatchupsForPerson(personId: number, options: ICatchupQueryOptions = {}, activeOnly: boolean = true) {
        // TODO: enable inclusion of inactive people as an option
        const key = this.wrapKeyForOptions("latestSpeedCatchupsForPersonId" + personId, options);
        const queryOptions = this.generateQueryOptionsForOptions(options, activeOnly);
        queryOptions.predicate = new MethodologyPredicate<SpeedCatchup>("person1Id", "==", personId)
            .or(new MethodologyPredicate<SpeedCatchup>("person2Id", "==", personId));

        return this.commonDataService.getWithOptions(SpeedCatchupBreezeModel, key, queryOptions);
    }

    @AfterOrganisationInitialisationObservable
    public getLatestCatchupsForTeam(teamId: number, options: ICatchupQueryOptions = {}) {
        return this.teamsService.getTeamById(teamId).pipe(
            filter((team) => !!team),
            switchMap((team) => this.teamsService.promiseToGetTeamMemberRoleConnections(team!, true)),
            map((roleConnections) => roleConnections.map((i) => i.connection.personId)),
            switchMap((personIds) => {
                const key = this.wrapKeyForOptions(`latestSpeedCatchupsForPeopleInTeam:${teamId}`, options);
                const queryOptions = this.generateQueryOptionsForOptions(options);

                queryOptions.customFilterFunction = this.filterCatchupsForTeamFunction(teamId, personIds);
                queryOptions.namedParams!.teamId = teamId;

                return this.commonDataService.getWithOptions(SpeedCatchupBreezeModel, key, queryOptions) as Observable<SpeedCatchup[]>;
            }),
        );
    }

    private async primeActivePeople(catchups: SpeedCatchup[]) {
        await new PeopleQueryUtilities(this.commonDataService).promiseToGetActivePeople();
        return catchups;
    }

    private generateQueryOptionsForOptions(options: ICatchupQueryOptions, activeOnly = true) {
        const queryOptions: IBreezeQueryOptions = {
            namedParams: {
                latestOnly: true,
                activeOnly,
            },
        };

        if (options.consolidateTeamCatchups) {
            queryOptions.latestGroupByField = "person1Id,person2Id";
            queryOptions.latestGroupByGenerateKeyFunction = (scu: any) => scu.person1Id < scu.person2Id
                ? [scu.person1Id, scu.person2Id]
                : [scu.person2Id, scu.person1Id];
        }

        return queryOptions;
    }

    private wrapKeyForOptions(key: string, options: ICatchupQueryOptions) {
        if (options.consolidateTeamCatchups) {
            key = "[consolidated]" + key;
        }

        return key;
    }

    private filterCatchupsForTeamFunction(teamId: number, teamMemberPersonIds: number[]) {
        return (catchups: SpeedCatchup[]) => catchups.filter((catchup) =>
            teamMemberPersonIds.indexOf(catchup.person1Id) >= 0 && teamMemberPersonIds.indexOf(catchup.person2Id) >= 0 &&
            (!catchup.teamId || catchup.teamId === teamId));
    }
}
