import { Component, ElementRef } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategory } from "@common/ADAPT.Common.Model/organisation/career-valuation-category";
import { CareerValuationCategoryValue } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { OrgCommonCareerValuationUiService } from "@org-common/lib/career-valuation/org-common-career-valuation-ui.service";
import dxPolarChart, { dxPolarChartCommonSeriesSettings, InitializedEvent, PointClickEvent } from "devextreme/viz/polar_chart";
import { CareerValuationService, ICareerValuationDifferential } from "../career-valuation.service";
import { ICareerValuationAnalysisFilter } from "../career-valuation-analysis-filter";
import { ICareerValuationChartTooltipOptions } from "../career-valuation-chart-tooltip-options.interface";
import { CareerValuationFilterService } from "../career-valuation-filter/career-valuation-filter.service";
import { CareerValuationUiService } from "../career-valuation-ui.service";
import { CareerValuationHistoryDialogComponent } from "../cvt-history-dialog/cvt-history-dialog.component";

@Component({
    selector: "adapt-cvt-differentials-chart",
    templateUrl: "./analyse-cvt-differentials-chart.component.html",
})
export class CareerValuationDifferentialsChartComponent extends BaseComponent {
    public chart?: dxPolarChart;

    public filterParameters?: ICareerValuationAnalysisFilter;

    public palette = OrgCommonCareerValuationUiService.CareerValuationPaletteName;

    public data: ICareerValuationDifferential[] = [];
    public series: dxPolarChartCommonSeriesSettings[] = [];

    public isLoading = true;

    public categories?: CareerValuationCategory[];

    public constructor(
        public elementRef: ElementRef,
        public responsiveService: ResponsiveService,
        private careerValuationService: CareerValuationService,
        private careerValuationUiService: CareerValuationUiService,
        public filterService: CareerValuationFilterService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(elementRef);

        this.filterService.filterListener.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((value) => {
            this.filterParameters = value;
            this.updateDataAndChart();
        });
    }

    public onInitialized(e: InitializedEvent) {
        this.chart = e.component;

        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    public get height() {
        return this.responsiveService.currentBreakpoint.isMobileSize ? 300 : 600;
    }

    public get isLegendVisible() {
        return this.responsiveService.currentBreakpoint.isDesktopSize;
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        const categoryValue: CareerValuationCategoryValue = info.point.tag;
        const tooltipOptions: ICareerValuationChartTooltipOptions = {
            categoryName: categoryValue.category.name,
            showName: true,
            notesColumnVisible: true,
        };

        return {
            html: this.careerValuationUiService.getChartDataTooltip(categoryValue.careerValuation, tooltipOptions),
        };
    }

    @Autobind
    public onPointClick(info: PointClickEvent) {
        info.component.hideTooltip();
        const careerValuation: CareerValuation = info.target!.tag.careerValuation;

        if (!careerValuation.person) {
            return;
        }

        this.dialogService.open(CareerValuationHistoryDialogComponent, careerValuation.person).subscribe();
    }

    @Autobind
    private async updateDataAndChart() {
        if (this.filterParameters) {
            if (!ChartUtils.isChartDisposed(this.chart)) {
                this.chart?.showLoadingIndicator();
            }

            const data = await this.careerValuationService.promiseToGetCareerValuationAnalysisData(this.filterParameters);
            const differentials = this.careerValuationService.getLatestCareerValuationDifferentials(data, this.filterParameters);
            this.data = differentials;

            this.categories = await this.careerValuationService.promiseToGetCareerValuationCategories();

            this.isLoading = false;
        }
    }
}
