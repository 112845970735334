import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { take } from "rxjs/operators";
import { SelectPersonComponent } from "../../directory-shared/select-person/select-person.component";
import { CommonTeamsService } from "../../teams/common-teams.service";

@Component({
    selector: "adapt-edit-catchup-people",
    templateUrl: "./edit-catchup-people.component.html",
    styleUrls: ["./edit-catchup-people.component.scss"],
})
export class EditCatchupPeopleComponent extends BaseComponent implements OnInit {
    @Input() public catchup!: SpeedCatchup;
    @Output() public validationChange = new EventEmitter<boolean>();

    @ViewChildren(SelectPersonComponent) public selectPersonComponents?: QueryList<SelectPersonComponent>;

    public showFacilitatorEntry = false;
    public selectedTeamIsValid = false;
    public isValid = false;
    public invalidMessage = "";

    public currentPerson?: Person;

    private selectedTeam?: Team;
    private selectedTeamMembersWithPcuEditPermission?: Person[];

    public constructor(
        userService: UserService,
        private commonTeamsService: CommonTeamsService,
        private teamsService: CommonTeamsService,
    ) {
        super();

        userService.currentPerson$.pipe(
            take(1),
            this.takeUntilDestroyed(),
        ).subscribe((person) => this.currentPerson = person);
    }

    public ngOnInit() {
        if (!this.catchup) {
            throw new Error("Expected Peer Catch-up object to be passed in here!");
        }

        this.updateSelectedTeam(this.catchup.team);
    }

    public async updateSelectedTeam(team?: Team) {
        this.selectedTeam = team;

        if (team) {
            let teamMembers = await this.commonTeamsService.promiseToGetActiveTeamMembers(team);
            teamMembers = teamMembers.filter((person) => this.teamsService.personCanParticipateInTeamPcu(person, team));
            this.setSelectableTeamMembers(teamMembers);
        } else {
            this.setSelectableTeamMembers([]);
        }
    }

    private setSelectableTeamMembers(teamMembers: Person[]) {
        this.selectedTeamMembersWithPcuEditPermission = teamMembers;
        this.selectPersonComponents?.forEach((selector) => selector?.reload());
    }

    public setSelectedTeamIsValid(isValid: any) {
        this.selectedTeamIsValid = isValid;
        this.validatePeople();
    }

    @Autobind
    public isNotExistingParticipantAndIsTeamMember(person: Person) {
        return (!this.selectedTeam || !!this.selectedTeamMembersWithPcuEditPermission && this.selectedTeamMembersWithPcuEditPermission.indexOf(person) >= 0);
    }

    @Autobind
    public validatePeople() {
        const isValid = (this.catchup.person1 && this.catchup.person2)
            && (this.catchup.person1 !== this.catchup.person2)
            && (this.catchup.person1 !== this.catchup.facilitatorPerson)
            && (this.catchup.person2 !== this.catchup.facilitatorPerson)
            && this.selectedTeamIsValid;
        this.isValid = !!isValid;

        if (this.isValid) {
            this.isValid = this.currentPerson === this.catchup.person1
                || this.currentPerson === this.catchup.person2
                || this.currentPerson === this.catchup.facilitatorPerson;

            if (!this.isValid) {
                this.invalidMessage = `${this.currentPerson!.fullName} cannot record a Peer Catch-up between
                    ${this.catchup.person1!.fullName} and ${this.catchup.person2!.fullName}
                    without being a facilitator.`;
            }
        } else {
            if (!this.catchup.person1) {
                this.invalidMessage = "Please correct the Peer Catch-up participants.";
            } else if (!this.catchup.person2) {
                this.invalidMessage = "Please correct the Peer Catch-up participants.";
            } else if (!this.selectedTeamIsValid) {
                this.invalidMessage = "Please select team to determine who can view this catch-up.";
            }
        }

        this.validationChange.emit(this.isValid);
    }
}
