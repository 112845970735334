import { Component, Input } from "@angular/core";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { combineLatest, forkJoin, Subject } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { OrgCommonCatchupUiService } from "../org-common-catchup-ui.service";
import { OrgCommonPeerCatchupAuthService } from "../org-common-peer-catchup-auth.service";

@Component({
    selector: "adapt-display-missing-catchup",
    templateUrl: "./display-missing-catchup.component.html",
})
export class DisplayMissingCatchupComponent extends BaseComponent {
    @Input() public set person1Id(personId: number) {
        this.person1Id$.next(personId);
    }

    @Input() public set person2Id(personId: number) {
        this.person2Id$.next(personId);
    }

    @Input() public teamId?: number;

    public person1?: Person;
    public person2?: Person;
    public canEditPersonalCatchups = false;

    private person1Id$ = new Subject<number>();
    private person2Id$ = new Subject<number>();

    public constructor(
        commonDataService: CommonDataService,
        catchupAuthService: OrgCommonPeerCatchupAuthService,
        private catchupUiService: OrgCommonCatchupUiService,
    ) {
        super();

        catchupAuthService.canEditPersonalCatchups.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((canEdit) => this.canEditPersonalCatchups = canEdit);

        combineLatest([this.person1Id$, this.person2Id$]).pipe(
            switchMap(([person1Id, person2Id]) => forkJoin([
                commonDataService.getById(PersonBreezeModel, person1Id),
                commonDataService.getById(PersonBreezeModel, person2Id),
            ])),
            take(1),
            this.takeUntilDestroyed(),
        ).subscribe(([person1, person2]) => {
            this.person1 = person1;
            this.person2 = person2;
        });
    }

    public get bothPeopleActive() {
        return this.person1?.isActive() && this.person2?.isActive();
    }

    public recordNewCatchup() {
        return this.catchupUiService
            .recordNewCatchup(this.person1!.personId, this.person2!.personId, this.teamId)
            .subscribe();
    }
}
