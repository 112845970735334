import { Injectable, Injector } from "@angular/core";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { KeyResult, KeyResultBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValue, KeyResultValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { LabelLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Link, LinkBreezeModel } from "@common/ADAPT.Common.Model/organisation/link";
import { Objective, ObjectiveBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveComment, ObjectiveCommentBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { ObjectiveReviewBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective-review";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { ValidationError } from "breeze-client";
import moment from "moment";
import { EMPTY, forkJoin, lastValueFrom, Observable, of, Subject } from "rxjs";
import { catchError, first, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { LinkService } from "../link/link.service";
import { ScheduleService } from "../schedule/schedule.service";
import { CommonTeamsAuthService } from "../teams/common-teams-auth.service";
import { ObjectiveFilter } from "./objective-filter/objective-filter";
import { ObjectivesAuthService } from "./objectives-auth.service";

export interface IObjectiveGroup {
    objective: Objective;
    childGroups: IObjectiveGroup[];
}

export interface IObjectiveTeamGroup {
    team?: Team;
    objectives: Objective[];
}

@Injectable({
    providedIn: "root",
})
export class ObjectivesService extends BaseOrganisationService {
    public get keyResultValueUpdated$() {
        return this._keyResultValueUpdated$.asObservable();
    }

    public get objectiveUpdated$() {
        return this._objectiveUpdated$.asObservable();
    }

    private _keyResultValueUpdated$ = new Subject<KeyResultValue>();
    private _objectiveUpdated$ = new Subject<Objective>();

    public constructor(
        injector: Injector,
        private userService: UserService,
        private commonDialogService: AdaptCommonDialogService,
        private objectivesAuthService: ObjectivesAuthService,
        private labellingService: LabellingService,
        private scheduleService: ScheduleService,
        private authorisationService: AuthorisationService,
        private linkService: LinkService,
    ) {
        super(injector);
    }

    public getObjectiveById(objectiveId: number) {
        if (isNaN(objectiveId) || !objectiveId) {
            // This can be coming from dynamic-navigation.service for breadcrumb when people type random obj id
            // Error toaster from breeze query if not capturing it here
            return of(undefined);
        }

        return this.commonDataService.getById(ObjectiveBreezeModel, objectiveId);
    }

    public getObjectivesByIds(objectiveIds: number[]) {
        if (objectiveIds.length === 0) {
            return of([] as Objective[]);
        }

        return this.getObjectivesByPredicate(new MethodologyPredicate<Objective>("objectiveId", "in", objectiveIds));
    }

    public getObjectivesWithParentId(parentObjectiveId?: number) {
        return this.commonDataService.getByPredicate(ObjectiveBreezeModel,
            new MethodologyPredicate<Objective>("parentObjectiveId", "==", parentObjectiveId ?? null));
    }

    public async autoUpdateDueDate(objective?: Objective) {
        if (!objective?.entityAspect.entityState.isAdded()) {
            return false;
        }

        const canViewMeeting = await this.authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.ViewAnyTeamMeeting);
        switch (objective.type) {
            case ObjectiveType.Annual:
                if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto && canViewMeeting) {
                    const cadenceEventCycle = await lastValueFrom(this.scheduleService.getEventCadenceCycle());
                    if (cadenceEventCycle) {
                        const annualSeries = await lastValueFrom(this.scheduleService.getLatestMeetingAndSeriesForEventTypePreset(EventTypePreset.AnnualStrategy));
                        objective.dueDate = annualSeries?.meeting && moment().isBefore(annualSeries.meeting.meetingDateTime)
                            ? annualSeries.meeting.meetingDateTime
                            : cadenceEventCycle.extensions.nextCycleStartDate;

                        return true;
                    }
                }

                objective.dueDate = moment().add(1, "year").toDate();
                break;
            case ObjectiveType.Quarterly:
                if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto && canViewMeeting) {
                    const quarterlyEvent = await lastValueFrom(this.scheduleService.getLatestMeetingAndSeriesForEventTypePreset(EventTypePreset.QuarterlyStrategy));
                    if (quarterlyEvent?.series && quarterlyEvent.meeting) {
                        objective.dueDate = moment().isAfter(quarterlyEvent.meeting.meetingDateTime)
                            ? quarterlyEvent.series.extensions.getNextMeeting(quarterlyEvent.meeting)?.meetingDateTime
                            ?? quarterlyEvent.series.endDate
                            : quarterlyEvent.meeting.meetingDateTime;

                        return true;
                    }
                }

                objective.dueDate = moment().add(3, "months").toDate();
                break;
            default:
                throw new Error("Unknown Objective type");
        }

        return false;
    }


    public getKeyResultsForObjectiveId(objectiveId: number) {
        const key = `getKeyResultsForObjectiveId${objectiveId}`;
        return this.commonDataService.getWithOptions(KeyResultBreezeModel, key, {
            predicate: new MethodologyPredicate<KeyResult>("objectiveId", "==", objectiveId),
            navProperty: "values.person",
        });
    }

    public getKeyResultsForObjectives(objectives: Objective[]) {
        const ids = objectives.map((i) => i.objectiveId);
        return this.getKeyResultsForObjectiveIds(ids);
    }

    public getKeyResultsForObjectiveIds(objectiveIds: number[]) {
        if (objectiveIds.length === 0) {
            return of([] as KeyResult[]);
        }

        return this.commonDataService.getWithOptions(
            KeyResultBreezeModel,
            `getKeyResultsForObjectiveIds${objectiveIds.join("_")}`,
            {
                predicate: new MethodologyPredicate<KeyResult>("objectiveId", "in", objectiveIds),
                navProperty: "values.person",
            });
    }

    public getObjectiveCommentsForObjectiveId(objectiveId: number) {
        return this.commonDataService.getByPredicate(ObjectiveCommentBreezeModel, new MethodologyPredicate<ObjectiveComment>("objectiveId", "==", objectiveId));
    }

    public getObjectivesByPredicate(predicate: MethodologyPredicate<Objective>) {
        return this.commonDataService.getByPredicate(ObjectiveBreezeModel, predicate);
    }

    public createObjective(initialData?: Partial<Objective>) {
        if (!initialData) {
            initialData = {};
        }

        initialData.index = 0; // initial as 0 - server will return with a proper unique index after save
        const predicate = new MethodologyPredicate<Objective>("status", "!=", ObjectiveStatus.Closed);
        if (initialData?.parentObjectiveId) {
            predicate.and(new MethodologyPredicate<Objective>("parentObjectiveId", "==", initialData.parentObjectiveId));
        } else if (initialData?.teamId) {
            predicate.and(new MethodologyPredicate<Objective>("teamId", "==", initialData.teamId));
        } else {
            predicate.and(new MethodologyPredicate<Objective>("teamId", "==", null));
        }

        return this.getObjectivesByPredicate(predicate).pipe(
            tap((existingObjectives) => initialData!.ordinal = existingObjectives.length > 0
                ? 1 + Math.max(...existingObjectives.map((o) => o.ordinal))
                : 0),
            switchMap(() => this.commonDataService.create(ObjectiveBreezeModel, initialData)),
        );
    }

    public createKeyResult(initialData?: Partial<KeyResult>) {
        return this.commonDataService.create(KeyResultBreezeModel, initialData);
    }

    public createKeyResultValue(initialData?: Partial<KeyResultValue>) {
        return this.commonDataService.create(KeyResultValueBreezeModel, initialData);
    }

    public createObjectiveItemLink(objective1: Objective, item: Item) {
        const initialData: Partial<Link> = {
            objective1Id: objective1.objectiveId,
            item,
            organisationId: this.organisationId,
        };
        return this.commonDataService.create(LinkBreezeModel, initialData);
    }

    public emitKeyResultValueUpdate(keyResultValue: KeyResultValue) {
        this._keyResultValueUpdated$.next(keyResultValue);
    }

    public emitObjectiveUpdate(objective: Objective) {
        this._objectiveUpdated$.next(objective);
    }

    /** Fetches the InProgress objectives for the organisation or specified team, priming all associated KeyResults and values */
    public getInProgressObjectives(team?: Team, showSupportedOrgObjectives = true, showSupportingTeamObjectives = false) {
        return this.objectivesAuthService.hasReadAccessToObjective(team ? team.teamId : undefined).pipe(
            switchMap((hasAccess) => {
                if (hasAccess) {
                    const objectiveFilter = new ObjectiveFilter();
                    objectiveFilter.showSupportedOrgObjectives = showSupportedOrgObjectives;
                    objectiveFilter.showSupportingTeamObjectives = showSupportingTeamObjectives;
                    return this.getPrimedObjectives(objectiveFilter, team?.teamId);
                } else {
                    return of([]);
                }
            }),
        );
    }

    public getUnclosedPotentialSupportiveObjectives(objective: Objective) {
        // annual org objectives cant be supported by anything, so return immediately
        if (objective.type === ObjectiveType.Annual && !objective.teamId) {
            return of([]);
        }

        // build a predicate that will be reused across multiple queries to this function
        // the predicate is intentionally of a wider scope than required, but it should result in less queries to the backend
        // further filtering will be done after the larger list of objectives is returned from the server
        const predicate = new MethodologyPredicate<Objective>("status", "!=", ObjectiveStatus.Closed);
        if (objective.team) {
            const orgPredicate = new MethodologyPredicate<Objective>("teamId", "==", null);
            const teamPredicate = new MethodologyPredicate<Objective>("teamId", "==", objective.team.teamId);
            predicate.and(orgPredicate.or(teamPredicate));
        } else {
            predicate.and(new MethodologyPredicate<Objective>("teamId", "==", null));
        }

        return this.getObjectivesByPredicate(predicate)
            .pipe(map((objectives) => objectives.filter((filterObjective) => {
                if (objective.team) {
                    if (objective.type === ObjectiveType.Annual) {
                        return filterObjective.teamId === null;
                    } else {
                        return filterObjective.type === ObjectiveType.Annual
                            || filterObjective.teamId === null;
                    }
                } else {
                    return filterObjective.type === ObjectiveType.Annual;
                }
            })));
    }

    public getInProgressObjectivesForPerson(person: Person) {
        // current person need to have at least permission to read from objective table
        return this.objectivesAuthService.hasAnyReadAccessToObjective$.pipe(
            switchMap((hasReadAccess) => {
                if (hasReadAccess) {
                    const key = `inProgressPersonObjectives${person.personId}`;
                    const predicate = new MethodologyPredicate<Objective>("assigneePersonId", "==", person.personId);
                    predicate.and(new MethodologyPredicate<Objective>("status", "!=", ObjectiveStatus.Closed));

                    return this.commonDataService.getWithOptions(ObjectiveBreezeModel, key, {
                        predicate,
                        orderBy: "dueDate ASC",
                    }).pipe(
                        switchMap((objectives) => this.getKeyResultsForObjectives(objectives).pipe(
                            map(() => objectives),
                        )),
                    );
                } else {
                    return of([]);
                }
            }),
        );
    }

    public getAllObjectives(objectives: Objective[]) {
        const results: Objective[] = [];
        for (const objective of objectives) {
            getObjectivesRecurse(objective, results);
        }

        // check the base objectives parent objective and add that to the list too
        // we are checking using a new array iterator in case we have a recursive objective situation
        for (const objective of objectives) {
            if (objective.parentObjective && !results.includes(objective.parentObjective)) {
                results.push(objective.parentObjective);
            }
        }

        return results;

        function getObjectivesRecurse(objective: Objective, collection: Objective[]) {
            if (!collection.includes(objective)) {
                collection.push(objective);
                for (const child of objective.childObjectives) {
                    getObjectivesRecurse(child, collection);
                }
            }
        }
    }

    /** Gets all objectives matching the passed in filter, priming child objectives and associated key results and values */
    public getPrimedObjectives(objectiveFilter: ObjectiveFilter, teamId?: number) {
        const self = this;
        const predicate = objectiveFilter.buildObjectivesPredicate(teamId);
        return this.getObjectivesByPredicate(predicate).pipe(
            //filter by label before we navigate up the objectives
            switchMap((objectives) => this.labellingService.primeLabelLocationsForObjectives(this.getAllObjectives(objectives)).pipe(
                map(() => objectives),
            )),
            map((objectives) => objectiveFilter.labels.length > 0 ? objectives.filter((o) => o.labelLocations.some((labelLocation) => objectiveFilter.labelIds!.some((l) => labelLocation.labelId === l))) : objectives),
            map((objectives) => {
                if (!teamId) {
                    // not showing private team objectives or team objectives from team with public read set to false when querying for org objectives page
                    // - don't have to worry about children of filtered out objectives as team objectives can only support org or another objective from
                    //   the same team - so if a team objective is excluded due to private team or team read-only, its children will also be matching the same filtering condition
                    // - as discussed with Steve, don't have to filter out objective links for private objectives (no one is using that anyway)
                    return objectives.filter((objective) => !objective.team ||
                        (!objective.team.isPrivate && objective.team.allowObjectivesTeamRead));
                }

                return objectives;
            }),
            switchMap(async (objectives) => {
                // still need the !teamId for orgs as the filter mail return org QO without AO - need AO in org view
                if (!teamId || objectiveFilter.showSupportedOrgObjectives) {
                    const allObjectives = [...objectives];
                    await getObjectivesRecurse(objectives, allObjectives);
                    return allObjectives;
                } else {
                    return objectives;
                }
            }),
            switchMap((objectives) => {
                // prime key results of all obj, child and parent (break into so many queries as we can't use expand/nav property due to lack of security)
                return this.getKeyResultsForObjectives(objectives).pipe(
                    // also prime links so that they are not required to be primed for each objective separately
                    switchMap(() => this.primeLinksForObjectives(objectives)),
                    map(() => objectives),
                );
            }),
        );

        /**
         * taking a list of objectives (objectives) as an input it will go to each set of objective's parents till it cannot find any more parents
         * and add those parents to a list (finalObjectives) as the output
         * @param objectives The child objectives who's parents are being added to finalObjectives
         * @param finalObjectives all the objectives that are being received
         * @param onlyOnce prevent getting more than the first set of parents
         */
        async function getObjectivesRecurse(objectives: Objective[], finalObjectives: Objective[]) {
            if (!objectives.some((o) => !!o.parentObjectiveId)) {
                return;
            }
            const objectiveWithParentsNotInFinalObjectives = objectives.filter((o) => !!o.parentObjectiveId)
                .filter((p) => !finalObjectives.some((id) => id.objectiveId === p.parentObjectiveId));

            const cachedParents = objectiveWithParentsNotInFinalObjectives.filter((o) => o.parentObjective !== null).map((o) => o.parentObjective);
            const results = ArrayUtilities.distinct(cachedParents);

            const parentIdsToFetch = objectiveWithParentsNotInFinalObjectives.filter((o) => o.parentObjective === null).map((o) => o.parentObjectiveId!);
            const distinctParentIds = ArrayUtilities.distinct(parentIdsToFetch);

            if (distinctParentIds.length > 0) {
                const fetchedObjectives = await lastValueFrom(self.getObjectivesByIds(distinctParentIds));
                results.push(...fetchedObjectives);
            }

            if (results.length > 0) {
                finalObjectives.push(...results);
                await getObjectivesRecurse(results, finalObjectives);
            }
        }
    }

    public getLinksForObjective(objective: Objective) {
        const key = `linksForObjective${objective.objectiveId}`;

        const predicate = new MethodologyPredicate<Link>("objective1Id", "==", objective.objectiveId);

        return this.commonDataService.getWithOptions(LinkBreezeModel, key, {
            predicate,
            navProperty: "item,objective2", // team would have been primed when building nav sidebar - don't include that here
        });
    }

    /** Get the specified objective, priming all useful navigation properties */
    public getPrimedObjective(objectiveId: number) {
        return this.getObjectiveById(objectiveId).pipe(
            // prime key results, parent and child objectives, and comments
            switchMap((objective) => {
                if (objective) {
                    const primeQueries = [
                        this.getKeyResultsForObjectiveId(objective.objectiveId),
                        this.getObjectivesWithParentId(objective.objectiveId),
                        this.getObjectiveCommentsForObjectiveId(objective.objectiveId),
                        this.getLinksForObjective(objective),
                        this.commonDataService.getById(ObjectiveReviewBreezeModel, objectiveId),
                    ] as Observable<any>[];

                    if (objective.parentObjectiveId) {
                        primeQueries.push(this.getObjectiveById(objective.parentObjectiveId));
                    }

                    return forkJoin(primeQueries).pipe(
                        map(() => objective),
                    );
                } else {
                    return of(undefined);
                }
            }),
        );
    }

    public createObjectiveComment(objective: Objective) {
        return this.userService.currentPerson$.pipe(
            first(),
            switchMap((currentPerson) => this.commonDataService.create(ObjectiveCommentBreezeModel, {
                objective,
                dateTime: new Date(),
                person: currentPerson,
            })),
        );
    }

    // This is used to get the top level IObjectiveGroup[] with matching teamId, used typically to filter out
    // ancestor nodes not within the team, e.g. team objectives with org parents, want only the team objectives in team page
    // for reordering, filtering out parent organisation objectives
    public getTopLevelChildGroupsInTeam(objectiveGroup: IObjectiveGroup, teamId?: number): IObjectiveGroup[] {
        // note teamId comparison here purposely uses double-equal only as teamId can be null (set by breeze) or
        // undefined (before breeze model is loaded such as in unit tests)
        if (objectiveGroup.objective.teamId == teamId) {
            return [objectiveGroup];
        }

        if (objectiveGroup.childGroups.length > 0) {
            return objectiveGroup.childGroups.flatMap((childGroup) => this.getTopLevelChildGroupsInTeam(childGroup, teamId));
        } else {
            return [];
        }
    }

    // This is to evaluate if the objectiveGroups are sortable.
    // IObjectiveGroup[] count of > 1 is not always sortable, e.g. if they are supporting different parent.
    // Need to group under same parent objectives.
    // Closed objectives are not counted.
    public objectiveGroupsAreSortableInTeam(objectiveGroups: IObjectiveGroup[], teamId?: number): boolean {
        const topLevelChildGroupsInTeam = objectiveGroups.flatMap((group) => this.getTopLevelChildGroupsInTeam(group, teamId));
        if (topLevelChildGroupsInTeam.length < 1) {
            // no top level, so there won't be any children - nothing to reorder
            return false;
        }

        if (topLevelChildGroupsInTeam.length === 1) {
            // only 1 group, will have to see if it has more than 1 child in the team - max 2 levels, so don't have to recurse
            return topLevelChildGroupsInTeam[0].childGroups
                .filter((group) => group.objective.teamId == teamId && group.objective.status !== ObjectiveStatus.Closed)
                .length > 1;
        }

        // here is when there are multiple groups, need to segment them into group of same parent
        const groupsOfSameParent = ArrayUtilities.groupArrayBy(topLevelChildGroupsInTeam, (g) => g.objective.parentObjectiveId);
        if (groupsOfSameParent.length === 1) {
            // single parent - multiple groups (single/no group already return from above)
            return true;
        } else if (groupsOfSameParent.length > 1) {
            // need at least one of the group to be sortable
            return groupsOfSameParent.some((g) => this.objectiveGroupsAreSortableInTeam(g.items, teamId));
        }

        // won't be here as there should be at least 1 group - just return anyway
        return false;
    }

    /**
     * Groups the given objectives into a tree structure, but also adding parent objectives
     * which aren't in the passed-in objective list
     */
    public groupObjectivesIncludingExternalParents(objectives: Objective[]) {
        return this.groupObjectivesInternal(objectives, (ungroupedObjectives) => {
            // Handle parents not in the original list
            const otherRoots = ungroupedObjectives.map((o) => o.parentObjective)
                .filter((p, idx, arr) => arr.indexOf(p) === idx) // Get unique values
                .filter((p) => ungroupedObjectives.indexOf(p) < 0);
            return otherRoots;
        });
    }

    /** Groups the given objectives into a tree structure but if a parent doesn't exist
     * in the original list of objectives, just place it at the top level.
     */
    public groupObjectives(objectives: Objective[]) {
        return this.groupObjectivesInternal(objectives, (ungroupedObjectives) => {
            const otherRoots = ungroupedObjectives.filter((o) => {
                return ungroupedObjectives.indexOf(o.parentObjective) < 0;
            });
            return otherRoots;
        });
    }

    private groupObjectivesInternal(objectives: Objective[], findOtherRoots: (objectives: Objective[]) => Objective[]) {
        const ungroupedObjectives = [...objectives];
        const sortFunction = this.getObjectiveSortFn<IObjectiveGroup>((g) => g.objective, null);

        const rootObjectives = ungroupedObjectives.filter((o) => !o.parentObjective);
        const groups = rootObjectives.map(generateGroup);

        // Pass a copy of the array as we modify the original in place in the method, and we don't
        // want the callback affecting the logic in here if it also modifies it in place.
        const otherRoots = findOtherRoots([...ungroupedObjectives]);
        const otherGroups = otherRoots.map(generateGroup);

        const allGroups = groups.concat(otherGroups);
        return allGroups.sort(sortFunction);

        function generateGroup(parent: Objective): IObjectiveGroup {
            ArrayUtilities.removeElementFromArray(parent, ungroupedObjectives);
            const children = ungroupedObjectives.filter((o) => o.parentObjective === parent);
            children.forEach((c) => ArrayUtilities.removeElementFromArray(c, ungroupedObjectives));

            return {
                objective: parent,
                childGroups: children.map(generateGroup).sort(sortFunction),
            };
        }
    }

    public sortGroup(currentTeamId: number | null) {
        return this.getObjectiveSortFn<IObjectiveGroup>((g) => g.objective, currentTeamId);
    }

    public sortObjectives(currentTeamId: number | null) {
        return this.getObjectiveSortFn<Objective>((o) => o, currentTeamId);
    }

    private getObjectiveSortFn<T>(getObjective: (o: T) => Objective, currentTeamId: number | null) {
        return SortUtilities.getSortByFieldFunction<T>(
            (o) => getObjective(o).teamId !== currentTeamId ? 1 : 0, // the team we are focusing goes first
            (o) => getObjective(o).status === ObjectiveStatus.Closed ? 1 : 0, // non-closed objective before closed
            (o) => getObjective(o).teamId ?? 0, // make same team together - otherwise, will alternate after ordinal is ordered
            (o) => getObjective(o).ordinal,
            (o) => getObjective(o).dueDate.getTime() * (getObjective(o).status === ObjectiveStatus.Closed ? -1 : 1),
            (o) => getObjective(o).objectiveId,
        );
    }

    // this is only used in groupObjectivesByTeam, which will sort according to status before ordinal
    private getObjectiveForTeamGroupSortFn<T>(getObjective: (o: T) => Objective) {
        return SortUtilities.getSortByFieldFunction<T>(
            (o) => ObjectiveStatusMetadata.ByStatus[getObjective(o).status].sortOrdinal,
            (o) => getObjective(o).ordinal,
            (o) => getObjective(o).dueDate.getTime() * (getObjective(o).status === ObjectiveStatus.Closed ? -1 : 1),
            (o) => getObjective(o).objectiveId,
        );
    }

    public groupObjectivesByTeam(objectives: Objective[]): IObjectiveTeamGroup[] {
        const objectivesByTeam = ArrayUtilities.groupArrayBy(objectives, (o) => o.team);
        const byTeam = objectivesByTeam.map((group) => ({
            team: group.key,
            objectives: group.items.sort(this.getObjectiveForTeamGroupSortFn((o) => o)),
        }));
        return byTeam.sort((g1, g2) => {
            if (!g1.team) {
                return -1;
            } else if (!g2.team) {
                return 1;
            } else {
                return g1.team.name.localeCompare(g2.team.name);
            }
        });
    }

    public cloneAndSaveObjective(objective: Objective) {
        const getObjectiveEntities = (newObjective: Objective) => [
            newObjective,
            ...newObjective.comments,
            ...newObjective.keyResults,
            ...newObjective.labelLocations,
            ...newObjective.links,
        ];

        let clonedObjective: Objective;

        if (!objective.entityAspect.validateEntity()) {
            const error = objective.entityAspect.getValidationErrors();
            return this.commonDialogService.showErrorDialog("Error duplicating objective", error.map((e) => e.errorMessage).join(", "));
        }
        const oldObjective$ = this.getPrimedObjective(objective.objectiveId).pipe(emptyIfUndefinedOrNull());
        const newObjective$ = oldObjective$.pipe(
            switchMap((oldObjective) => {
                const newDate = new Date();
                return this.commonDataService.create(ObjectiveBreezeModel, {
                    organisation: oldObjective.organisation,
                    team: oldObjective.team,
                    parentObjective: oldObjective.parentObjective,
                    title: `Copy of ${oldObjective.title}`,
                    description: oldObjective.description,
                    assigneePerson: oldObjective.assigneePerson,
                    creationDate: newDate,
                    modifiedDate: newDate,
                    dueDate: oldObjective.type === ObjectiveType.Annual
                        ? moment(newDate).add(1, "year").toDate()
                        : moment(newDate).add(3, "months").toDate(),
                    status: ObjectiveStatus.OnTrack,
                    type: oldObjective.type,
                } as Partial<Objective>);
            }),
            switchMap((newObjective: Objective) => {
                const titleError = newObjective.entityAspect.getValidationErrors()
                    .find((validationError) => validationError.propertyName === "title" && validationError.key === "maxLength:title") as any;

                if (titleError) {
                    newObjective.title = newObjective.title.substr(0, titleError.property.maxLength);
                    newObjective.entityAspect.validateEntity();
                }

                if (!newObjective.entityAspect.hasValidationErrors) {
                    return of(newObjective);
                } else {
                    const validationErrors = newObjective.entityAspect.getValidationErrors().map((validationError: ValidationError) => validationError.errorMessage).join(" ");
                    return this.commonDialogService.showMessageDialog("Error duplicating objective", validationErrors, "OK")
                        .pipe(
                            switchMap(() => this.commonDataService.rejectChanges(newObjective)),
                            map(() => undefined),
                        );
                }
            }),
        );

        return newObjective$.pipe(
            emptyIfUndefinedOrNull(),
            withLatestFrom(oldObjective$),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveKeyResults(oldObjective, newObjective)),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveLabelsLocations(oldObjective, newObjective)),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveLinks(oldObjective, newObjective)),
            switchMap(([newObjective, oldObjective]) => this.cloneObjectiveItemLinks(oldObjective, newObjective)),
            switchMap((newObjective: Objective) => {
                clonedObjective = newObjective;
                return this.commonDataService.saveEntities(getObjectiveEntities(clonedObjective)).pipe(
                    map(() => clonedObjective),
                );
            }),
            catchError((err: AdaptError) => this.commonDialogService.showMessageDialog("Error duplicating objective", err.message, "OK").pipe(
                switchMap(() => this.commonDataService.rejectChanges(getObjectiveEntities(clonedObjective))),
                switchMap(() => EMPTY),
            )),
        );
    }

    public createReviewForObjective(objective: Objective) {
        return this.commonDataService.create(ObjectiveReviewBreezeModel, {
            objective,
            reviewDismissed: false,
        });
    }

    public getOrCreateReviewForObjective(objective: Objective) {
        if (objective.objectiveReview) {
            return of(objective.objectiveReview);
        }

        return this.commonDataService.getById(ObjectiveReviewBreezeModel, objective.objectiveId).pipe(
            switchMap((objectiveReview) => objectiveReview
                ? of(objectiveReview)
                : this.createReviewForObjective(objective)),
        );
    }

    private cloneObjectiveLinks(source: Objective, destination: Objective) {
        if (!source.links?.length) {
            return of([destination, source]);
        }

        return forkJoin(source.links.map((link) => {
                if (!link.extensions.isObjectiveToObjectiveLink) {
                return of(null);
            }

                return this.linkService.createObjectiveToObjectiveLink(destination, link.objective2!);
        }),
        ).pipe(
            map(() => [destination, source]),
        );
    }

    private cloneObjectiveItemLinks(source: Objective, destination: Objective) {
        if (!source.links?.length) {
            return of(destination);
        }

        return forkJoin(source.links.map((link) => {
                if (!link.extensions.isObjectiveToItemLink) {
                return of(null);
            }

                return this.createObjectiveItemLink(destination, link.item!);
        }),
        ).pipe(
            map(() => destination),
        );
    }

    private cloneObjectiveKeyResults(source: Objective, destination: Objective) {
        if (!source.keyResults.length) {
            return of([destination, source]);
        }

        return forkJoin(source.keyResults.map((keyResult: KeyResult) => {
            return this.createKeyResult({
                objective: destination,
                title: keyResult.title,
                targetValue: keyResult.targetValue,
                targetValuePrefix: keyResult.targetValuePrefix,
                targetValueSuffix: keyResult.targetValueSuffix,
                ordinal: keyResult.ordinal,
            });
        })).pipe(
            map(() => [destination, source]),
        );
    }

    private cloneObjectiveLabelsLocations(source: Objective, destination: Objective) {
        if (!source.labelLocations.length) {
            return of([destination, source]);
        }

        return forkJoin(source.labelLocations.map((labelLocation) => {
            return this.commonDataService.create(LabelLocationBreezeModel, {
                label: labelLocation.label,
                objective: destination,
            });
        })).pipe(
            map(() => [destination, source]),
        );
    }

    private primeLinksForObjectives(objectives: Objective[]) {
        const objectiveIds = objectives.map((i) => i.objectiveId);
        return this.getLinksForObjectiveIds(objectiveIds).pipe(
            map(() => objectives),
        );
    }

    private getLinksForObjectiveIds(objectiveIds: number[]) {
        if (objectiveIds.length === 0) {
            return of([] as Link[]);
        }

        const predicate = new MethodologyPredicate<Link>("objective1Id", "in", objectiveIds);
        return this.commonDataService.getWithOptions(
            LinkBreezeModel,
            predicate.getKey(LinkBreezeModel.identifier),
            {
                predicate,
                navProperty: "item,objective2", // don't need to prime team as all accessible teams are already primed when forming sidebar nav
            });
    }
}
