import { Component, Input } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrgCommonCatchupUiService } from "@org-common/lib/peer-catchup/org-common-catchup-ui.service";
import { OrgCommonPeerCatchupAuthService } from "@org-common/lib/peer-catchup/org-common-peer-catchup-auth.service";
import { ICatchupQueryOptions, PeerCatchupService } from "@org-common/lib/peer-catchup/peer-catchup.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { TeamsService } from "app/features/people/teams/teams.service";
import moment from "moment";
import { BehaviorSubject, combineLatest, lastValueFrom, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";

interface ICatchupCount {
    getCountFromCatchups: (catchups: SpeedCatchup[]) => number;
    label: string;
    count: number;
}

@Component({
    selector: "adapt-peer-catch-up-dashboard-element",
    templateUrl: "./peer-catch-up-dashboard-element.component.html",
    styleUrls: ["./peer-catch-up-dashboard-element.component.scss"],
})
export class PeerCatchUpDashboardElementComponent extends BaseComponent {
    public readonly EditMyPeerCatchup = OrgCommonPeerCatchupAuthService.EditMyPeerCatchup;

    @Input() public set team(value: Team) {
        this.team$.next(value);
    }
    public team$ = new BehaviorSubject<Team | undefined>(undefined);

    public showElement = false;

    public teamMembers: Person[] = [];
    public peerCatchUps: SpeedCatchup[] = [];
    public url$: Observable<string>;
    public totalRelationshipCount = 0;
    private pcuUpdates$ = new BehaviorSubject<undefined>(undefined);

    public catchupCounts: ICatchupCount[] = [{
        getCountFromCatchups: this.getNumberOfTeamMemberCombinations,
        label: "Relationships in the team",
        count: 0,
    }, {
        getCountFromCatchups: this.getOverdueCount,
        label: "Overdue catch-ups",
        count: 0,
    }, {
        getCountFromCatchups: this.getCatchupsThisMonth,
        label: "Catch ups completed in the last month",
        count: 0,
    }];

    public constructor(
        featuresService: FeaturesService,
        private catchupService: PeerCatchupService,
        private orgCommonCatchupService: PeerCatchupService,
        private catchupUiService: OrgCommonCatchupUiService,
        private commonTeamsService: CommonTeamsService,
        private teamsService: TeamsService,
        private authService: AuthorisationService,
    ) {
        super();

        const catchupOptions: ICatchupQueryOptions = {
            consolidateTeamCatchups: true,
        };

        this.orgCommonCatchupService.catchupChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.pcuUpdates$.next(undefined));

        this.url$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => this.teamsService.promiseToGetTeamNetworkHealthUrl(team)),
        );

        combineLatest([this.team$, this.pcuUpdates$]).pipe(
            emptyIfUndefinedOrNull(),
            switchMap(async ([team, _pcuUpdates]) => {
                if (team) {
                    const teamMemberRoleConnections = await this.commonTeamsService.promiseToGetTeamMemberRoleConnections(team, true);
                    this.teamMembers = teamMemberRoleConnections.map((rc) => rc.connection.person);
                    this.showElement = featuresService.isFeatureActive(FeatureName.PeoplePeerCatchUp, team)
                        && this.teamMembers.length > 1;
                    this.totalRelationshipCount = this.getNumberOfTeamMemberCombinations();
                    this.peerCatchUps = await lastValueFrom(this.catchupService.getLatestCatchupsForTeam(team.teamId, catchupOptions));
                    this.catchupCounts.forEach((i) => i.count = i.getCountFromCatchups(this.peerCatchUps));
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    public newCatchup() {
        return this.team$.pipe(
            switchMap((team) => this.catchupUiService.recordNewCatchup(this.authService.currentPerson!.personId, undefined, team!.teamId)),
        ).subscribe();
    }

    @Autobind
    private getNumberOfTeamMemberCombinations() {
        return this.teamMembers.reduce((total, _tm, idx) => total + idx, 0);
    }

    @Autobind
    private getCatchupsThisMonth(catchups: SpeedCatchup[]) {
        const now = moment();
        const aMonthAgo = now.subtract(1, "month");
        return this.countMatchingCondition(catchups, (c) => moment(c.creationDate).isAfter(aMonthAgo));
    }

    @Autobind
    private getOverdueCount(catchups: SpeedCatchup[]) {
        const now = moment();
        let overdueCount = 0;

        for (const scu of catchups) {
            if (now.isAfter(this.orgCommonCatchupService.calculateNextCatchupDate(scu.creationDate))) {
                overdueCount++;
            }
        }

        return overdueCount;
    }

    private countMatchingCondition<T>(data: T[], condition: (d: T) => boolean) {
        return data.reduce((total, d) => total + (condition(d) ? 1 : 0), 0);
    }
}
