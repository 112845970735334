import { Component, Inject, Injector, OnInit } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { TEAM_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { KanbanAuthService } from "@org-common/lib/kanban/kanban-auth.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { OrgCommonPeerCatchupAuthService } from "@org-common/lib/peer-catchup/org-common-peer-catchup-auth.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { TeamAssessmentAuthService } from "@org-common/lib/survey/team-assessment/team-assessment-auth.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { CommonTeamsUiService } from "@org-common/lib/teams/common-teams-ui.service";
import { ValuesConstitutionAuthService } from "@org-common/lib/values-constitution/values-constitution-auth.service";
import { IntegratedArchitectureFrameworkAuthService } from "app/features/architecture/integrated-architecture/integrated-architecture-framework-auth.service";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { filter, first, map, switchMap, tap } from "rxjs/operators";

enum DashboardPage {
    Guidance = "guidance",
    Systems = "systems",
    Stewardship = "stewardship",
    PeopleAndCulture = "people-and-culture",
    Activity = "activity",
}

@Component({
    selector: "adapt-team-dashboard-page",
    templateUrl: "./team-dashboard-page.component.html",
})
export class TeamDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public static readonly PageSearchParam = "page";
    public page = DashboardPage.Guidance;
    public dashboardPage = DashboardPage;

    public teamId$ = new ReplaySubject<number | undefined>(1);
    public team$: Observable<Team | undefined>;
    public teamConfigureLink$: Observable<string>;

    private teamConfigUpdated$ = new BehaviorSubject<undefined>(undefined);
    public hasPeopleAndCulturePage = true;
    public hasStewardshipPage = true;
    public hasTier2 = true;
    public hasStats = true;

    // permissions for each of the dashboard elements
    public configureTeam = CommonTeamsAuthService.ConfigureTeam;
    public purposeVisionRead = CommonPurposeVisionAuthService.ReadPurposeVision;
    public valuesRead = ValuesConstitutionAuthService.ReadValuesConstitution;
    public tier1Read = Tier1ArchitectureAuthService.ReadTier1;
    public readObjectives = ObjectivesAuthService.ReadObjectivesForTeam;
    public viewTeamMeetings = CommonTeamsAuthService.ViewTeamMeetings;
    public readAllPeerCatchUps = OrgCommonPeerCatchupAuthService.ReadAllPeerCatchups;
    public readTeamAssessment = TeamAssessmentAuthService.ReadTeamAssessment;

    public readonly teamAssessmentSurveyType = SurveyType.TeamAssessment;
    public readonly systemEntityIcon = SystemEntity.IconClass;

    private person$ = this.userService.currentPerson$;

    constructor(
        teamsService: CommonTeamsService,
        authService: AuthorisationService,
        kanbanAuthService: KanbanAuthService,
        private teamsUiService: CommonTeamsUiService,
        injector: Injector,
        labellingService: LabellingService,
        private userService: UserService,
        private navHierarchyService: NavigationHierarchyService,
        @Inject(TEAM_CONFIGURATION_PAGE) teamConfigurationPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
        super(injector);

        // prime labels for toolbar title
        this.teamId$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((teamId) => labellingService.getLabelLocationsForTeam(teamId)),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.team$ = this.teamId$.pipe(
            switchMap((teamId) => teamId ? teamsService.getTeamById(teamId) : of(undefined)),
            tap((team) => {
                if (!team) {
                    this.routeService.gotoHome();
                }
            }),
            this.takeUntilDestroyed(),
        );

        this.teamConfigureLink$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap(({ teamId }) => teamConfigurationPageRoute.getRoute({ teamId })),
            this.takeUntilDestroyed(),
        );

        combineLatest([this.person$, this.team$, this.teamConfigUpdated$]).pipe(
            filter(([person]) => !!person),
            map(async ([person, team]) => {
                const isPCUActive = await authService.promiseToGetHasAccess(OrgCommonPeerCatchupAuthService.ReadAllPeerCatchups, team);
                const isTeamAssessmentActive = await authService.promiseToGetHasAccess(TeamAssessmentAuthService.ReadTeamAssessment, team);
                this.hasPeopleAndCulturePage = team !== undefined && (isPCUActive || isTeamAssessmentActive);
                if (this.hasPeopleAndCulturePage && isPCUActive && !isTeamAssessmentActive) {
                    const teamMemberRoleConnections = await teamsService.promiseToGetTeamMemberRoleConnections(team!, true);
                    this.hasPeopleAndCulturePage = teamMemberRoleConnections.length > 1;
                }

                this.hasTier2 = team !== undefined
                    && await authService.promiseToGetHasAccess(IntegratedArchitectureFrameworkAuthService.ReadTier2, team);

                this.hasStewardshipPage = team !== undefined
                    && (await authService.promiseToGetHasAccess(CommonTeamsAuthService.ViewTeamKanban, team)
                        || await authService.promiseToGetHasAccess(CommonTeamsAuthService.ViewTeamMeetings, team)
                        || await authService.promiseToGetHasAccess(ObjectivesAuthService.ReadObjectivesForTeam, team));

                this.hasStats = !!team && !!person && kanbanAuthService.personCanReadNonPublicBoardsForTeam(person, team);

                if ((!this.hasPeopleAndCulturePage && this.page === DashboardPage.PeopleAndCulture)
                    || (!this.hasStewardshipPage && this.page === DashboardPage.Stewardship)
                    || (!this.hasTier2 && this.page === DashboardPage.Activity)) {
                    await this.updateUrl(DashboardPage.Guidance);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.notifyActivated();
    }

    public async ngOnInit() {
        await this.updateData();
        // component is not reloaded even with onSameUrlNavigation: 'reload'
        // -> so whatever initialized in ngOnInit() which usually retrieve url/query params
        // won't be refreshed when changing from team page to another team page.
        // try this workaround: ngOnInit() is called after NavigationEnd
        //  - register callback for this initialization after navigation end
        // This is to update after switching from team dashboard to another team dashboard (navigationEnd has takeUntilDestoryed in there)
        this.navigationEnd.subscribe(() => this.updateData());
    }

    private async updateData() {
        const teamId = this.getRouteParamInt("teamId");
        this.teamId$.next(teamId);

        await this.updateTabFromSearchParam();
        this.navHierarchyService.updateActiveNodeFromUrl();
        this.notifyActivated();
    }

    private async updateTabFromSearchParam() {
        const page = this.getSearchParameterValue(TeamDashboardPageComponent.PageSearchParam) as DashboardPage;
        await this.updateUrl(Object.values(DashboardPage).includes(page) ? page : DashboardPage.Guidance);
    }

    public async updateUrl(newValueIdentifier: DashboardPage) {
        this.page = newValueIdentifier;
        await this.setSearchParameterValue(TeamDashboardPageComponent.PageSearchParam, this.page);
    }

    @Autobind
    public reactivateTeam() {
        return this.team$.pipe(
            first(),
            emptyIfUndefinedOrNull(),
            switchMap((team) => this.teamsUiService.reactivateTeam(team)),
        );
    }
}
