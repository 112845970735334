import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CareerValuationCategoryValue, CareerValuationCategoryValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/career-valuation-category-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { IDxPieChartCustomizeText } from "@common/ux/dx.types";
import dxPieChart from "devextreme/viz/pie_chart";
import { tap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { ItemUtilities } from "../../kanban/items/item-utilities";
import { LinkService } from "../../link/link.service";
import { OrgCommonCareerValuationService } from "../org-common-career-valuation.service";
import { OrgCommonCareerValuationUiService } from "../org-common-career-valuation-ui.service";

interface IIdealsChartData {
    argument: string;
    value: number;
}

@Component({
    selector: "adapt-view-cvt-dialog",
    templateUrl: "./view-cvt-dialog.component.html",
    styleUrls: ["./view-cvt-dialog.component.scss"],
})
export class ViewCareerValuationDialogComponent extends BaseDialogComponent<CareerValuation> implements OnInit {
    public readonly dialogName = "DisplayCareerValuationDialog";
    public title: string;

    public idealValuesChartInstance?: dxPieChart;

    public idealsChartData!: IIdealsChartData[];

    public actualTotal: number;

    public idealsChartPalette = OrgCommonCareerValuationUiService.CareerValuationPaletteName;

    @ViewChild("idealsChart") public idealsChart?: dxPieChart;

    public hasEditPermission = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public careerValuation: CareerValuation,
        public orgCommonCareerValuationUiService: OrgCommonCareerValuationUiService,
        public orgCommonCareerValuationService: OrgCommonCareerValuationService,
        private authorisationService: AuthorisationService,
        linkService: LinkService,
    ) {
        super();

        this.actualTotal = this.careerValuation.extensions.actualTotal;

        this.title = "View Career Valuation for " + this.careerValuation.person.fullName;

        this.setEditPermission();
        this.sortLinks();

        linkService.linkedItemChanged$(() => this.careerValuation.links).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.sortLinks());
    }

    public get filteredLinks() {
        return this.careerValuation.links.filter((l) => l.careerValuationId != null);
    }

    public customiseLabelText(info: IDxPieChartCustomizeText) {
        return info.argument + "<br>(" + info.value + " %)";
    }

    public getCategoryHeadingClass(categoryValue: CareerValuationCategoryValue) {
        return "category-heading-" + categoryValue.category.ordinal;
    }

    public sortLinks() {
        this.careerValuation.links.sort(ItemUtilities.getItemLinkStatusAndItemCodeSortComparator());
    }

    @Autobind
    public editCareerValuation() {
        return this.orgCommonCareerValuationUiService.editCareerValuation(this.careerValuation).pipe(
            tap(() => this.onCareerValuationChanged()),
        );
    }

    public deleteCareerValuation() {
        this.orgCommonCareerValuationUiService.promiseToDeleteCareerValuation(this.careerValuation).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.resolve(this.careerValuation));
    }

    public closeDialog() {
        this.resolve(this.careerValuation);
    }

    private async setEditPermission() {
        this.hasEditPermission = await this.authorisationService.promiseToGetHasAccess(OrgCommonCareerValuationService.EditCareerValuationForPerson, this.careerValuation.person);
    }

    private onCareerValuationChanged() {
        if (this.idealValuesChartInstance) {
            const extractedValues = this.careerValuation.categoryValues.map(this.extractIdealValue);

            this.idealValuesChartInstance.option("dataSource", extractedValues);
        }
    }

    private extractIdealValue(value: CareerValuationCategoryValue) {
        return {
            argument: value.category.name,
            value: value.idealValue,
        };
    }

    public async ngOnInit() {
        await this.orgCommonCareerValuationService.promiseToGetCareerValuation(this.careerValuation.careerValuationId);

        this.idealsChartData = this.careerValuation.categoryValues
            .sort(SortUtilities.getSortByFieldFunction(CareerValuationCategoryValueBreezeModel.sortField!))
            .map(this.extractIdealValue);
    }

}
