<adapt-two-column-page>
    <div left-column>
        <adapt-dashboard-element [info]="CulturalCohortTooltip"
                                 [name]="cohortName">
            <dx-list *adaptLoadingUntilFirstEmit="let culturalCohort of cohort$"
                     class="dx-list-boxed mb-3"
                     [dataSource]="culturalCohort"
                     selectionMode="single"
                     [focusStateEnabled]="false"
                     [selectedItems]="[selectedCohortMember]"
                     (onSelectionChanged)="onPrimaryListSelectionChanged($event)">
                <div *dxTemplate="let member of 'item'">
                    <ng-container *ngTemplateOutlet="culturalRelationshipPersonTemplate; context: {member: member}"></ng-container>
                </div>
            </dx-list>

            <adapt-collapsible-dashboard-element [info]="SupportingMemberTooltip"
                                                 [collapsed]="hasCohortMembers"
                                                 class="supporting"
                                                 name="Supporting members">
                <dx-list [dataSource]="supportingMembers"
                         [focusStateEnabled]="false"
                         (onInitialized)="supportingMembersList = $event.component"
                         [grouped]="true"
                         [collapsibleGroups]="true"
                         [selectedItems]="[selectedCohortMember]"
                         class="dx-list-boxed"
                         selectionMode="single">
                    <div *dxTemplate="let group of 'group'">
                        <adapt-link-person *ngIf="group.key; else unallocatedGroup"
                                           [personId]="group.key"
                                           [adaptLinkDisabled]="true"></adapt-link-person>
                        <ng-template #unallocatedGroup>
                            <span>(No cultural leader)</span>
                        </ng-template>
                    </div>
                    <div *dxTemplate="let data of 'item'">
                        <ng-container *ngTemplateOutlet="culturalRelationshipPersonTemplate; context: {member: data.member}"></ng-container>
                    </div>
                </dx-list>
            </adapt-collapsible-dashboard-element>
        </adapt-dashboard-element>
    </div>

    <div right-column>
        <adapt-dashboard-element *ngIf="selectedCohortMember"
                                 [name]="selectedCohortMember.relationship.person.fullName">

            <div adapt-dashboard-title-buttons>
                <button adaptButton="add"
                        [adaptBlockingClick]="recordCVT">Career Valuation
                </button>
            </div>

            <div class="card-box-shadow"
                 data-test="career-valuation">
                <ng-container *ngIf="selectedCohortMember.latestCatchup; else NoCvtRecordedTemplate">
                    <div data-test="next-career-valuation-due"
                         class="mb-5"><b>Next Career Valuation Due</b>: {{selectedCohortMember.nextCatchup | adaptDate}}
                    </div>
                    <adapt-cvt-history [personId]="selectedCohortMember.relationship.personId"
                                       [showGrid]="false"></adapt-cvt-history>
                </ng-container>
            </div>

            <ng-template #NoCvtRecordedTemplate>
                No Career Valuation has been recorded
            </ng-template>

        </adapt-dashboard-element>
    </div>

    <ng-template #culturalRelationshipPersonTemplate
                 let-member="member">
        <div (click)="onSelectionChanged(member)">
            <adapt-link-person *ngIf="member && member.relationship"
                               [personId]="member.relationship.personId"
                               [showImage]="true"
                               [showText]="true"

                               [adaptLinkDisabled]="true"></adapt-link-person>
            <span *ngIf="member && member.catchupStatus"
                  class="badge float-end mt-2"
                  [ngClass]="member.catchupStatus.badgeClass">{{member.catchupStatus.defaultLabel}}</span>
        </div>
    </ng-template>
</adapt-two-column-page>
