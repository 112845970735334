import { Component, ElementRef, ViewChild } from "@angular/core";
import { ValueQuestion } from "@common/ADAPT.Common.Model/organisation/value-question";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { IDxSortableEvent } from "@common/ux/dx.types";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { ValuesConstitutionService } from "../../values-constitution.service";
import { ICreateValueData } from "../create-value-workflow";

@WorkflowStepComponent("adapt-create-value-behaviours")
@Component({
    selector: "adapt-create-value-behaviours",
    templateUrl: "./create-value-behaviours.component.html",
    styleUrls: ["./create-value-behaviours.component.scss"],
})
export class CreateValueBehavioursComponent extends WorkflowStepComponentAdapter {
    public data?: ICreateValueData;
    private isValid = new BehaviorSubject<boolean>(false);
    public workflowStepCompleted = this.isValid.asObservable();

    @ViewChild("addBehaviourBtn") public addBehaviourBtn?: ElementRef<HTMLElement>;

    public constructor(
        private valuesConstitutionService: ValuesConstitutionService,
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public async workflowStepOnInit() {
        this.data = this.workflowStep?.workflow.runData;
        if (!this.data) {
            throw new Error("Workflow data is not available");
        }

        // add a default behaviour if none exist
        // skip if we're editing an existing value
        if ((this.data.value?.valueQuestions?.length ?? 0) === 0
            && this.data.value?.entityAspect.entityState.isAdded()) {
            await this.addBehaviour();
        }

        this.validateEntities();
    }

    public validateEntities() {
        if (this.data?.value) {
            this.data.entitiesToConfirm = ArrayUtilities.addElementIfNotAlreadyExists(this.data.entitiesToConfirm ?? [], this.data.value, ...this.data.value.valueQuestions);
            this.data.entitiesToConfirm.forEach((e) => this.emitEntityChange(e));

            this.isValid.next(this.data.entitiesToConfirm.every((e) => e.entityAspect.validateEntity()
                // make sure behaviour is provided for each
                && (e instanceof ValueQuestion ? e.goodBehaviour !== null : true)));
            return;
        }
        this.isValid.next(false);
    }

    public reorderItems(e: IDxSortableEvent<ValueQuestion>) {
        if (!this.data?.value?.valueQuestions) {
            return;
        }

        SortUtilities.reorderItemInIntegerSortedArray(this.data.value.valueQuestions, "ordinal", e.fromIndex!, e.toIndex!);
    }

    public async addBehaviour() {
        if (this.data?.value) {
            await lastValueFrom(this.valuesConstitutionService.createQuestionInValue(this.data.value));
            this.validateEntities();
            setTimeout(() => this.addBehaviourBtn?.nativeElement.scrollIntoView({ behavior: "smooth" }), 100);
        }
    }

    public async removeBehaviour(behaviour: ValueQuestion) {
        if (this.data?.value) {
            await lastValueFrom(this.commonDataService.remove(behaviour));
            this.data.entitiesToConfirm = ArrayUtilities.addElementIfNotAlreadyExists(this.data.entitiesToConfirm ?? [], behaviour);
            this.validateEntities();
        }
    }
}
