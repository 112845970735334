<adapt-feature-guidance [configItem]="configItem">
    <p>Configure who is in the team.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [team]="team"
                             [configItem]="configItem">
    <div class="row">
        <div class="col-md-6">
            <h4>{{teamLeaderLabel$ | async}}</h4>
            <div class="d-flex align-items-center">
                <adapt-person-image *ngIf="teamLeaderConnection?.person"
                                    class="ms-2 me-1"
                                    [person]="teamLeaderConnection!.person"
                                    imageSize="sm"></adapt-person-image>
                <adapt-select-person *ngIf="isActive; else inactiveTeamTemplate"
                                     id="inputTeamLeader"
                                     class="flex-grow-1"
                                     [required]="true"
                                     [person]="teamLeaderConnection?.person"
                                     (personChange)="onTeamLeaderPersonChange($event)"
                                     [userTypes]="allowedLeaderUserTypes"
                                     data-test="select-team-leader"></adapt-select-person>
            </div>
            <div *ngIf="warnAboutTeamLeaderChange"
                 role="alert"
                 class="alert alert-warning">
                You have changed the team leader for this team to be someone other than yourself. After this change is saved, you will be
                redirected to the team dashboard as you will no longer have permission to configure the team.
            </div>

            <ng-template #inactiveTeamTemplate>
                <adapt-link-person class="d-block"
                                   [person]="team.teamLeaderPerson"
                                   [showImage]="true"></adapt-link-person>
            </ng-template>

            <h4 class="pt-3">{{teamMemberLabel$ | async}}</h4>
            <dx-list class="dx-list-boxed h-auto"
                     *ngIf="teamMembers?.length"
                     [dataSource]="teamMembers"
                     [hoverStateEnabled]="false"
                     [focusStateEnabled]="false"
                     [activeStateEnabled]="false"
                     [allowItemDeleting]="isActive"
                     (onItemDeleted)="removeMember($event)"
                     data-test="team-members-list">

                <div *dxTemplate="let roleConnection of 'item'">
                    <adapt-link-person *ngIf="roleConnection?.connection?.person"
                                       [person]="roleConnection.connection.person"
                                       [showImage]="true"
                                       imageSize="sm">
                    </adapt-link-person>
                </div>
            </dx-list>

            <adapt-select-person #selectTeamMember
                                 class="pt-3"
                                 *ngIf="isActive"
                                 [filter]="filterAvailable"
                                 [person]="newMember"
                                 (personChange)="onTeamMemberPersonChange($event)"
                                 data-test="select-team-member"></adapt-select-person>
        </div>
    </div>

    @if (isActive) {
        <button *adaptIfAuthorised="ConfigureOrganisation"
                adaptButtonIcon="add"
                data-test="add-people-button"
                class="mt-3"
                (click)="addPeople()">Invite new team members to the platform</button>
    }
</adapt-feature-configuration>
