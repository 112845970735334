<ng-container *ngIf="link?.item">
    <div class="my-1 d-flex flex-row align-items-start note-action-container action-container">
        <adapt-item-link [item]="link.item"
                         [showStatus]="showStatus"
                         [itemLinksDisabled]="itemLinksDisabled"
                         [closeAllDialogsOnDelete]="closeAllDialogsOnDelete"
                         [showSummary]="true"
                         (itemDialogClosed)="itemDialogClosed.emit($event)"></adapt-item-link>
    </div>
</ng-container>
