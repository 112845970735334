import { Injectable } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { PermissionType } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { FeatureService } from "@common/feature/feature.service";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { UserService } from "@common/user/user.service";
import { lastValueFrom } from "rxjs";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { CulturalLeadershipAccessLevels } from "./cultural-leadership-access-levels.enum";
import { CulturalLeadershipQueryUtilities } from "./cultural-leadership-query-utilities";

interface ICulturalEntityAuthCheck {
    /** The person who's authorisation is being checked for access */
    authorisingPerson: Person;

    /** The person who is the target for access checking */
    culturalEntityPerson: Person;

    /** The cultural feature that is being checked. Note this feature must have the
     * PersonalUseAndRead, PersonalEdit, QuantitativeRead and Read permissions.
     */
    culturalFeature: FeatureName;
}

interface ICulturalEntityPermissions {
    personalUseAndRead: FeaturePermissionName;
    personalEdit: FeaturePermissionName;
    quantitativeRead: FeaturePermissionName;
    anonymousRead: FeaturePermissionName;
    fullRead: FeaturePermissionName;
    fullEdit: FeaturePermissionName;
}

@Injectable({
    providedIn: "root",
})
export class CulturalLeadershipFrameworkAuthService {
    public static readonly ReadCohorts = "readCulturalNetworkCulturalLeadershipCohorts";
    public static readonly ConfigureCohorts = "configureCulturalNetworkCulturalLeadershipCohorts";
    public static readonly ReadCulturalLeadership = "readCulturalNetworkCulturalLeadership";

    private cachedFeaturePermissions: { [featureName in FeatureName]?: ICulturalEntityPermissions } = {};

    public constructor(
        private commonDataService: CommonDataService,
        private userService: UserService,
        private authorisationService: AuthorisationService,
        private featureService: FeatureService,
    ) {
    }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CulturalLeadershipFrameworkAuthService.ReadCohorts,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortRead,
                    FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortConfigure,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CulturalLeadershipFrameworkAuthService.ConfigureCohorts,
            {
                requirePermissions: FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortConfigure,
            },
        );
        authorisationService.registerAccessVerifier(
            CulturalLeadershipFrameworkAuthService.ReadCulturalLeadership,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkCareerValuationAnonymousRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead,
                    FeaturePermissionName.CulturalNetworkCareerValuationRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexAnonymousRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead,
                    FeaturePermissionName.CulturalNetworkCulturalIndexRead,
                    FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortRead,
                    FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortConfigure,
                ],
            },
        );
    }

    public currentPersonHasPermissionToReadCohorts() {
        const person = this.authorisationService.currentPerson;
        if (!person) {
            return false;
        }

        return this.authorisationService.personHasPermission(person, FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortRead)
            || this.authorisationService.personHasPermission(person, FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortConfigure);
    }

    /**
     * Returns the access level for the passed in person (or the current person if not set) for the passed in cultural feature.
     *
     * @param culturalFeature The cultural feature that is being checked.
     * @param personId If set, the personId of the person's CV that is being checked
     *                    OR
     *                 If NOT set, we use the current person to check their access level.
     */
    public async checkCulturalAccessLevel(culturalFeature: FeatureName, personId?: number): Promise<CulturalLeadershipAccessLevels> {
        const featurePermissions = await this.getFeaturePermissions(culturalFeature);

        if (!personId) {
            if (this.authorisationService.currentPersonHasPermission(featurePermissions.fullRead)) {
                return CulturalLeadershipAccessLevels.FullAccess;
            } else if (this.authorisationService.currentPersonHasPermission(featurePermissions.quantitativeRead)) {
                return CulturalLeadershipAccessLevels.QuantitativeAccess;
            } else if (this.authorisationService.currentPersonHasPermission(featurePermissions.anonymousRead)) {
                return CulturalLeadershipAccessLevels.AnonymousAccess;
            } else {
                personId = this.userService.getCurrentPersonId()!;
            }
        }

        // Previously was assumed to be defined
        const currentPerson = this.userService.currentPerson!;
        if (personId === currentPerson.personId) {
            const hasPersonalPermission = this.authorisationService.currentPersonHasOneOfPermissions([
                featurePermissions.personalUseAndRead,
                featurePermissions.personalEdit,
            ]);

            if (!hasPersonalPermission) {
                throw new Error("No personal permission");
            }

            return CulturalLeadershipAccessLevels.CurrentPerson;
        }

        if (await this.personIsCulturalLeaderOfMember(currentPerson, personId)) {
            return CulturalLeadershipAccessLevels.FullAccess;
        } else {
            if (this.authorisationService.currentPersonHasPermission(featurePermissions.fullRead)) {
                return CulturalLeadershipAccessLevels.FullAccess;
            } else if (this.authorisationService.currentPersonHasPermission(featurePermissions.quantitativeRead)) {
                return CulturalLeadershipAccessLevels.QuantitativeAccess;
            } else {
                // TODO This was previous behaviour, do we still want this?
                throw new Error("No CulturalAccessLevel permissions");
            }
        }
    }

    public async canReadCulturalEntity(authCheck: ICulturalEntityAuthCheck) {
        const featurePermissions = await this.getFeaturePermissions(authCheck.culturalFeature);

        // if they dont have permission and its an active connection, then disallow
        // (if they dont have an active connection, we just let it through because we never know if they had a historical connection...
        // ...because we delete access level history)
        const hasPermission = this.authorisationService.personHasPermission(authCheck.culturalEntityPerson, featurePermissions.personalUseAndRead);
        const hasActiveConnection = !!authCheck.culturalEntityPerson.getActiveConnections().length;
        if (!hasPermission && hasActiveConnection) {
            return false;
        }

        if (authCheck.authorisingPerson === authCheck.culturalEntityPerson) {
            return true;
        }

        if (await this.personIsCulturalLeaderOfMember(authCheck.authorisingPerson, authCheck.culturalEntityPerson)) {
            return true;
        }

        return this.authorisationService.personHasAtLeastOnePermission(authCheck.authorisingPerson, [
            featurePermissions.fullRead,
            featurePermissions.quantitativeRead,
        ]);
    }

    public async canEditCulturalEntity(authCheck: ICulturalEntityAuthCheck) {
        const featurePermissions = await this.getFeaturePermissions(authCheck.culturalFeature);

        if (!this.authorisationService.personHasPermission(authCheck.culturalEntityPerson, featurePermissions.personalUseAndRead)) {
            return false;
        }

        if (authCheck.authorisingPerson === authCheck.culturalEntityPerson) {
            return this.authorisationService.personHasPermission(
                authCheck.authorisingPerson,
                featurePermissions.personalEdit);
        }

        if (await this.personIsCulturalLeaderOfMember(authCheck.authorisingPerson, authCheck.culturalEntityPerson)) {
            return true;
        }

        return this.authorisationService.personHasPermission(
            authCheck.authorisingPerson,
            featurePermissions.fullEdit);
    }

    private async personIsCulturalLeaderOfMember(leader: Person, memberOrId: Person | number) {
        if (typeof memberOrId !== "number") {
            memberOrId = memberOrId.personId;
        }

        try {
            const memberLeaders = await new CulturalLeadershipQueryUtilities(this.commonDataService)
                .promiseToGetCulturalLeadersForPersonId(memberOrId);
            return memberLeaders.some((p) => p === leader);
        } catch (e) { // 403 is no longer intercepted -> if any read above returns 403 -> permission check should fail
            return false;
        }
    }

    private async getFeaturePermissions(featureName: FeatureName) {
        if (!this.cachedFeaturePermissions[featureName]) {
            const permissions = await lastValueFrom(this.featureService.getFeaturePermissions(featureName));

            this.cachedFeaturePermissions[featureName] = {
                personalUseAndRead: assertGetPermission(PermissionType.PersonalUseAndRead),
                personalEdit: assertGetPermission(PermissionType.PersonalEdit),
                quantitativeRead: assertGetPermission(PermissionType.QuantitativeRead),
                anonymousRead: assertGetPermission(PermissionType.AnonymousRead),
                fullRead: assertGetPermission(PermissionType.Read),

                // TODO CM-4258 This is a bit weird, we really should have an organisation edit
                // permission for this
                fullEdit: assertGetPermission(PermissionType.Read),
            };

            function assertGetPermission(permissionType: PermissionType) {
                const permission = permissions.find((i) => i.permissionType === permissionType);
                if (!permission) {
                    throw new Error(`Expected to find permission of type ${permissionType}`);
                }

                return permission.name;
            }
        }

        return this.cachedFeaturePermissions[featureName]!;
    }
}
