import { Injectable, Injector } from "@angular/core";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ProcessMap, ProcessMapBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep, ProcessStepBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step";
import { ProcessStepGuidanceMaterial, ProcessStepGuidanceMaterialBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { ProcessStepSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step-supplementary-data";
import { ProcessStepType } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { BehaviorSubject, lastValueFrom, Observable, of, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ProcessMapService extends BaseOrganisationService {
    private stepCopiedOrMovedSubject$ = new Subject<SystemComponent>();
    private movingWithinProcessMapSubject$ = new BehaviorSubject<ProcessMap | undefined>(undefined);

    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public get stepCopiedOrMoved$() {
        return this.stepCopiedOrMovedSubject$.asObservable();
    }

    public get movingWithinProcessMap$() {
        return this.movingWithinProcessMapSubject$.asObservable();
    }

    public emitComponentForStepCopiedOrMoved(systemComponent: SystemComponent) {
        this.stepCopiedOrMovedSubject$.next(systemComponent);
    }

    public emitDraggingWithinProcessMap(processMap: ProcessMap | undefined) {
        this.movingWithinProcessMapSubject$.next(processMap);
    }

    public createProcessMap() {
        return this.currentOrganisation$.pipe(
            switchMap((organisation) => this.commonDataService.create(ProcessMapBreezeModel, {
                organisation,
            })),
        );
    }

    public getProcessMap(processMapId: number) {
        return this.commonDataService.getById(ProcessMapBreezeModel, processMapId);
    }

    public getProcessStep(processStepId: number) {
        return this.commonDataService.getById(ProcessStepBreezeModel, processStepId);
    }

    /** Fetch all process maps for this organisation */
    public getProcessMaps() {
        return this.commonDataService.getAll(ProcessMapBreezeModel);
    }

    public getProcessMapForStep(processStep: ProcessStep) {
        return this.commonDataService.getById(ProcessMapBreezeModel, processStep.processMapId);
    }

    /** Get process map steps, priming any allocated roles or linked process maps */
    public getProcessMapSteps(processMap: ProcessMap) {
        const key = this.getProcessMapStepsKey(processMap.processMapId);
        const predicate = new MethodologyPredicate<ProcessStep>("processMapId", "==", processMap.processMapId);
        return this.commonDataService.getWithOptions(ProcessStepBreezeModel, key, {
            predicate,
            navProperty: "role,linkedProcessMap",
        });
    }

    /** Get the top level steps for the given process map in ordinal order with the allocated
     * roles and linked process maps primed.
     */
    public getTopProcessMapSteps(processMap: ProcessMap) {
        const key = `processMapTopSteps${processMap.processMapId}`;
        const predicate = new MethodologyPredicate<ProcessStep>("processMapId", "==", processMap.processMapId)
            .and(new MethodologyPredicate<ProcessStep>("parentStepId", "==", null));
        return this.commonDataService.getWithOptions(ProcessStepBreezeModel, key, {
            predicate,
            navProperty: "role, linkedProcessMap",
        }).pipe(
            map((steps) => steps.sort((a, b) => a.parentStepOrdinal - b.parentStepOrdinal)),
        );
    }

    public getChildSteps(processStep: ProcessStep) {
        const predicate = new MethodologyPredicate<ProcessStep>("parentStepId", "==", processStep.processStepId);
        const key = `getChildSteps${predicate.getKey()}`;
        const encompassingKey = this.getProcessMapStepsKey(processStep.processMapId);
        return this.commonDataService.getWithOptions(ProcessStepBreezeModel, key, {
            predicate,
            encompassingKey,
        }).pipe(
            map((steps) => {
                return steps.sort((a, b) => a.parentStepOrdinal - b.parentStepOrdinal);
            }),
        );
    }

    /**
     * Clear the query cache for the process map steps. Needed when copying/moving a process step with child steps.
     * @param processMapId process map ID to clear cache for.
     */
    public clearChildStepsCacheForProcessMap(processMapId: number) {
        this.commonDataService.clearQueryCacheForRequestKey(this.getProcessMapStepsKey(processMapId));
    }

    private getProcessMapStepsKey(processMapId: number) {
        return `processMapSteps${processMapId}`;
    }

    /**
     * Fetch the steps which link to the specified process map, priming that step's process map and
     * parent step if it exists
     */
    public getPrimedProcessMapLinks(processMap: ProcessMap) {
        const key = `primedProcessMapLinks${processMap.processMapId}`;
        const predicate = new MethodologyPredicate<ProcessStep>("linkedProcessMapId", "==", processMap.processMapId);
        return this.commonDataService.getWithOptions(ProcessStepBreezeModel, key, {
            predicate,
            navProperty: "processMap, parentStep",
        });
    }

    public createProcessStep(parent: ProcessMap | ProcessStep, type: ProcessStepType) {
        const defaults: Partial<ProcessStep> = {
            processMap: parent instanceof ProcessMap
                ? parent
                : parent.processMap,
            parentStep: parent instanceof ProcessStep
                ? parent
                : null,
            type,
            lastUpdated: new Date(),
        };

        if (type === ProcessStepType.ProcessMapLink) {
            defaults.name = "Task Map Link";
        }

        return this.commonDataService.create(ProcessStepBreezeModel, defaults).pipe(
            switchMap((processStep) => this.commonDataService.create(ProcessStepSupplementaryDataBreezeModel, { processStep }).pipe(
                map(() => processStep),
            )),
        );
    }

    public getOrCreateProcessStepSupplementaryData(processStep: ProcessStep) {
        return this.getProcessStepSupplementaryData(processStep).pipe(
            switchMap((supplementaryData) => supplementaryData
                ? of(supplementaryData)
                : this.createProcessStepSupplementaryData(processStep)),
        );
    }

    public getProcessStepSupplementaryData(processStep: ProcessStep) {
        return processStep.supplementaryData
            ? of(processStep.supplementaryData)
            : this.commonDataService.getById(ProcessStepSupplementaryDataBreezeModel, processStep.processStepId);

    }

    public getProcessStepGuidanceMaterialsByProcessStepId(processStepId: number) {
        const key = `getProcessStepGuidanceMaterialsByProcessStepId${processStepId}`;
        const predicate = new MethodologyPredicate<ProcessStepGuidanceMaterial>("processStepId", "==", processStepId);
        return this.commonDataService.getWithOptions(ProcessStepGuidanceMaterialBreezeModel, key, {
            predicate,
            navProperty: "guidanceMaterial",
        });
    }

    public getProcessStepGuidanceMaterialsByGuidanceMaterialId(guidanceMaterialId: number) {
        const key = `getProcessStepGuidanceMaterialsByGuidanceMaterialId${guidanceMaterialId}`;
        const predicate = new MethodologyPredicate<ProcessStepGuidanceMaterial>("guidanceMaterialId", "==", guidanceMaterialId);
        return this.commonDataService.getWithOptions(ProcessStepGuidanceMaterialBreezeModel, key, {
            predicate,
            navProperty: "processStep",
        });
    }

    public getProcessStepByRoleId(roleId: number) {
        const predicate = new MethodologyPredicate<ProcessStep>("roleId", "==", roleId);
        return this.commonDataService.getByPredicate(ProcessStepBreezeModel, predicate);
    }

    public createProcessStepGuidanceMaterial(processStep: ProcessStep, guidanceMaterial: GuidanceMaterial, ordinal: number) {
        return this.commonDataService.create(ProcessStepGuidanceMaterialBreezeModel, {
            processStep,
            guidanceMaterial,
            ordinal,
        });
    }

    public createProcessStepSupplementaryData(processStep: ProcessStep) {
        return this.commonDataService.create(ProcessStepSupplementaryDataBreezeModel, { processStep });
    }

    /**
     * Recurse over the given steps, calling a callback for every role task process step found
     * @param childSteps childSteps from a process map
     * @param getSteps function which gets the steps from a linked process map
     * @param processRoleTaskStep function which is called for every role task process step
     */
    public async recurseForRoleTasks(
        childSteps: ProcessStep[],
        getSteps: (pm: ProcessMap) => Observable<ProcessStep[]>,
        processRoleTaskStep: (step: ProcessStep) => any,
    ) {
        const processedMapIds: number[] = []; // this is to prevent recurse map links
        const processStepIds: number[] = []; // just in case there is a recurse steps from sortablejs error
        await recurseSteps(childSteps);

        async function recurseSteps(steps: ProcessStep[]) {
            for (const step of steps) {
                if (!processedMapIds.includes(step.processMapId)) {
                    processedMapIds.push(step.processMapId);
                }

                if (step.extensions.isRoleTask) {
                    processRoleTaskStep(step);
                } else if (step.extensions.isProcessStep) {
                    if (processStepIds.includes(step.processStepId)) {
                        return;
                    } else {
                        processStepIds.push(step.processStepId);
                    }

                    // get them in order for exporting etc.
                    await recurseSteps(step.childSteps.sort((a, b) => a.parentStepOrdinal - b.parentStepOrdinal));
                } else if (step.extensions.isProcessMapLink) {
                    if (processedMapIds.includes(step.linkedProcessMapId!)) {
                        return;
                    }
                    processedMapIds.push(step.linkedProcessMapId!);
                    const linkedProcessMapSteps = await lastValueFrom(getSteps(step.linkedProcessMap!));
                    await recurseSteps(linkedProcessMapSteps);
                }
            }
        }
    }
}
