import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { IDxGridColumnCellInfo } from "@common/ux/dx.types";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { OrgCommonCareerValuationService } from "@org-common/lib/career-valuation/org-common-career-valuation.service";
import { ViewCareerValuationDialogComponent } from "@org-common/lib/career-valuation/view-cvt-dialog/view-cvt-dialog.component";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { CareerValuationAuthService } from "app/features/people/career-valuation/career-valuation-auth.service";
import { CareerValuationUiService } from "app/features/people/career-valuation/career-valuation-ui.service";
import { CulturalIndexService } from "app/features/people/cultural-index/cultural-index.service";
import { CulturalIndexAuthService } from "app/features/people/cultural-index/cultural-index-auth.service";
import { CulturalIndexUiService } from "app/features/people/cultural-index/cultural-index-ui.service";
import dxDataGrid, { CellPreparedEvent, InitializedEvent } from "devextreme/ui/data_grid";
import { DxTooltipComponent } from "devextreme-angular";
import { CulturalLeadershipFrameworkService, SupportingMemberTooltip } from "../cultural-leadership-framework.service";
import { CulturalLeadershipFrameworkUiService } from "../cultural-leadership-framework-ui.service";
import { ICulturalLeadershipPersonalAnalysisData } from "../cultural-leadership-personal-analysis-data.interface";

interface ICulturalLeaderMap {
    [key: number]: string;
}

@Component({
    selector: "adapt-clf-grid",
    templateUrl: "./clf-grid.component.html",
    styleUrls: ["./clf-grid.component.scss"],
})
export class CulturalLeadershipFrameworkGridComponent extends BaseComponent implements OnInit {
    public readonly SupportingMemberTooltip = SupportingMemberTooltip;

    @ViewChild(DxTooltipComponent) public careerValuationCellTooltip?: DxTooltipComponent;
    public dateFormat = DateFormats.globalize.short;
    public dxGridWrapperHelper: DxGridWrapperHelper;

    public culturalLeadersHeaderItems?: any[];

    public cvtAccess?: boolean;
    public ciAccess?: boolean;

    public culturalLeadershipConfiguration?: CulturalLeadership;
    private culturalLeaderNameMap: ICulturalLeaderMap = {};

    public gridData: ICulturalLeadershipPersonalAnalysisData[] = [];
    private gridInstance?: dxDataGrid;

    public allowCulturalIndexNotes?: boolean;
    public allowCareerValuationNotes?: boolean;

    public culturalLeadershipHeaderOptions = this.culturalLeadershipUiService.getStatusHeaderOptions();
    public culturalIndexHeaderOptions = this.culturalIndexUiService.getValueHeaderOptions();
    public careerValuationHeaderOptions = this.careerValuationUiService.getValueHeaderOptions();

    public CulturalLeadershipFrameworkAuthService = CulturalLeadershipFrameworkAuthService;

    constructor(
        elementRef: ElementRef,
        public culturalIndexService: CulturalIndexService,
        public culturalIndexUiService: CulturalIndexUiService,
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        public culturalLeadershipUiService: CulturalLeadershipFrameworkUiService,
        private authorisationService: AuthorisationService,
        public careerValuationService: OrgCommonCareerValuationService,
        public careerValuationUiService: CareerValuationUiService,
        private orgService: OrganisationService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();
        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-clf-grid", jQuery(elementRef.nativeElement));

        this.dxGridWrapperHelper.saveGridState(`${this.orgService.getOrganisationId()}`);
    }

    public async ngOnInit() {

        await this.promiseToGetData();
        this.culturalLeadershipConfiguration = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();

        this.ciAccess = await this.authorisationService.promiseToGetHasAccess(CulturalIndexAuthService.ReadQuantitativeCulturalIndex);
        this.cvtAccess = await this.authorisationService.promiseToGetHasAccess(CareerValuationAuthService.ReadQuantitativeCareerValuation);

        // only mark columns ready once everything is loaded so that state can restore correctly
        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());
    }

    @Autobind
    public onGridInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.initialiseGrid(e);
        this.gridInstance = e.component;

        e.component!.option().onToolbarPreparing = this.dxGridWrapperHelper.addResetToolbarButton;
    }

    @Autobind
    public editCohort(culturalLeaderId: number) {
        this.culturalLeadershipUiService.editCohortForCulturalLeaderId(culturalLeaderId).subscribe(() => this.refreshData());
    }

    @Autobind
    public addCohort() {
        this.culturalLeadershipUiService.addCohort().subscribe(() => this.refreshData());
    }

    @Autobind
    private async refreshData() {
        await this.promiseToGetData();

        this.gridInstance?.refresh();
    }

    public async promiseToGetData() {
        this.gridData = await this.culturalLeadershipFrameworkService.promiseToGetLatestCulturalFrameworkData();

        const culturalLeaders = this.gridData.reduce(this.reduceArrayToCulturalLeaders, []);

        this.culturalLeadersHeaderItems = culturalLeaders.map((culturalLeader) => ({
            text: culturalLeader.fullName,
            value: [this.calculateCulturalLeadersCellValue, "contains", culturalLeader.fullName],
        }));

        this.culturalLeadersHeaderItems.unshift({
            text: "(Blanks)",
            value: [this.calculateCulturalLeadersCellValue, "=", ""],
        });
    }

    @Autobind
    public calculateCulturalLeadersCellValue(data: ICulturalLeadershipPersonalAnalysisData) {
        return data.person.culturalLeaderRelationships
            .map((x) => this.getCulturalLeaderName(x))
            .join(",");
    }

    @Autobind
    public calculateCulturalLeadersGroupValue(data: ICulturalLeadershipPersonalAnalysisData) {
        if (data.culturalLeader) {
            this.culturalLeaderNameMap[data.culturalLeader.personId] = data.culturalLeader.fullName;

            return data.culturalLeader.personId;
        } else {
            return undefined;
        }
    }

    @Autobind
    public culturalLeadersCustomizeText(e: IDxGridColumnCellInfo) {
        if (!e.valueText) {
            return "None allocated";
        } else {
            return this.culturalLeaderNameMap[+e.valueText] || e.valueText;
        }
    }

    private getCulturalLeaderName(culturalRelationship: CulturalRelationship) {
        if (culturalRelationship && culturalRelationship.culturalLeader) {
            return culturalRelationship.culturalLeader.fullName;
        }

        return undefined;
    }

    private reduceArrayToCulturalLeaders(result: Person[], currentValue: ICulturalLeadershipPersonalAnalysisData) {
        for (const relationship of currentValue.person.culturalLeaderRelationships) {
            if (!result.includes(relationship.culturalLeader)) {
                result.push(relationship.culturalLeader);
            }
        }

        return result;
    }

    @Autobind
    public onCellPrepared(event: CellPreparedEvent) {
        if (event.rowType === "data" && event.column?.dataField === "careerValuation.extensions.actualTotal") {
            (event.cellElement as unknown as JQuery).addClass(this.careerValuationUiService.getValueCellTemplateForClass(event)!);
        }

    }

    public launchPersonCareerValuation(cv: CareerValuation) {
        this.dialogService.open(ViewCareerValuationDialogComponent, cv).subscribe(() => {
            // refresh if CVT was deleted
            if (cv.entityAspect.entityState.isDeleted() || cv.entityAspect.entityState.isDetached()) {
                // since we get only the latest CVTs on load, the next CVT for the person will not be in the cache
                this.careerValuationService.promiseToGetLatestCareerValuationForPerson(cv.personId)
                    .then(() => this.refreshData());
            }
        });
    }
}
