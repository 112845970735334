import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrgCommonPeerCatchupAuthService } from "@org-common/lib/peer-catchup/org-common-peer-catchup-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { AnalyseCatchupsPageComponent } from "./analyse-catchups-page.component";

export const analyseCatchupsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-catchups-page", AnalyseCatchupsPageComponent)
    .atOrganisationUrl("/people/peer-catchup/analyse")
    .redirectToThisRouteFromOrganisationUrl("/people/peerCatchUp/analyse")
    .withTitle("Peer Catch-ups")
    .withSearchKeyword("Network Health")
    .withSearchKeyword("PCU")
    .verifyingFeatures(FeatureName.PeoplePeerCatchUp)
    .verifyingAccess(OrgCommonPeerCatchupAuthService.ReadAllPeerCatchups)
    .build();
