import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { CulturalIndex } from "@common/ADAPT.Common.Model/organisation/cultural-index";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship, CulturalRelationshipBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BreezePredicateUtilities } from "@common/lib/data/breeze-predicate-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { AfterOrganisationInitialisation, AfterOrganisationInitialisationObservable } from "@org-common/lib/organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { CareerValuationDataService } from "app/features/people/career-valuation/career-valuation-data.service";
import { CulturalIndexAuthService } from "app/features/people/cultural-index/cultural-index-auth.service";
import { CulturalIndexDataService } from "app/features/people/cultural-index/cultural-index-data.service";
import moment from "moment";
import { lastValueFrom } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { CareerValuationAuthService } from "../../people/career-valuation/career-valuation-auth.service";
import { ICulturalCohortMemberData } from "./cultural-cohort-member-data.interface";
import { CulturalLeadershipCatchupStatus } from "./cultural-leadership-catchup-status.enum";
import { ICulturalLeadershipCatchupStatus } from "./cultural-leadership-framework-ui.service";
import { ICulturalLeadershipPersonalAnalysisData } from "./cultural-leadership-personal-analysis-data.interface";

export const SupportingMemberTooltip = "The cultural leader is not directly responsible for facilitating or scheduling cultural measurements for this person. They can still view and record cultural measurements for this person";
export const CulturalCohortTooltip = "Members for whom you are responsible for facilitating and scheduling cultural measurements";

export interface ICulturalLeadershipOverdueData {
    status: CulturalLeadershipCatchupStatus;
    label: string;
    value: number;
}

export interface ICulturalLeadershipPersonalAnalysisBlock {
    people?: Person[];
    culturalIndexes?: CulturalIndex[];
    careerValuations?: CareerValuation[];
    culturalRelationships?: CulturalRelationship[];
    culturalConfiguration?: CulturalLeadership;
}

@Injectable({
    providedIn: "root",
})
export class CulturalLeadershipFrameworkService extends BaseOrganisationService {
    private configuration!: CulturalLeadership;
    private clQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);
    private catchupStatuses: ICulturalLeadershipCatchupStatus[];

    public constructor(
        injector: Injector,
        private authService: AuthorisationService,
        private featuresService: FeaturesService,
        private ciDataService: CulturalIndexDataService,
        private cvDataService: CareerValuationDataService,
    ) {
        super(injector);

        this.catchupStatuses = [
            {
                id: CulturalLeadershipCatchupStatus.Overdue,
                defaultLabel: "Overdue",
                class: "catchup-status-overdue",
                badgeClass: "badge-overdue",
            },
            {
                id: CulturalLeadershipCatchupStatus.Due,
                defaultLabel: "Due this month",
                class: "catchup-status-due",
                badgeClass: "badge-due",
            },
            {
                id: CulturalLeadershipCatchupStatus.OnTime,
                defaultLabel: "On schedule",
                class: "catchup-status-on-schedule",
                badgeClass: "badge-on-schedule",
            },
        ];
    }

    protected organisationInitialisationActions() {
        return [this.initialiseService()];
    }

    @Autobind
    protected async initialiseService() {
        this.configuration = await this.clQueryUtilities.promiseToGetCulturalLeadershipConfiguration();
    }

    public async promiseToGetLatestCulturalFrameworkData() {
        const unorderedFrame: ICulturalLeadershipPersonalAnalysisBlock = {};

        unorderedFrame.people = await new PeopleQueryUtilities(this.commonDataService).promiseToGetActivePeople();

        const culturalIndexEnabled = await this.featuresService.promiseToCheckIfFeatureActive(FeatureName.CulturalNetworkCulturalIndex);
        if (culturalIndexEnabled) {
            await this.authService.promiseToVerifyAccess(CulturalIndexAuthService.ReadAnonymousCulturalIndex);
            unorderedFrame.culturalIndexes = await this.ciDataService.promiseToGetLatestCulturalIndexes();
        }

        const careerValuationEnabled = await this.featuresService.promiseToCheckIfFeatureActive(FeatureName.CulturalNetworkCareerValuation);
        if (careerValuationEnabled) {
            await this.authService.promiseToVerifyAccess(CareerValuationAuthService.ReadAnonymousCareerValuation);
            unorderedFrame.careerValuations = await this.cvDataService.promiseToGetLatestCareerValuations();
        }

        unorderedFrame.culturalRelationships = await this.clQueryUtilities.promiseToGetActiveCulturalLeadershipRelationships();
        unorderedFrame.culturalConfiguration = await this.promiseToGetCulturalLeadershipConfiguration();

        return this.prepareFrameworkData(unorderedFrame);
    }

    @Autobind
    private prepareFrameworkData(referenceData: ICulturalLeadershipPersonalAnalysisBlock) {
        const allPeople: Person[] = referenceData.people!;
        const allPersonalCulturalData = allPeople
            .filter((person: Person) => {
                const connection = person.getLatestEmployeeConnection();
                return connection && connection.isActive();
            })
            .map((person: Person) => ({ person })) as ICulturalLeadershipPersonalAnalysisData[];

        const culturalIndexData = referenceData.culturalIndexes ?? [];
        const cis: CulturalIndex[] = [];
        culturalIndexData.forEach((culturalIndex) => cis[culturalIndex.personId] = culturalIndex);
        allPersonalCulturalData.forEach((personalCulturalData) => personalCulturalData.culturalIndex = cis[personalCulturalData.person.personId]);

        const careerValuation = referenceData.careerValuations ?? [];
        const cvs: CareerValuation[] = [];
        careerValuation.forEach((cv) => cvs[cv.personId] = cv);
        allPersonalCulturalData.forEach((personalCulturalData) => personalCulturalData.careerValuation = cvs[personalCulturalData.person.personId]);

        const configuration = referenceData.culturalConfiguration!;
        allPersonalCulturalData.forEach((personalCulturalData) => this.calculateNextCatchupDate(personalCulturalData, configuration));

        /*The grid binds to only one cultural leader per person.
        Check for any people with multiple culturalRelationships.
        If so, duplicate the person and assign a distinct cultural leader.*/
        const people = referenceData.culturalRelationships ?? [];
        allPersonalCulturalData.forEach((personalCulturalData) => {
            const relationships = people.filter((p) => p.personId === personalCulturalData.person.personId);
            relationships.forEach((relationship, i) => {
                if (i === 0) {
                    personalCulturalData.culturalLeader = relationship.culturalLeader;
                    personalCulturalData.isSupporting = relationship.isSupporting;
                } else {
                    allPersonalCulturalData.push({ ...personalCulturalData, culturalLeader: relationship.culturalLeader, isSupporting: relationship.isSupporting });
                }
            });
        });

        return allPersonalCulturalData;
    }

    private calculateNextCatchupDate(personalCulturalData: ICulturalLeadershipPersonalAnalysisData, configuration: CulturalLeadership) {
        const today = moment().startOf("day")
            .toDate();
        const monthFromToday = moment().startOf("day")
            .add(1, "month")
            .toDate();

        if (!personalCulturalData.culturalIndex) {
            if (!personalCulturalData.careerValuation) {
                // no measurements included or recorded
                personalCulturalData.nextCatchup = today;
            } else {
                // only a career valuation included or recorded
                personalCulturalData.latestCatchup = personalCulturalData.careerValuation.creationDate;
                personalCulturalData.nextCatchup = calculateNextCatchup(personalCulturalData.latestCatchup);
            }
        } else if (!personalCulturalData.careerValuation) {
            // only a cultural index included or recorded
            personalCulturalData.latestCatchup = personalCulturalData.culturalIndex.creationDate;
            personalCulturalData.nextCatchup = calculateNextCatchup(personalCulturalData.latestCatchup);
        } else {
            // both career valuation and cultural index included or recorded
            personalCulturalData.latestCatchup = moment.max(
                moment(personalCulturalData.culturalIndex.creationDate),
                moment(personalCulturalData.careerValuation.creationDate),
            )
                .toDate();
            personalCulturalData.nextCatchup = calculateNextCatchup(personalCulturalData.latestCatchup);
        }

        if (!personalCulturalData.latestCatchup) {
            personalCulturalData.catchupStatus = CulturalLeadershipCatchupStatus.Overdue;
        } else if (moment(personalCulturalData.nextCatchup).isBefore(today)) {
            personalCulturalData.catchupStatus = CulturalLeadershipCatchupStatus.Overdue;
        } else if (moment(personalCulturalData.nextCatchup).isBefore(monthFromToday)) {
            personalCulturalData.catchupStatus = CulturalLeadershipCatchupStatus.Due;
        } else {
            personalCulturalData.catchupStatus = CulturalLeadershipCatchupStatus.OnTime;
        }

        function calculateNextCatchup(date: Date) {
            return moment(date)
                .add(configuration.catchupFrequency, "months")
                .toDate();
        }
    }

    public async promiseToAddCulturalRelationship(culturalLeader: Person): Promise<CulturalRelationship> {
        const defaults = {
            organisation: this.configuration.organisation,
            culturalLeader,
            startDate: moment.utc().toDate(),
        };

        return await lastValueFrom(this.commonDataService.create(CulturalRelationshipBreezeModel, defaults));
    }

    public promiseToRemoveCulturalRelationship(culturalRelationship: CulturalRelationship) {
        if (culturalRelationship.entityAspect.entityState.isAdded()) {
            return lastValueFrom(this.commonDataService.remove(culturalRelationship));
        }

        culturalRelationship.endDate = moment.utc().toDate();

        return Promise.resolve(undefined);
    }

    @AfterOrganisationInitialisation
    public async promiseToGetCulturalLeadershipConfiguration() { // leave async here even if there is no away as the function becomes a promise from the decorator
        return this.configuration;
    }

    @AfterOrganisationInitialisationObservable
    public getCulturalCohort(personId?: number) {
        const predicate = new MethodologyPredicate<CulturalRelationship>("culturalLeaderId", "==", personId)
            .and(BreezePredicateUtilities.getIsActivePredicate());

        return this.commonDataService.getByPredicate(CulturalRelationshipBreezeModel, predicate);
    }

    public getCulturalCohortMemberData(personId: number) {
        return this.getCulturalCohort()
            .pipe(
                switchMap((culturalRelationships) => {
                    return culturalRelationships?.length
                        ? this.getCohortMemberStatus(culturalRelationships, personId)
                        : [];
                }),
                map((data) => data.sort((a, b) => a.relationship.person.fullName.localeCompare(b.relationship.person.fullName))),
            );
    }

    private async getCohortMemberStatus(relationships: CulturalRelationship[], personId: number) {
        const cohortMemberData: ICulturalCohortMemberData[] = [];
        const filteredRelationships = relationships.filter((r) => r.culturalLeaderId === personId);
        const careerValuations = await this.cvDataService.promiseToGetLatestCareerValuationsForPersonIds(filteredRelationships.map((fr) => fr.personId));

        if (careerValuations) {
            filteredRelationships.forEach((relationship) => {
                const latestCVT = careerValuations.find((cvt) => cvt.personId === relationship.personId);
                const nextCatchupDate = this.getNextCatchupDate(latestCVT?.creationDate);
                const status = this.getCatchupStatus(this.getCatchupStatusFromDate(new Date(), nextCatchupDate));
                const primaryCulturalRelationship = relationships.find((r) => r.personId === relationship.personId && !r.isSupporting);

                cohortMemberData.push({
                    relationship,
                    latestCatchup: latestCVT?.creationDate,
                    nextCatchup: nextCatchupDate,
                    catchupStatus: status,
                    primaryCulturalLeaderId: primaryCulturalRelationship?.culturalLeaderId,
                } as ICulturalCohortMemberData);
            });
        }
        return cohortMemberData;
    }

    public getCatchupStatus(statusId: CulturalLeadershipCatchupStatus) {
        return this.catchupStatuses.find((element) => element.id === statusId);
    }

    public getCatchupStatusFromDate(currentDate: Date, nextCatchupDate?: Date) {
        if (!nextCatchupDate) {
            return CulturalLeadershipCatchupStatus.Overdue;
        } else if (moment(nextCatchupDate).isBefore(currentDate)) {
            return CulturalLeadershipCatchupStatus.Overdue;
        } else {
            const monthFromCurrentDate = moment(currentDate)
                .add(1, "month")
                .toDate();

            if (moment(nextCatchupDate).isBefore(monthFromCurrentDate)) {
                return CulturalLeadershipCatchupStatus.Due;
            } else {
                return CulturalLeadershipCatchupStatus.OnTime;
            }
        }
    }

    public getNextCatchupDate(date?: Date) {
        if (!date) {
            return undefined;
        }

        return moment(date)
            .add(this.configuration.catchupFrequency, "months")
            .toDate();
    }
}
