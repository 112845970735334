import { Injectable } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleSupplementaryData, RoleSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-supplementary-data";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { forkJoin, from, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { ISearchProviderOptions, SearchType } from "../search.interface";
import { SearchProvider } from "../search-provider";
import { IRoleSearchResult, ISearchResultMatch, PersonTeamRoleResultType } from "../search-results.interface";

@Injectable()
export class RoleSearchProvider extends SearchProvider<IRoleSearchResult> {
    public readonly Type = SearchType.PersonTeamRole;

    public roles: Role[] = [];

    public hasAccess = true;

    public constructor(
        private directorySharedService: DirectorySharedService,
        private labellingService: LabellingService,
        private featuresService: FeaturesService,
        private authService: AuthorisationService,
        private commonDataService: CommonDataService,
    ) {
        super();
    }

    public shouldSkip(options: ISearchProviderOptions): boolean {
        return !this.hasAccess || !!options.teamId || !!options.personId || !!options.updatedSince;
    }

    public initialise() {
        return from(this.featuresService.promiseToCheckIfFeatureActive(FeatureName.ArchitectureTier2)).pipe(
            switchMap((active) => active
                ? this.authService.promiseToGetHasAccess(CommonIntegratedArchitectureFrameworkAuthService.ReadTier2)
                : of(active)),
            tap((hasAccess) => this.hasAccess = hasAccess),
            switchMap((active) => active
                ? this.directorySharedService.promiseToGetAllRoles()
                : of([])),
            map((roles: Role[]) => roles.filter(DirectorySharedService.isNotTeamBasedRole)),
            tap((roles: Role[]) => this.roles = roles),
            map((roles: Role[]) => roles.map((r) => r.roleId)),
            // it will just end the chain here if we don't check for empty list...
            switchMap((roleIds: number[]) => roleIds.length === 0
                ? of([])
                : forkJoin([
                    this.getAllRoleSuppData(roleIds),
                    this.getAllRoleLabels(roleIds),
                ])),
        );
    }

    public execute({ keyword, activeOnly, labelIds }: ISearchProviderOptions) {
        const hasLabels = !!labelIds && labelIds.size > 0;
        const searchResults = this.roles
            .filter((r) => !activeOnly || r.isActive())
            .filter((r) => !hasLabels || r.labelLocations.find((ll) => labelIds?.has(ll.labelId)))
            .filter((r) => !keyword || (this.hasKeyword(r.label, keyword)
                || this.hasKeyword(r.supplementaryData?.capabilities, keyword)
                || this.hasKeyword(r.supplementaryData?.attributes, keyword)
                || this.hasKeyword(r.supplementaryData?.relationships, keyword)
                || this.hasKeyword(r.supplementaryData?.keyAccountabilities, keyword)))
            .slice(0, 10)
            .map((role) => {
                const results: ISearchResultMatch[] = [];

                if (this.hasKeyword(role.supplementaryData?.capabilities, keyword)) {
                    results.push({
                        field: ["Skills"],
                        snippet: StringUtilities.generateSnippet(role.supplementaryData?.capabilities ?? "", keyword),
                    });
                }
                if (this.hasKeyword(role.supplementaryData?.attributes, keyword)) {
                    results.push({
                        field: ["Attributes"],
                        snippet: StringUtilities.generateSnippet(role.supplementaryData?.attributes ?? "", keyword),
                    });
                }
                if (this.hasKeyword(role.supplementaryData?.relationships, keyword)) {
                    results.push({
                        field: ["Primary Relationships"],
                        snippet: StringUtilities.generateSnippet(role.supplementaryData?.relationships ?? "", keyword),
                    });
                }
                if (this.hasKeyword(role.supplementaryData?.keyAccountabilities, keyword)) {
                    results.push({
                        field: ["Key Accountabilities"],
                        snippet: StringUtilities.generateSnippet(role.supplementaryData?.keyAccountabilities ?? "", keyword),
                    });
                }

                return { type: PersonTeamRoleResultType.Role, role, results } as IRoleSearchResult;
            });

        return of(searchResults);
    }

    private getAllRoleSuppData(roleIds: number[]) {
        const predicate = new MethodologyPredicate<RoleSupplementaryData>("roleId", "in", roleIds);

        return this.commonDataService.getByPredicate(RoleSupplementaryDataBreezeModel, predicate);
    }

    private getAllRoleLabels(roleIds: number[]) {
        const predicate = new MethodologyPredicate<LabelLocation>("roleId", "in", roleIds);

        return this.labellingService.getLabelLocationsByPredicate(predicate);
    }
}
