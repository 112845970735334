import { Validator } from "breeze-client";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Person } from "../person/person";
import { Link } from "./link";
import { Organisation } from "./organisation";
import { SpeedCatchupExtensions } from "./speed-catchup-extensions";
import { SpeedCatchupRating } from "./speed-catchup-rating";
import { Team } from "./team";

export class SpeedCatchup extends BaseEntity<SpeedCatchup> {
    public speedCatchupId!: number;
    public organisationId!: number;
    public teamId?: number;
    public person1Id!: number;
    public person2Id!: number;
    public facilitatorPersonId?: number;
    public creationDate!: Date;
    public facilitatorComment?: string;
    public facilitatorPerson!: Person;
    public organisation!: Organisation;
    public person1?: Person;
    public person2?: Person;
    public ratings!: SpeedCatchupRating[];
    public team?: Team;
    public links!: Link[];

    @EntityExtensions(SpeedCatchupExtensions)
    public extensions!: SpeedCatchupExtensions;
}

export const SpeedCatchupBreezeModel = new BreezeModelBuilder("SpeedCatchup", SpeedCatchup)
    .persistChangedEntity()
    .withSingularName("Peer Catch-up")
    .withPluralName("Peer Catch-ups")
    .alwaysFetchingNavigationProperty("person1")
    .alwaysFetchingNavigationProperty("person2")
    .alwaysFetchingNavigationProperty("facilitatorPerson")
    .alwaysFetchingNavigationProperty("ratings")
    .alwaysFetchingNavigationProperty("team")
    .orderByDesc("creationDate")
    .withGroupByField("person1Id", "person2Id", "teamId")
    .withGroupByGenerateKeyFunction((entity: SpeedCatchup) => {
        const groupKeys: (number | undefined)[] = entity.person1Id < entity.person2Id
            ? [entity.person1Id, entity.person2Id]
            : [entity.person2Id, entity.person1Id];

        groupKeys.push(entity.teamId);

        return groupKeys;
    })
    .withActivePath("person1.connections")
    .withActivePath("person2.connections")
    .withEntityValidator(new Validator(
        "facilitatorCommentRequiredValidator",
        (entity: SpeedCatchup) => !entity.facilitatorPersonId || !!entity.facilitatorComment,
        {
            messageTemplate: "Facilitator comment is required",
        }))
    .withEntityValidator(new Validator(
        "correctNumberRatingsValidator",
        (entity: SpeedCatchup) => entity.ratings.length === 6,
        {
            messageTemplate: "This Peer Catch-up has an incorrect number of ratings.",
        }))
    .isOrganisationEntity()
    .hasSource("SpeedCatchups")
    .build();
