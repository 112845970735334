import { Link } from "./link";

export class LinkExtensions {

    public constructor(private link: Link) {
    }

    public get isObjectiveToObjectiveLink() {
        return !!(this.link.objective1Id
            && this.link.objective2Id);
    }

    public get isObjectiveToItemLink() {
        return !!(this.link.objective1Id
            && this.link.itemId);
    }

    public get isItemToItemLink() {
        return !!(this.link.itemId
            && this.link.linkedItemId);
    }

    public get isItemToMeetingLink() {
        return !!(this.link.itemId
            && this.link.meetingId
            && this.link.meetingAgendaItemId);
    }

    public get isItemToPcuLink() {
        return !!(this.link.speedCatchupId
            && this.link.itemId);
    }

    public get isItemToCvtLink() {
        return !!(this.link.careerValuationId
            && this.link.itemId);
        }
}