import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Link } from "@common/ADAPT.Common.Model/organisation/link";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { tap } from "rxjs/operators";
import { KanbanUiService } from "../../kanban/kanban-ui.service";
import { OrgCommonCatchupUiService } from "../org-common-catchup-ui.service";
import { PeerCatchupService } from "../peer-catchup.service";

@Component({
    selector: "adapt-catchup-link",
    templateUrl: "./catchup-link.component.html",
    styleUrl: "./catchup-link.component.scss",
})
export class CatchupLinkComponent implements OnInit, OnChanges {
    @Input({ required: true }) public catchupLink!: Link;

    @Input() public canEdit = false;
    @Input() public linkDisabled = false;

    @Output() public linkDeleted = new EventEmitter<void>();

    public catchup?: SpeedCatchup;

    public constructor(private catchupService: PeerCatchupService,
                       private catchupUiService: OrgCommonCatchupUiService,
                       private kanbanUiService: KanbanUiService) {
    }

    public ngOnInit(): void {
        this.catchupService.getCatchupById(this.catchupLink.speedCatchupId!).subscribe((c) => this.catchup = c);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.catchupLink) {
            this.ngOnInit();
        }
    }

    public openCatchup(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.catchup || this.linkDisabled) {
            return;
        }

        this.catchupUiService.showCatchup(this.catchup, true, false).subscribe();
    }

    @Autobind
    public deleteLink() {
        return this.kanbanUiService.deletePcuLink(this.catchupLink).pipe(
            tap(() => this.linkDeleted.emit()),
        );
    }
}
