import { Component, OnInit } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { CareerValuationUiService } from "app/features/people/career-valuation/career-valuation-ui.service";
import DataSource from "devextreme/data/data_source";
import dxList from "devextreme/ui/list";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ICulturalCohortMemberData } from "../cultural-cohort-member-data.interface";
import { CulturalCohortTooltip, CulturalLeadershipFrameworkService, SupportingMemberTooltip } from "../cultural-leadership-framework.service";

export interface ISupportingMemberGroupedItem {
    key: number // culturalLeader.personId
    member: ICulturalCohortMemberData,
}

@Component({
    selector: "adapt-cultural-cohort-personal-dashboard-element",
    templateUrl: "./cultural-cohort-personal-dashboard-element.component.html",
    styleUrls: ["./cultural-cohort-personal-dashboard-element.component.scss"],
})
export class CulturalCohortPersonalDashboardElementComponent extends BaseComponent implements OnInit {
    public readonly CulturalCohortTooltip = CulturalCohortTooltip;
    public readonly SupportingMemberTooltip = SupportingMemberTooltip;
    
    public cohortName?: string;
    public cohort$?: Observable<ICulturalCohortMemberData[]>;
    public supportingMembers?: DataSource<ISupportingMemberGroupedItem>;
    public refreshRequired$ = new BehaviorSubject<undefined>(undefined);
    public selectedCohortMember: ICulturalCohortMemberData | undefined;
    public hasCohortMembers = true;
    public supportingMembersList?: dxList;

    constructor(
        private culturalLeadershipFrameworkService: CulturalLeadershipFrameworkService,
        private careerValuationUiService: CareerValuationUiService,
        private userService: UserService,
        private commonDataService: CommonDataService,
    ) {
        super();

        // if someone saves a career valuation, then refresh the cohort
        this.commonDataService.saveCompleted$.pipe(
            map((entities) => entities.filter((entity) => !!entity.careerValuationId)),
            filter((entitiesOfType) => entitiesOfType.length > 0),
            tap(() => this.refreshRequired$.next(undefined)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public async ngOnInit() {
        // build the cohort, if previous selection was active attempt to restore it
        this.cohort$ = this.refreshRequired$.pipe(
            map(this.userService.getCurrentPersonId),
            emptyIfUndefinedOrNull(),
            switchMap((personId) => this.culturalLeadershipFrameworkService.getCulturalCohortMemberData(personId)),
            tap(async (data) => {
                const groupItems: ISupportingMemberGroupedItem[] = [];
                const members = data.filter((d) => d.relationship.isSupporting);
                for (const m of members) {
                    groupItems.push({
                        key: m.primaryCulturalLeaderId ?? 0,
                        member: m,
                    });
                }
                this.supportingMembers = new DataSource<ISupportingMemberGroupedItem>({
                    store: groupItems,
                    group: (item: ISupportingMemberGroupedItem) => item.key,
                    sort: [
                        "member.relationship.culturalLeader.firstName",
                    ],
                });
            }),
            map((data) => data.filter((p) => !p.relationship.isSupporting)),
            tap((data) => this.hasCohortMembers = data.length > 0),
            tap((data) => {
                if (this.selectedCohortMember?.relationship) {
                    this.selectedCohortMember = data.find((element) => element.relationship.personId === this.selectedCohortMember?.relationship.personId);
                } else if (data.length) {
                    this.selectedCohortMember = data[0];
                } else {
                    setTimeout(() => {
                        const listItems = this.supportingMembersList?.getDataSource().items() as IGroupedData<number, ISupportingMemberGroupedItem>[];
                        this.selectedCohortMember = listItems[0]?.items[0]?.member;
                    });
                }
            }),
        );

        const clConfig = await this.culturalLeadershipFrameworkService.promiseToGetCulturalLeadershipConfiguration();
        this.cohortName = clConfig.secondaryCollectionName;
    }

    public async onPrimaryListSelectionChanged(e: IDxListSelectionChangedEvent<ICulturalCohortMemberData>) {
        if (e.addedItems.length) {
            this.selectedCohortMember = ArrayUtilities.getSingleFromArray(e.addedItems);
        }
    }

    public async onSelectionChanged(e: ICulturalCohortMemberData) {
        if (e) {
            this.selectedCohortMember = e;
        }
    }

    @Autobind
    public async recordCVT() {
        const person = this.selectedCohortMember!.relationship.person;
        await this.careerValuationUiService.promiseToRecordCareerValuation(person);
    }
}
