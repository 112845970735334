import { Component, Input, ViewEncapsulation } from "@angular/core";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { CommonTeamsService } from "../common-teams.service";

@Component({
    selector: "adapt-team-membership",
    templateUrl: "./team-membership.component.html",
    styleUrls: ["./team-membership.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class TeamMembershipComponent {
    @Input()
    public set team(value: Team | undefined) {
        this.team$.next(value);
    }

    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public activeTeamMembers$: Observable<Person[]>;

    public teamLeaderPerson?: Person;

    public constructor(
        private teamsService: CommonTeamsService,
    ) {
        this.activeTeamMembers$ = this.team$.pipe(
            switchMap((team) => team
                ? this.teamsService.getLatestTeamRoleConnections(team)
                : EMPTY),
            map((teamRoleConnections) => {
                this.teamLeaderPerson = teamRoleConnections
                    .find((rc) => rc.role.roleType?.code === RoleTypeCode.TeamLeader)?.connection.person;
                return this.teamsService.getUniqueRoleConnectionPeople(teamRoleConnections
                    .filter((rc) => rc.role.roleType?.code !== RoleTypeCode.TeamLeader))
                    .sort(SortUtilities.getSortByFieldFunction((p) => p.fullName));
            }),
        );
    }
}
