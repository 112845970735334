<div *adaptLoading="!account"
     class="d-flex flex-column gap-3 p-2">
    <div class="d-flex flex-column gap-2"
         *ngIf="basePlatformFee > 0 || pricingModelUserCounts.size > 0 || platformModules.length > 0">
        <div class="title">Platform subscription</div>
        <div *ngFor="let countMap of pricingModelUserCounts | keyvalue: orderByUserOrdinal"
             class="item">
            <span class="item-description">{{UserTypeExtensions.singularLabel(countMap.key)}} ({{price(countMap.key) | currency:
                account!.extensions.currencyCode : 'symbol' : '1.0-0'}} *
                {{countMap.value}})</span>
            <span class="item-cost">{{subtotal(countMap.key, countMap.value) | currency: account!.extensions.currencyCode}}/month</span>
        </div>
        <div *ngIf="basePlatformFee > 0"
             class="item">
            <span class="item-description">{{account?.pricingModel?.name ?? projectLabel + ' platform fee'}}</span>
            <span class="item-cost">{{basePlatformFee | currency: account!.extensions.currencyCode}}/month</span>
        </div>
        <div *ngIf="platformModules.length">
            <div *ngFor="let module of platformModules"
                 class="item">
                <span class="item-description">{{module.platformCategory}} {{module.name}}</span>
                <span class="item-cost">{{account!.extensions.monthlyModuleFee(module.moduleId) | currency:
                    account!.extensions.currencyCode}}/month</span>
            </div>
        </div>
    </div>
    <div *ngIf="addonModules.length"
         class="d-flex flex-column gap-2">
        <div class="title">Add-ons</div>
        <div *ngFor="let module of addonModules"
             class="item">
            <span class="item-description">{{module.name}}</span>
            <span class="item-cost">{{account!.extensions.monthlyModuleFee(module.moduleId) | currency:
                account!.extensions.currencyCode}}/month</span>
        </div>
    </div>
    <div *ngIf="account?.extensions?.appliedAnnualDiscountPercentage && monthlyTotal > 0"
         class="d-flex flex-column gap-2 mb-3">
        <div class="title">Total monthly cost</div>
        <div class="undiscounted-monthly-cost">
            <span class="item-description">You are saving {{account?.extensions?.appliedAnnualDiscountPercentage}}% on an annual plan</span>
            <span class="item-cost">{{undiscountedMonthlyTotal | currency: account!.extensions.currencyCode}}/month</span>
        </div>
        <div class="discounted-monthly-cost">
            <span class="item-description">Discounted monthly cost</span>
            <span class="item-cost">{{monthlyTotal | currency: account!.extensions.currencyCode}}/month</span>
        </div>
    </div>

    <div class="bill-subtotal">
        <span class="item-description">Subtotal</span>
        <span class="item-cost">{{totalExGst | currency: account!.extensions.currencyCode}}/{{account?.billingPeriod === 1 ? 'month' :
            'year'}}</span>
    </div>

    @if (!account!.taxExempt) {
        <div class="bill-tax">
            <span class="item-description">GST (10%)</span>
            <span class="item-cost">{{tax | currency: account!.extensions.currencyCode}}</span>
        </div>
    }

    <div class="bill-total">
        <span class="item-description">Total {{account!.billingPeriod === 1 ? 'monthly' : 'annual'}} cost</span>
        <span class="item-cost">{{total | currency: account!.extensions.currencyCode}}/{{account?.billingPeriod === 1 ? 'month' :
            'year'}}</span>
    </div>

    @if (accountCredits && resetBillingCycle) {
        <div class="credit mt-3">
            <span class="item-description">Account credits</span>
            <span class="item-cost">{{(accountCredits) | currency: account!.extensions.currencyCode}}</span>
        </div>

        <div class="credited-total">
            <span class="item-description">Total due after credit applied</span>
            <span class="item-cost">{{creditedTotal | currency: account!.extensions.currencyCode}}</span>
        </div>

        <div class="remaining-credit">
            <span class="item-description">Remaining credit</span>
            <span class="item-cost">{{remainingCredit | currency: account!.extensions.currencyCode}}</span>
        </div>
    }
</div>
