import { Component, Input } from "@angular/core";
import { CatchupRatingValue, SpeedCatchupRating } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import moment from "moment";
import { OrgCommonCatchupUiService } from "../org-common-catchup-ui.service";
import { OrgCommonPeerCatchupAuthService } from "../org-common-peer-catchup-auth.service";
import { PeerCatchupService } from "../peer-catchup.service";

@Component({
    selector: "adapt-display-catchup-rating",
    templateUrl: "./display-catchup-rating.component.html",
    styleUrls: ["../peer-catchup-styles.scss"],
})
export class DisplayCatchupRatingComponent extends BaseComponent {
    @Input() public rating?: SpeedCatchupRating;

    // this component is also used by catchup history chart tooltip content where the following won't be default values
    // - keep the looks from how it was before the ajs port
    @Input() public showCommentDetails = false;
    @Input() public showButtons = true;

    @Input() public teamId?: number; // this is to allow setting team if doing a catchup from team Network Health

    public readonly CatchupRatingValue = CatchupRatingValue;
    public canEditPersonalCatchups = false;

    public constructor(
        catchupAuthService: OrgCommonPeerCatchupAuthService,
        private catchupService: PeerCatchupService,
        private catchupUiService: OrgCommonCatchupUiService,
        private orgCommonCatchupUiService: OrgCommonCatchupUiService,
    ) {
        super();

        catchupAuthService.canEditPersonalCatchups.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((canEdit) => this.canEditPersonalCatchups = canEdit);
    }

    public get person1() {
        return this.rating?.speedCatchup?.person1?.fullName;
    }

    public get person2() {
        return this.rating?.speedCatchup?.person2?.fullName;
    }

    public get author() {
        return this.rating?.authorPerson?.fullName;
    }

    public get feedbackPerson() {
        return this.rating?.feedbackPerson.fullName;
    }

    public get catchup() {
        return this.rating?.speedCatchup;
    }

    public get catchupDate() {
        return moment(this.rating?.speedCatchup?.creationDate).format(DateFormats.moment.short);
    }

    public get ratingType() {
        return this.rating?.extensions.getRatingType();
    }

    public get ratingSubjectText() {
        const type = this.ratingType!;
        if (type.authorIsSubject) {
            return `their own ${type.name}`;
        } else if (type.ratingIsMutual) {
            return `their ${type.name}  with ${this.rating!.subjectPerson.fullName}`;
        } else {
            return `${this.rating!.subjectPerson.fullName}'s ${type.name}`;
        }
    }

    // can't just do it in constructor as the rating is not set yet (can't even do it in ngOnInit as this is actually set from SVG draw)
    public get dueDateClass() {
        const nextCatchupDate = this.nextCatchupDate;
        let dueDateClass = "";
        if (this.areBothPeopleActive) {
            const now = moment();
            if (now.isSame(nextCatchupDate, "day")) {
                dueDateClass = "health-status-today";
            } else if (nextCatchupDate.isBefore(now)) {
                dueDateClass = "health-status-overdue";
            }
        }

        return dueDateClass;
    }

    public get areBothPeopleActive() {
        return !!(this.catchup?.person1?.isActive() && this.catchup?.person2?.isActive());
    }

    public get formattedNextCatchupDate() {
        return this.nextCatchupDate.format(DateFormats.moment.short);
    }

    private get nextCatchupDate() {
        return this.catchupService.calculateNextCatchupDate(this.catchup?.creationDate ?? new Date());
    }

    public showCatchup() {
        // scroll to the rating this card is showing
        return this.orgCommonCatchupUiService
            .showCatchup(this.catchup!, false, false, false, this.ratingType)
            .subscribe();
    }

    public recordNewCatchup() {
        return this.catchupUiService
            .recordNewCatchup(this.catchup!.person1Id, this.catchup!.person2Id, this.teamId)
            .subscribe();
    }
}
