import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxChartModule, DxDateBoxModule, DxLinearGaugeModule, DxPieChartModule, DxSliderModule } from "devextreme-angular";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptMeetingsSharedModule } from "../meetings/meetings-shared.module";
import { CulturalMeasurementMetaComponent } from "./cultural-measurement-meta/cultural-measurement-meta.component";
import { CareerValuationCategoryValueComponent } from "./cvt-category-value/cvt-category-value.component";
import { CvtLinkComponent } from "./cvt-link/cvt-link.component";
import { EditCareerValuationDialogComponent } from "./edit-cvt-dialog/edit-cvt-dialog.component";
import { ViewCareerValuationDialogComponent } from "./view-cvt-dialog/view-cvt-dialog.component";


@NgModule({
    declarations: [
        CvtLinkComponent,
        ViewCareerValuationDialogComponent,
        EditCareerValuationDialogComponent,
        CulturalMeasurementMetaComponent,
        CareerValuationCategoryValueComponent,
    ],
    imports: [
        CommonModule,
        AdaptDateModule,
        AdaptCommonDialogModule,
        AdaptEditorViewModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptMeetingsSharedModule,
        AdaptButtonModule,
        AdaptDirectorySharedModule,
        DxLinearGaugeModule,
        DxPieChartModule,
        DxChartModule,
        DxSliderModule,
        AdaptHtmlEditorModule,
        DxDateBoxModule,
        AdaptTooltipModule,
    ],
    exports: [
        CvtLinkComponent,
        CulturalMeasurementMetaComponent,
    ],
})
export class OrgCommonCareerValuationModule {}
