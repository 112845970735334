import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ITypedSimpleChange } from "@common/ux/base.component/typed-simple-change";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { KanbanNavigationService } from "../../kanban-navigation.service";
import { KanbanUiService } from "../../kanban-ui.service";

interface IItemLinkChanges extends SimpleChanges {
    item: ITypedSimpleChange<Item | undefined>;
    showStatus: ITypedSimpleChange<boolean>;
}

@Component({
    selector: "adapt-item-link",
    templateUrl: "./item-link.component.html",
    styleUrls: ["./item-link.component.scss"],
})
export class ItemLinkComponent extends BaseComponent implements OnChanges {
    @Input() public item?: Item;
    @Input() public showDialogLink = true;
    @Input() public showSummary = true;
    @Input() public showStatus = false;
    @Input() public useBoldItemCode = true;
    @Input() public inline = false;
    @Input() public showPersonImageLink = false;
    @Input() public hideItemCode = false;
    @Input() public itemLinksDisabled = false;
    @Input() public closeAllDialogsOnDelete = true;
    @Output() public itemDialogOpened = new EventEmitter<Item>();
    @Output() public itemDialogClosed = new EventEmitter<Item>();

    public itemUrl$?: Observable<string>;

    public constructor(
        private kanbanUiService: KanbanUiService,
        private kanbanNavService: KanbanNavigationService,
    ) {
        super();
    }

    public get itemStatusBadgeClass() {
        return this.showStatus && this.item
            ? ItemStatusMetadata.ByStatus[this.item.status].iconClass
            : "";
    }

    public get itemStatusTooltip() {
        if (!this.item) {
            return "";
        }

        return ItemStatusMetadata.ByStatus[this.item.status].name;
    }

    public ngOnChanges(changes: IItemLinkChanges) {
        if (changes.item && this.item) {
            this.itemUrl$ = this.kanbanNavService.getItemUrl(this.item);
        }
    }

    public async openItem(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.item) {
            return;
        }

        // can be adding link and hitting cancel - finally to keep board in sync with item
        setTimeout(async () => {
            this.itemDialogOpened.emit(this.item);
            // doing this in the next digest cycle to prevent interruption from kanban-card selection
            this.kanbanUiService.openItem(this.item!, { itemLinksDisabled: this.itemLinksDisabled, closeAllDialogsOnDelete: this.closeAllDialogsOnDelete }).pipe(
                finalize(() => this.itemDialogClosed.emit(this.item)),
            ).subscribe();
        });
    }
}
