import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { CareerValuation } from "@common/ADAPT.Common.Model/organisation/career-valuation";
import { Link } from "@common/ADAPT.Common.Model/organisation/link";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { tap } from "rxjs/operators";
import { KanbanUiService } from "../../kanban/kanban-ui.service";
import { OrgCommonCareerValuationService } from "../org-common-career-valuation.service";

@Component({
    selector: "adapt-cvt-link",
    templateUrl: "./cvt-link.component.html",
    styleUrl: "./cvt-link.component.scss",
})
export class CvtLinkComponent implements OnInit, OnChanges {
    @Input({ required: true }) public careerValuationLink!: Link;

    @Input() public canEdit = false;
    @Input() public linkDisabled = false;

    @Output() public linkDeleted = new EventEmitter<void>();

    public careerValuation?: CareerValuation;

    public constructor(private cvtService: OrgCommonCareerValuationService,
                       private kanbanUiService: KanbanUiService) {
    }

    public async ngOnInit() {
        this.careerValuation = await this.cvtService.promiseToGetCareerValuation(this.careerValuationLink.careerValuationId!);
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.careerValuationLink) {
            await this.ngOnInit();
        }
    }

    public openCareerValuation(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.careerValuation || this.linkDisabled) {
            return;
        }

        this.cvtService.viewCareerValuationDialog(this.careerValuation).subscribe(async () => {
            await this.ngOnInit();
        });
    }

    @Autobind
    public deleteLink() {
        return this.kanbanUiService.deleteCvtLink(this.careerValuationLink).pipe(
            tap(() => this.linkDeleted.emit()),
        );
    }
}
