import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FeatureModule } from "@common/ADAPT.Common.Model/embed/feature";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { FeaturePermissionTranslatorService } from "@common/feature/feature-permission-translator.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxCheckBoxOnValueChanged } from "@common/ux/dx.types";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import { IRolePermissionChanges } from "./role-permission-changes.interface";

export interface IConfigureRoleAccessOptions {
    changes?: IRolePermissionChanges;
}

interface IFeatureGroup {
    name: string;
    featurePermissions: FeaturePermission[];
}

interface IModuleRow {
    module: FeatureModule;
    featureGroups: IFeatureGroup[];
    totalPermissionCount: number;
    grantedPermissionCount: number;
}

@Component({
    selector: "adapt-configure-role-access",
    templateUrl: "./configure-role-access.component.html",
    styleUrls: ["./configure-role-access.component.scss"],
})
export class ConfigureRoleAccessComponent extends BaseComponent implements OnInit {
    @Input() public role?: Role;

    @Output() public addedPermissionsChange = new EventEmitter<FeaturePermission[]>();
    @Output() public deletedPermissionsChange = new EventEmitter<FeaturePermission[]>();

    @Input() public addedPermissions: FeaturePermission[] = [];
    @Input() public deletedPermissions: FeaturePermission[] = [];

    @Input() public readOnly = false;

    public accordionData: IModuleRow[] = [];
    public selectedIndex: number = 0; // watched by dxAccordion

    private canRemoveAccessManagementEdit = false;

    public constructor(
        private featureFactory: FeaturesService,
        private userManagementService: UserManagementService,
        public translatorService: FeaturePermissionTranslatorService,
        private directoryService: DirectorySharedService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.addedPermissions = [];
        this.deletedPermissions = [];

        const featurePermissions = await this.featureFactory.promiseToGetAllFeaturePermissions(); // passed to each edit role permissions component
        const canRemoveAccessManagementEdit: boolean = await this.directoryService.promiseToVerifyPermissionRemovalFromRole(
            FeaturePermissionName.OrganisationAccessManagementConfigure, this.role);

        this.updateAccordionData(
            featurePermissions.filter((i) => !FeaturesService.NonConfigurableFeaturePermissions.includes(i.name)),
            canRemoveAccessManagementEdit,
        );
    }

    private updateAccordionData(featurePermissions: FeaturePermission[], canRemoveAccessManagementEdit: boolean) {
        // with the filter, we allow editing of employee role
        this.canRemoveAccessManagementEdit = canRemoveAccessManagementEdit;

        if (featurePermissions) {
            const filteredFeaturePermissions = featurePermissions
                .filter((i) => this.nonPlatformFeaturePermission(i));

            for (const featurePermission of filteredFeaturePermissions) {
                const moduleRow = this.getModuleRow(featurePermission);
                this.addFeaturePermission(moduleRow, featurePermission);
                moduleRow.totalPermissionCount++;

                if (this.hasPermission(featurePermission)) {
                    moduleRow.grantedPermissionCount++;
                }
            }

            // ordering
            this.accordionData = this.accordionData.sort(SortUtilities.getSortByFieldFunction<IModuleRow>("module"));
            for (const item of this.accordionData) {
                item.featureGroups = item.featureGroups.sort(SortUtilities.getSortByFieldFunction<IFeatureGroup>("name"));
            }

            // expand the first module with permission
            let selectedFeatureModuleName: FeatureModule;
            for (const moduleRow of this.accordionData) {
                if (moduleRow.featureGroups.some(
                    (featureGroup) => featureGroup.featurePermissions.some((featurePermission) => this.hasPermission(featurePermission)))) {
                    selectedFeatureModuleName = moduleRow.module;
                    break;
                }
            }

            for (let i = 0; i < this.accordionData.length; i++) {
                const item = this.accordionData[i];
                if (item.module === selectedFeatureModuleName!) {
                    // set this next digest cycle for the item to be populated first or there will be errors
                    // from dx
                    setTimeout(() => this.selectedIndex = i);
                    break;
                }
            }
        }
    }

    private addFeaturePermission(moduleRow: IModuleRow, featurePermission: FeaturePermission) {
        // further groupings of features, e.g. personal kanban and organisation kanban into kanban
        const featureGroup = this.getFeatureGroup(moduleRow, this.translatorService.getFeatureGroupName(featurePermission.feature));
        featureGroup.featurePermissions.push(featurePermission);
    }

    private getFeatureGroup(moduleRow: IModuleRow, featureGroupName: string) {
        for (const group of moduleRow.featureGroups) {
            if (group.name === featureGroupName) {
                return group;
            }
        }

        const newGroup: IFeatureGroup = {
            name: featureGroupName,
            featurePermissions: [],
        };

        moduleRow.featureGroups.push(newGroup);

        return newGroup;
    }

    private nonPlatformFeaturePermission(featurePermission: FeaturePermission) {
        return !this.featureFactory.isPlatformFeature(featurePermission.feature);
    }

    @Autobind
    public isCheckBoxDisabled(featurePermission: FeaturePermission) {
        if (this.readOnly) {
            return true;
        }

        const hasPermission = this.hasPermission(featurePermission);
        return hasPermission && !this.canPermissionRemovedFromRole(featurePermission);
    }

    public onValueChanged(e: IDxCheckBoxOnValueChanged, featurePermission: FeaturePermission) {
        const moduleRow = this.getModuleRow(featurePermission);
        if (e.value) {
            const deleteIndex = this.deletedPermissions.indexOf(featurePermission);
            if (deleteIndex >= 0) {
                // previously deleted permission - don't create new one
                this.deletedPermissions.splice(deleteIndex, 1);
            } else {
                this.addedPermissions.push(featurePermission);
            }

            moduleRow.grantedPermissionCount++;
        } else {
            const deleteIndex = this.addedPermissions.indexOf(featurePermission);
            if (deleteIndex >= 0) {
                this.addedPermissions.splice(deleteIndex, 1);
            } else {
                this.deletedPermissions.push(featurePermission);
            }

            moduleRow.grantedPermissionCount--;
        }

        this.addedPermissionsChange.emit(this.addedPermissions);
        this.deletedPermissionsChange.emit(this.deletedPermissions);
    }

    @Autobind
    public getFeaturePermissionTooltip(featurePermission: FeaturePermission) {
        if (this.hasPermission(featurePermission) && !this.canPermissionRemovedFromRole(featurePermission)) {
            return "This permission cannot be removed by the current user";
        }

        return null;
    }

    @Autobind
    private getModuleRow(featurePermission: FeaturePermission) {
        const module = featurePermission.feature.featureModule;
        for (const itemRow of this.accordionData) {
            if (itemRow.module === module) {
                return itemRow;
            }
        }

        const newItemRow: IModuleRow = {
            module,
            featureGroups: [],
            totalPermissionCount: 0,
            grantedPermissionCount: 0,
        };

        this.accordionData.push(newItemRow);
        return newItemRow;
    }

    @Autobind
    public hasPermission(featurePermission: FeaturePermission) {
        return this.isRoleFeaturePermission(this.role, featurePermission);
    }

    @Autobind
    private canPermissionRemovedFromRole(featurePermission: FeaturePermission) {
        return featurePermission.name !== FeaturePermissionName.OrganisationAccessManagementConfigure
            || this.canRemoveAccessManagementEdit;
    }

    @Autobind
    private isRoleFeaturePermission(role: Role | undefined, featurePermission: FeaturePermission) {
        if (!role) {
            return false;
        }

        return !!this.userManagementService.getRoleFeaturePermission(role, featurePermission);
    }
}
