import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { AnchorMetadata } from "@common/ADAPT.Common.Model/organisation/anchor";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { ServiceUri } from "@common/configuration/service-uri";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { Logger } from "@common/lib/logger/logger";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { SetUtilities } from "@common/lib/utilities/set-utilities";
import { RouteService } from "@common/route/route.service";
import { UserService } from "@common/user/user.service";
import { DurationSelector } from "@common/ux/duration-selector";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import isEqual from "lodash.isequal";
import { BehaviorSubject, combineLatest, Observable, of, Subject, throttleTime, throwError, timeout } from "rxjs";
import { catchError, debounceTime, filter, finalize, map, startWith, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { StrategicViewIcon } from "../strategy/strategy-view-constants";
import { BullseyeViewOption, CAInputsViewOptions, SWTInputsViewOption } from "../strategy-board/strategy-board-page/strategy-board-page.component";
import { ISearchApiParams, ISearchGroup, ISearchOptions, ISearchOptionsUrlParams, ISearchUrlParams, SearchType } from "./search.interface";
import { SearchPageRoute } from "./search-page/search-page.component";
import { SEARCH_PROVIDERS, SearchProvider } from "./search-provider";
import { GuidanceType, IBaseSearchResult, ICompetitorAnalysisSearchResult, IGuidanceResult, IGuidanceSearchResult, IKeyFunctionSearchResult, IPersonSearchResult, IPersonTeamRoleResult, IProductServiceSearchResult, IPurposeVisionSearchResult, IResilientBusinessGoalSearchResult, IRoleSearchResult, ISearchResults, IStrategicAnchorSearchResult, IStrategicGoalSearchResult, IStrategicThemeSearchResult, IStrengthWeaknessTrendSearchResult, ITeamedSearchResult, ITeamSearchResult, ITier1Result, IValueSearchResult, IValueStreamSearchResult, IZonedSearchResult, PersonTeamRoleResultType, Tier1Type } from "./search-results.interface";

// how long to wait between options updating and query running
export const SearchDebounce = 1_000;
// show slow search banner
export const SearchSlowTimeout = 5_000;
// report to sentry that search is slow
export const SearchSlowErrorTimeout = 10_000;
// server search took too long, cancel timeout
export const SearchTimeout = 20_000;

// we have extracted these out from the search service due to an interaction with Angular 15.1 (https://github.com/angular/angular/issues/48764) and TS (https://github.com/microsoft/TypeScript/issues/52004)
export const searchGroupMapping: ISearchGroup[] = [];

function updateSearchTypeMapping() {
    return Object.fromEntries(searchGroupMapping.map((group) => [group.type, group])) as Record<SearchType, ISearchGroup>;
}

@Injectable({
    providedIn: "root",
})
export class SearchService {
    public static readonly NameBreadcrumb = "Name";
    public static readonly ProductServicesBreadcrumb = "Products & services";

    public static readonly PageSearchGroup: ISearchGroup = {
        type: SearchType.Page,
        name: "Pages",
        icon: "fa-fw fal fa-file-alt",
        remote: false,
    };
    public static readonly PeopleTeamsRolesSearchGroup: ISearchGroup<PersonTeamRoleResultType> = {
        type: SearchType.PersonTeamRole,
        name: "People, teams & roles",
        icon: "fa-fw fal fa-users",
        remote: false,
        subGroups: [{
            type: PersonTeamRoleResultType.Person,
            name: "People",
            icon: "fa-fw fal fa-user",
        }, {
            type: PersonTeamRoleResultType.Team,
            name: "Teams",
            icon: "fa-fw fal fa-users",
        }, {
            type: PersonTeamRoleResultType.Role,
            name: "Roles",
            icon: "fal fa-fw fa-user-tag",
        }],
    };
    public static readonly KanbanSearchGroup: ISearchGroup = {
        type: SearchType.Kanban,
        name: "Actions",
        icon: "fa-fw fal fa-columns",
        remote: true,
    };
    public static readonly MeetingSearchGroup: ISearchGroup = {
        type: SearchType.Meeting,
        name: "Meetings",
        icon: "fa-fw fal fa-clipboard-list",
        remote: true,
    };
    public static readonly ObjectivesSearchGroup: ISearchGroup = {
        type: SearchType.Objective,
        name: "Objectives & key results",
        icon: "fa-fw fal fa-th-large",
        remote: true,
    };
    public static readonly SystemSearchGroup: ISearchGroup = {
        type: SearchType.System,
        name: "Systems",
        icon: "fa-fw fal fa-solar-system",
        remote: true,
    };
    public static readonly Tier1SearchGroup: ISearchGroup<Tier1Type> = {
        type: SearchType.Tier1,
        name: "Value streams & key functions",
        icon: "fa-fw fal fa-cube",
        remote: true,
        subGroups: [{
            type: Tier1Type.ValueStream,
            name: "Value streams",
            icon: "fal fa-fw fa-cube",
        }, {
            type: Tier1Type.KeyFunction,
            name: "Key functions",
            icon: KeyFunction.IconClass,
        }, {
            type: Tier1Type.ProductService,
            name: "Products & services",
            icon: "fal fa-fw fa-box-open",
        }],
    };
    public static readonly GuidanceSearchGroup: ISearchGroup<GuidanceType> = {
        type: SearchType.Guidance,
        name: "Guidance",
        icon: "fa-fw fal fa-hand-holding-seedling",
        remote: true,
        subGroups: [{
            type: GuidanceType.PurposeVision,
            name: "Purpose & vision",
            icon: "fal fa-fw fa-compass",
        }, {
            type: GuidanceType.Value,
            name: "Values",
            icon: "fal fa-fw fa-heart",
        }, {
            type: GuidanceType.ResilientBusinessGoal,
            name: "Resilient business goals",
            icon: "fal fa-fw fa-star-shooting",
        }, {
            type: GuidanceType.StrategicTheme,
            name: "Strategic themes",
            icon: StrategicViewIcon.ThemeIcon,
        }, {
            type: GuidanceType.StrategicGoal,
            name: "Strategic goals",
            icon: StrategicViewIcon.GoalIcon,
        }, {
            type: GuidanceType.StrategicAnchor,
            name: AnchorMetadata.pluralLabel,
            icon: AnchorMetadata.iconClass,
        }, {
            type: GuidanceType.StrengthWeaknessTrend,
            name: SWTInputsViewOption.text,
            icon: SWTInputsViewOption.iconClass,
        }, {
            type: GuidanceType.CompetitorAnalysis,
            name: CAInputsViewOptions.text,
            icon: CAInputsViewOptions.iconClass,
        }, {
            type: GuidanceType.Bullseye,
            name: BullseyeViewOption.text,
            icon: BullseyeViewOption.iconClass,
        }],
    };
    public static readonly ImplementationKitSearchGroup: ISearchGroup = {
        type: SearchType.ImplementationKit,
        name: "Support articles",
        icon: "fa-fw fal fa-circle-question",
        remote: false,
    };

    public static SearchElementRegistrar?: IComponentRender<any>;
    public static ApplicationBarSearchElementRegistrar?: IComponentRender<any>;

    // mapping as dict of {SearchType: ISearchGroup}
    public static SearchTypeMapping = updateSearchTypeMapping();

    private readonly BaseUri = `${ServiceUri.MethodologyServicesServiceBaseUri}/Search`;
    private readonly log = Logger.getLogger(this.constructor.name);

    private searchDefaults: { [k in keyof ISearchOptions]: () => ISearchOptions[k] } = {
        keyword: () => undefined,
        types: () => new Set(searchGroupMapping.flatMap((type) => type.type)),
        activeOnly: () => true,
        personId: () => undefined,
        teamId: () => undefined,
        updatedSince: () => undefined,
        labelIds: () => new Set(),
    };
    private searchOptions = new BehaviorSubject<ISearchOptions>(this.getDefaultOptions());
    public searchOptions$ = this.searchOptions.asObservable();
    public searchLabels$: Observable<Label[]>;

    private lastSearchQuery = new BehaviorSubject<ISearchOptions | undefined>(undefined);
    public searchQuery$: Observable<ISearchOptions>;

    private isLoading = new BehaviorSubject<boolean>(false);
    public isLoading$ = this.isLoading.asObservable();

    private lastCompletedResults = new BehaviorSubject<ISearchResults | undefined>(undefined);
    private searchResults = new BehaviorSubject<ISearchResults | undefined>(undefined);
    public searchResults$ = this.searchResults.asObservable();

    private showSearchResults = new Subject<void>();
    public showSearchResults$ = this.showSearchResults.asObservable();

    public providerSearchErrors: Map<SearchType, any[]> = new Map();

    constructor(
        private http: HttpClient,
        private orgService: OrganisationService,
        private labellingService: LabellingService,
        private routeService: RouteService,
        @Inject(SEARCH_PROVIDERS) private searchProviders: SearchProvider[],
        userService: UserService,
    ) {
        this.searchQuery$ = this.searchOptions.pipe(
            debounceTime(SearchDebounce),
            switchMap((options) => {
                // update the url if we are already on the search page
                if (this.routeService.currentControllerId === SearchPageRoute.id) {
                    return this.gotoSearchRoute(options).pipe(map(() => options));
                }
                return of(options);
            }),
            // only allow keyword search once there are more than 2 characters, or a label is selected
            filter(({ keyword, labelIds }) => this.shouldPerformSearch(keyword, labelIds)),
        );

        this.searchLabels$ = this.searchOptions.pipe(
            switchMap(({ labelIds }) => {
                return this.labellingService.getLabelsWithIds(Array.from(labelIds ?? []));
            }),
        );

        // perform the search here
        this.searchQuery$.pipe(
            switchMap((options) => this.search(options, true)),
        ).subscribe(this.searchResults);

        this.showSearchResults.pipe(
            throttleTime(SearchDebounce),
            switchMap(() => this.searchOptions$),
            switchMap((options) => this.gotoSearchRoute(options)),
        ).subscribe();

        // reset search completely when changing org or user
        // and force local providers to reinitialise for fresh data
        this.orgService.organisationChanged$.subscribe(this.resetService);
        userService.userChanged$.subscribe(this.resetService);
    }

    public static registerSearchElementComponent(sec: IComponentRender<any>) {
        SearchService.SearchElementRegistrar = sec;
    }

    public static registerApplicationBarSearchElementComponent(sec: IComponentRender<any>) {
        SearchService.ApplicationBarSearchElementRegistrar = sec;
    }

    public static SortByTeamId<T extends IBaseSearchResult>(results?: T[]) {
        return [...(results ?? [])].sort((a, b) => {
            if (!this.HasTeam(a) && !this.HasTeam(b)) return 0;
            if (!this.HasTeam(a) || !this.HasTeam(b)) return -1;
            return a.teamId! - b.teamId!;
        });
    }

    public static SortByZone<T extends IBaseSearchResult>(results?: T[]) {
        return [...(results ?? [])].sort((a, b) => {
            if (!this.HasZone(a) && !this.HasZone(b)) return 0;
            if (!this.HasZone(a) || !this.HasZone(b)) return -1;
            return ZoneMetadata.InOrder.indexOf(a.zone!) - ZoneMetadata.InOrder.indexOf(b.zone!);
        });
    }

    public static HasTeam(result?: IBaseSearchResult): result is ITeamedSearchResult {
        return "teamId" in (result ?? {});
    }

    public static HasZone(result?: IBaseSearchResult): result is IZonedSearchResult {
        return "zone" in (result ?? {});
    }

    public static isValueStream(result: ITier1Result) {
        return result.type === Tier1Type.ValueStream
            ? result as IValueStreamSearchResult
            : undefined;
    }

    public static isKeyFunction(result: ITier1Result) {
        return result.type === Tier1Type.KeyFunction
            ? result as IKeyFunctionSearchResult
            : undefined;
    }

    public static isProduct(result: ITier1Result) {
        return result.type === Tier1Type.ProductService
            ? result as IProductServiceSearchResult
            : undefined;
    }

    public static isPurposeVision(result: IGuidanceResult) {
        return result.type === GuidanceType.PurposeVision
            ? result as IPurposeVisionSearchResult
            : undefined;
    }

    public static isValue(result: IGuidanceResult) {
        return result.type === GuidanceType.Value
            ? result as IValueSearchResult
            : undefined;
    }

    public static isResilientBusinessGoal(result: IGuidanceResult) {
        return result.type === GuidanceType.ResilientBusinessGoal
            ? result as IResilientBusinessGoalSearchResult
            : undefined;
    }

    public static isStrategicTheme(result: IGuidanceResult) {
        return result.type === GuidanceType.StrategicTheme
            ? result as IStrategicThemeSearchResult
            : undefined;
    }

    public static isStrategicGoal(result: IGuidanceResult) {
        return result.type === GuidanceType.StrategicGoal
            ? result as IStrategicGoalSearchResult
            : undefined;
    }

    public static isStrategicAnchor(result: IGuidanceResult) {
        return result.type === GuidanceType.StrategicAnchor
            ? result as IStrategicAnchorSearchResult
            : undefined;
    }

    public static isStrengthWeaknessTrend(result: IGuidanceResult) {
        return result.type === GuidanceType.StrengthWeaknessTrend
            ? result as IStrengthWeaknessTrendSearchResult
            : undefined;
    }

    public static isCompetitorAnalysis(result: IGuidanceResult) {
        return result.type === GuidanceType.CompetitorAnalysis
            ? result as ICompetitorAnalysisSearchResult
            : undefined;
    }

    public static isBullseye(result: IGuidanceResult) {
        return result.type === GuidanceType.Bullseye
            ? result as IGuidanceSearchResult
            : undefined;
    }

    public static isPerson(result: IPersonTeamRoleResult) {
        return result.type === PersonTeamRoleResultType.Person
            ? result as IPersonSearchResult
            : undefined;
    }

    public static isTeam(result: IPersonTeamRoleResult) {
        return result.type === PersonTeamRoleResultType.Team
            ? result as ITeamSearchResult
            : undefined;
    }

    public static isRole(result: IPersonTeamRoleResult) {
        return result.type === PersonTeamRoleResultType.Role
            ? result as IRoleSearchResult
            : undefined;
    }

    public registerSearchGroup(searchGroup: ISearchGroup) {
        searchGroupMapping.push(searchGroup);
        SearchService.SearchTypeMapping = updateSearchTypeMapping();
    }

    public shouldPerformSearch(keyword?: string, labelIds?: Set<number>) {
        const keywordIsValid = !!keyword && !!(keyword.trim()) && keyword.trim().length >= 2;
        const labelIsValid = !!labelIds && labelIds.size > 0;
        return keywordIsValid || labelIsValid;
    }

    public optionsFromSearchParams(params: Partial<ISearchUrlParams>) {
        const options: Partial<ISearchOptions> = {
            keyword: params.q,
            activeOnly: params.inactive
                ? !parseInt(params.inactive, 2)
                : true,
            personId: params.personId
                ? parseInt(params.personId, 10) || undefined
                : undefined,
            teamId: params.teamId
                ? parseInt(params.teamId, 10) || undefined
                : undefined,
            updatedSince: params.since
                ? DurationSelector.DataSource.find((i) => i.slug === params.since)
                : undefined,
            labelIds: params.labelIds
                ? this.urlToLabelIds(params.labelIds)
                : new Set<number>(),
        };
        if (params.types) {
            options.types = this.urlToSearchTypes(params.types);
        }
        return options;
    }

    public searchParamsFromOptions(options: Partial<ISearchOptions>) {
        const defaults = this.getDefaultOptions();

        const params: ISearchOptionsUrlParams = {
            q: options.keyword || undefined,
            labelIds: options.labelIds
                ? this.labelsToUrl(options.labelIds) || undefined
                : undefined,
            types: options.types
                ? this.searchTypesToUrl(options.types) || undefined
                : undefined,
            inactive: defaults.activeOnly !== options.activeOnly && options.activeOnly !== undefined
                ? options.activeOnly ? 0 : 1
                : undefined,
            personId: options.personId,
            teamId: options.teamId,
            since: options.updatedSince
                ? options.updatedSince.slug
                : undefined,
        };

        return ObjectUtilities.cleanNullAndUndefinedValues(params);
    }

    public search(searchOptions: ISearchOptions, sideEffects = false) {
        return of(searchOptions).pipe(
            tap(() => {
                if (sideEffects) {
                    this.isLoading.next(true);
                    this.providerSearchErrors.clear();
                }
            }),
            withLatestFrom(this.lastSearchQuery, this.lastCompletedResults),
            switchMap(([options, lastSearchQuery, lastCompletedResults]) => {
                if (sideEffects && lastSearchQuery && lastCompletedResults) {
                    // take out types to compare superset, everything else can be compared directly.
                    const { types, ...opt } = options;
                    const { types: prevTypes, ...prevOpt } = lastSearchQuery;
                    if (isEqual(opt, prevOpt) && SetUtilities.isSuperset(prevTypes, types)) {
                        // we already have all the data requested, we don't have to do another search.
                        // we should return a filtered view of the results.
                        const filteredResults = Object.entries(lastCompletedResults).map(
                            ([category, matches]) => options.types.has(SearchType[category as keyof typeof SearchType])
                                ? [category, matches]
                                : [category, []]);
                        return of([Object.fromEntries(filteredResults)] as ISearchResults[]);
                    } else {
                        // clear the last results as they don't match anymore
                        this.clearResultCache();
                    }
                }

                // need to create a new set else the types may get updated externally
                this.lastSearchQuery.next({ ...options, types: new Set(options.types) });

                return this.performSearch(options);
            }),
            tap((results) => {
                // set loading done once all sources are defined
                if (sideEffects && results.every((set) => set && Object.values(set).every((res) => res))) {
                    this.isLoading.next(false);
                }
            }),
            withLatestFrom(this.lastCompletedResults, this.isLoading),
            map(([results, lastCompletedResults, isLoading]) => {
                const combinedResults = this.combineResults(results);

                // save complete results once loading has finished
                if (sideEffects && !lastCompletedResults && !isLoading) {
                    this.lastCompletedResults.next({ ...combinedResults });
                }

                return combinedResults;
            }),
            debounceTime(100),
            switchMap((results) => {
                if (results) {
                    // get the label location Ids provided for any entity and preload them
                    const labelLocationIds = Object.values(results)
                        .flatMap((i: ISearchResults[keyof ISearchResults]) => i?.flatMap((ii: any) => ii?.LabelLocationIds ?? []) ?? []);

                    if (labelLocationIds.length > 0) {
                        return this.getAllLabelLocations(labelLocationIds).pipe(
                            map(() => results),
                        );
                    }
                }
                return of(results);
            }),
        );
    }

    public getSearchUrl$(options: Partial<ISearchOptions>) {
        const params = this.searchParamsFromOptions({ ...this.getDefaultOptions(), ...options });
        return SearchPageRoute.getRouteObject({}, params);
    }

    public gotoSearchRoute(options: Partial<ISearchOptions>) {
        const params = this.searchParamsFromOptions(options);
        return SearchPageRoute.gotoRoute({}, params, false, true);
    }

    public setSearchOptions(options: Partial<ISearchOptions>) {
        this.searchOptions.next(Object.assign({}, this.searchOptions.value, options));
    }

    public setKeyword(keyword?: string) {
        this.setSearchOptions({ keyword });
    }

    public setTypes(types: Set<SearchType> | SearchType[]) {
        // clone the set so it does not get modified externally
        this.setSearchOptions({ types: new Set(types) });
    }

    public toggleType(type: SearchType) {
        // clone the set so it does not get modified externally
        const types = new Set(this.searchOptions.value.types);
        // delete returns false if the element did not exist, so we can add it based on that
        if (!types.delete(type)) {
            types.add(type);
        }
        this.setTypes(types);
    }

    public reset() {
        this.clearResultCache();

        this.setSearchOptions(this.getDefaultOptions());
    }

    public clearResultCache() {
        this.lastCompletedResults.next(undefined);
        this.lastSearchQuery.next(undefined);
        this.searchResults.next(undefined);
    }

    public showResults() {
        this.showSearchResults.next();
    }

    public getDefaultOptions() {
        // build searchOptions from the defaults. have to cast to unknown as Typescript cannot follow the types in entries -> fromEntries
        return Object.fromEntries(Object.entries(this.searchDefaults).map(([k, v]) => [k, v()])) as unknown as ISearchOptions;
    }

    public getEmptyOptions() {
        return { ...this.getDefaultOptions(), types: new Set<SearchType>() };
    }

    private urlToSearchTypes(urlString: string) {
        const groups = Object.values(SearchService.SearchTypeMapping).map(({ type }) => type);
        const types = urlString.split(",")
            .map((type) => parseInt(type, 10))
            .filter((type) => !Number.isNaN(type) && groups.includes(type))
            .sort((a, b) => a - b);

        return new Set<SearchType>(types);
    }

    private urlToLabelIds(urlString: string) {
        const labelIds = urlString.split(",")
            .map((type) => parseInt(type, 10))
            .filter((type) => !Number.isNaN(type))
            .sort((a, b) => a - b);

        return new Set<number>(labelIds);
    }

    private searchTypesToUrl(types: Set<SearchType>) {
        return Array.from(types).sort((a, b) => a - b).join(",");
    }

    private labelsToUrl(labels: Set<number>) {
        return Array.from(labels).sort((a, b) => a - b).join(",");
    }

    @Autobind
    private resetService() {
        this.reset();
        this.resetLocalProviders();
    }

    private resetLocalProviders() {
        this.searchProviders.forEach((provider) => provider.isInitialised = false);
    }

    private performSearch(options: ISearchOptions) {
        const searchTypes = [...options.types];
        const [remoteTypes, localTypes] = ArrayUtilities.partition(searchTypes, (type) => SearchService.SearchTypeMapping[type].remote);

        const labelIds = Array.from(options.labelIds ?? []);
        const updatedSince = options.updatedSince?.value;

        const keyword = options.keyword ? options.keyword.trim() : options.keyword!;

        const searchOptions = { ...options, keyword, labelIds };
        return combineLatest([
            ...remoteTypes.map((type) => this.performSearchRequest({
                    ...searchOptions,
                    types: [type],
                    updatedSince,
                }).pipe(
                    // this allows local search results to stream in while search API loads
                    startWith(undefined),
                    tap(() => this.providerSearchErrors.set(type, [])),
                    timeout({
                        each: SearchTimeout,
                        with: () => throwError(() => "Search took too long. Please try again."),
                    }),
                    catchError((err) => {
                        this.recordTypeError(type, err);
                        return of({});
                    }),
                ),
            ),
            ...this.getLocalSearchObservables({ ...searchOptions, types: localTypes }),
        ]).pipe(
            finalize(() => {
                this.isLoading.next(false);
            }),
        );
    }

    private getLocalSearchObservables(params: Omit<ISearchApiParams, "organisationId" | "updatedSince">) {
        const options = { ...params, types: new Set(params.types), labelIds: new Set(params.labelIds) };

        return this.searchProviders
            .filter((provider) => params.types.includes(provider.Type))
            .map((searchProvider) => searchProvider.isInitialised
                ? of(searchProvider)
                : searchProvider.initialise().pipe(
                    tap(() => searchProvider.isInitialised = true),
                    map(() => searchProvider),
                ))
            .map((searchProvider) => searchProvider.pipe(
                switchMap((provider) => {
                    if (provider.shouldSkip(options)) {
                        return of([] as any);
                    }

                    return provider.execute(options).pipe(
                        startWith(undefined),
                        catchError((err) => {
                            this.log.error(err);
                            this.recordTypeError(provider.Type, err);
                            return of([]);
                        }),
                        map((results) => ({ [SearchType[provider.Type]]: results } as ISearchResults)),
                    );
                }),
            ));
    }

    private recordTypeError(type: SearchType, err: any) {
        if (!this.providerSearchErrors.has(type)) {
            this.providerSearchErrors.set(type, []);
        }
        this.providerSearchErrors.get(type)?.push(err);
    }

    private performSearchRequest(params: Omit<ISearchApiParams, "organisationId">) {
        if (params.types.length === 0) {
            // don't bother sending a query if no types selected
            return of({});
        }

        const organisationId = this.orgService.getOrganisationId();
        return this.http.post<ISearchResults>(this.BaseUri, { ...params, organisationId }, {
            responseType: "json",
        }).pipe(
            catchError((err) => throwError(() => ErrorHandlingUtilities.getHttpResponseMessage(err))),
        );
    }

    private combineResults(results: (ISearchResults | undefined)[]): ISearchResults | undefined {
        return results.reduce((acc: ISearchResults, res) => {
            for (const [key, typeResults] of Object.entries(res ?? {})) {
                acc = Object.assign(acc, {
                    [key]: (acc[key as keyof ISearchResults] ?? []).concat(typeResults ?? []),
                });
            }

            return acc;
        }, {} as ISearchResults);
    }

    private getAllLabelLocations(locationIds: number[]) {
        const predicate = new MethodologyPredicate<LabelLocation>("labelLocationId", "in", locationIds);

        return this.labellingService.getLabelLocationsByPredicate(predicate);
    }
}
